import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Activity, Check, ChevronLeft, ClockIcon, Download, Edit, Eye, FileLock2, FilePenIcon, Plus } from "lucide-react";
import React from "react";
import { useSelector } from "react-redux";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

const NewExplore = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const img = "../../../public/excel.png";

  return (
    <>
      <Grid container sx={{ height: "100%", width: "95%", margin: 2, marginLeft: 6 }}>
        <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ marginLeft: open ? "240px" : "75px" }}>
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2}>
              <Button startIcon={<ChevronLeft />}>Back</Button>
              <Typography
                variant="h4"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Dummy Transaction Diligence
              </Typography>
            </Stack>
            <Button variant="contained" startIcon={<Plus size={"22px"} />} sx={{ marginLeft: "auto", bgcolor: "#000", px: "82px", fontWeight: "bold" }}>
              Add Workstream
            </Button>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 2, borderBottomWidth: 3, bgcolor: "#000" }} />
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              marginTop: 2,
              marginBottom: 4,
            }}
          >
            <Stack direction={"row"} spacing={3}>
              <Typography
                variant="h5"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Due Diligence
              </Typography>
              <Button
                startIcon={<Edit size={20} color="#000" />}
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  px: "20px",
                }}
              >
                Edit
              </Button>
              <Button
                startIcon={<Eye size={20} color="#000" />}
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  px: "20px",
                }}
              >
                Preview
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <LinearProgress
                variant="determinate"
                value={70}
                sx={{
                  width: "400px",
                  height: 18,
                  borderRadius: 6,
                  border: "1px solid #000",
                  backgroundColor: "lightgrey",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 6,
                    backgroundColor: "#1AB5B8",
                  },
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                70%
              </Typography>
            </Stack>
          </Stack>
          <Typography
            variant="h5"
            sx={{
              marginTop: 2,
              marginBottom: 1,
              marginLeft: 0,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Phase 01
          </Typography>
          <Divider sx={{ marginTop: 1, marginBottom: 2, borderBottomWidth: 3, bgcolor: "#000" }} />
          <Stack spacing={5} direction={"row"} alignItems={"center"}>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Due Diligence summary
              </Typography>
              <Chip label="85% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
              <Card
                style={{
                  display: "block",
                  width: "100%",
                  borderRadius: "10px",
                  transitionDuration: "0.3s",
                  height: "auto",
                  backgroundColor: "#F5F5F7",
                }}
              >
                <Stack spacing={3} margin={2}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Check size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Completed
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                        12 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Activity size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        In Progress
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                        4 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <FilePenIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        User Input
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                        2 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <ClockIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Not Started Yet
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                        8 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Desktop research
              </Typography>
              <Chip label="70% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
              <Card
                style={{
                  display: "block",
                  width: "100%",
                  borderRadius: "10px",
                  transitionDuration: "0.3s",
                  height: "auto",
                  backgroundColor: "#1AB5B8",
                }}
              >
                <Stack spacing={3} margin={2}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Check size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        Completed
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                        12 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Activity size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        In Progress
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                        4 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <FilePenIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        User Input
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                        2 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <ClockIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography
                        variant={"body1"}
                        sx={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        Not Started Yet
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                        8 tasks
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Stack>
          <Typography
            variant="h5"
            sx={{
              marginTop: 2,
              marginBottom: 1,
              marginLeft: 0,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Phase 02
          </Typography>
          <Divider sx={{ marginTop: 1, marginBottom: 2, borderBottomWidth: 3, bgcolor: "#000" }} />
          <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              marginLeft: 2,
              fontWeight: "bold",
              color: "#1AB5B8",
            }}
          >
            Workstream
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Legal
                </Typography>
                <Chip label="5% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
                <Card
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    transitionDuration: "0.3s",
                    height: "auto",
                    backgroundColor: "#F5F5F7",
                  }}
                >
                  <Stack spacing={3} margin={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Check size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          12 tasks
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Activity size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          In Progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          4 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <FilePenIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          User Input
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          2 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <ClockIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Not Started Yet
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          8 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Commercial
                </Typography>
                <Chip label="8% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
                <Card
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    transitionDuration: "0.3s",
                    height: "auto",
                    backgroundColor: "#1AB5B8",
                  }}
                >
                  <Stack spacing={3} margin={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Check size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          12 tasks
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Activity size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          In Progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          4 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <FilePenIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          User Input
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          2 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <ClockIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Not Started Yet
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          8 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Finance
                </Typography>
                <Chip label="15% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
                <Card
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    transitionDuration: "0.3s",
                    height: "auto",
                    backgroundColor: "#F5F5F7",
                  }}
                >
                  <Stack spacing={3} margin={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Check size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          50 tasks
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Activity size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          In Progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          0 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <FilePenIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          User Input
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          12 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <ClockIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Not Started Yet
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          0 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Technical
                </Typography>
                <Chip label="18% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
                <Card
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    transitionDuration: "0.3s",
                    height: "auto",
                    backgroundColor: "#1AB5B8",
                  }}
                >
                  <Stack spacing={3} margin={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Check size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          10 tasks
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Activity size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          In Progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          2 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <FilePenIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          User Input
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          3 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <ClockIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Not Started Yet
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#ccc", marginTop: 0, marginBottom: 0 }}>
                          0 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Tax
                </Typography>
                <Chip label="10% Progress" variant="outlined" size={"small"} style={{ backgroundColor: "#fef08a", width: "170px" }} />
                <Card
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    transitionDuration: "0.3s",
                    height: "auto",
                    backgroundColor: "#F5F5F7",
                  }}
                >
                  <Stack spacing={3} margin={2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Check size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Completed
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          15 tasks
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <Activity size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          In Progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          4 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <FilePenIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          User Input
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          2 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderRadius: 2,
                        }}
                      >
                        <ClockIcon size={20} color={"#fff"} />
                      </Box>
                      <Stack spacing={0} alignItems={"flex-start"}>
                        <Typography
                          variant={"body1"}
                          sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          Not Started Yet
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#758694", marginTop: 0, marginBottom: 0 }}>
                          0 tasks
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
          <Grid mt={2} item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Financial model
              </Typography>
              <Card
                style={{
                  display: "block",
                  width: "100%",
                  borderRadius: "10px",
                  transitionDuration: "0.3s",
                  height: "auto",
                  backgroundColor: "#F5F5F7",
                }}
              >
                <CardActionArea>
                  <ImageButton
                    focusRipple
                    key={"image.title"}
                    style={{
                      width: "100%",
                    }}
                  >
                    <ImageSrc style={{ backgroundImage: `url()` }} />
                    <ImageBackdrop className="MuiImageBackdrop-root" />
                    <Image>
                      <Eye size={30} color={"#fff"} />
                    </Image>
                  </ImageButton>
                </CardActionArea>
                <CardContent>
                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Download
                    </Typography>
                    <Button>
                      <Download />
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
          <Typography
            variant="h5"
            sx={{
              marginTop: 4,
              marginBottom: 2,
              fontWeight: "bold",
              color: "#1AB5B8",
            }}
          >
            Internal Documents
          </Typography>
          <Stack spacing={3} direction={"row"}>
            <Button
              variant="contained"
              startIcon={<FileLock2 />}
              sx={{
                backgroundColor: "#1AB5B8",
                color: "#fff",
                fontWeight: "bold",
                px: "20px",
              }}
            >
              Letter of Intent
            </Button>
            <Button
              variant="contained"
              startIcon={<FileLock2 />}
              sx={{
                backgroundColor: "#1AB5B8",
                color: "#fff",
                fontWeight: "bold",
                px: "20px",
              }}
            >
              Investment Memo
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default NewExplore;
