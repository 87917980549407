import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Card, CardContent, CardActions, Typography, Button, LinearProgress, Box, Stack } from "@mui/material";
import { AttachFile, Close, InsertDriveFile } from "@material-ui/icons";
import { FileUp } from "lucide-react";

const CardUpload = () => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => Object.assign(file, { progress: 0 }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    // Simulate upload progress
    newFiles.forEach((file) => {
      const interval = setInterval(() => {
        setFiles((prevFiles) => prevFiles.map((f) => (f === file ? { ...f, progress: Math.min(f.progress + 10, 100) } : f)));
        if (file.progress >= 100) clearInterval(interval);
      }, 500);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
  });

  const removeFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const formatFileSize = (bytes) => {
    if (typeof bytes !== "number" || isNaN(bytes) || bytes === 0) return "Unknown size";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "background.default",
      }}
    >
      <Card sx={{ width: 600, margin: "auto", borderRadius: "12px", padding: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            Upload PDFs
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
            Select or drag and drop PDF files to upload. You can upload multiple files at once.
          </Typography>
          <Box
            {...getRootProps()}
            sx={{
              p: 2,
              border: "2px dashed",
              borderColor: isDragActive ? "primary.main" : "grey.300",
              borderRadius: 1,
              textAlign: "center",
              cursor: "pointer",
              bgcolor: isDragActive ? "primary.light" : "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <input {...getInputProps()} />
            <FileUp size={44} />
            <Typography>{isDragActive ? "Drop the PDF files here..." : "Drag and drop PDF files here, or click to select files"}</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            {files.map((file, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1, p: 1, bgcolor: "grey.100", borderRadius: 3 }}>
                <InsertDriveFile sx={{ mr: 1, color: "primary.main" }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2">{file.name ? file.name : "Name not available"}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatFileSize(file.size)} - PDF
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <LinearProgress
                      variant="determinate"
                      value={file.progress}
                      sx={{
                        width: "400px",
                        height: 13,
                        borderRadius: 6,
                        backgroundColor: "#B0B7C0",
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 6,
                          backgroundColor: "black",
                        },
                      }}
                    />
                    <Typography variant="body1" color="text.secondary">
                      {file.progress}%
                    </Typography>
                  </Stack>
                </Box>
                <Button size="small" onClick={() => removeFile(file)} sx={{ minWidth: "auto", p: 0.5 }}>
                  <Close fontSize="small" color="error" />
                </Button>
              </Box>
            ))}
          </Box>
        </CardContent>
        <CardActions sx={{ flexDirection: "row", gap: 1, p: 1 }}>
          <Button
            variant="outlined"
            sx={{
              borderRadius: 6,
              color: "black",
              borderColor: "black",
              "&:hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            fullWidth
            onClick={() => setFiles([])}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 6,
              bgcolor: "black",
              color: "white",
              "&:hover": {
                bgcolor: "black",
              },
            }}
            fullWidth
            startIcon={<AttachFile />}
          >
            Attach Files
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CardUpload;
