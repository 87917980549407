import React from "react";
import { Container, Grid, Typography, Paper, Box, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import myImage from "../../../assets/landingpage/s21.png"; // adjust the path as necessary
import { colors } from "../../../utils/colors";

const featureList = [
  {
    title: "Benchmarking",
    description: "Utilize semantic analysis to determine if contract clauses align with market standards.",
  },
  {
    title: "Red Flagging",
    description: "Challenge or confirm hypotheses using comprehensive data room insights.",
  },
  {
    title: "View Extractor & sector expertise",
    description: "Extract viewpoints from data rooms to inform strategic benchmarks.",
  },
];

const SectionTwo = () => {
  return (
    <Container maxWidth="xl" style={{ maxWidth: "1400px" }}>
      <Grid container spacing={4}>
        {/* Image on the left */}
        <Grid item xs={12} md={6}>
          <img src={myImage} alt="Landing Page" style={{ width: "100%", height: "auto" }} />
        </Grid>

        {/* Text on the right */}
        <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Box width="40px" height="4px" bgcolor={"#000"} mb={2} /> {/* Underline effect */}
          <Typography variant="h3" component="h2" fontWeight="bold" gutterBottom>
            Identify and Analyze All Potential Deals
          </Typography>
          <Typography variant="subtitle1" paragraph style={{ color: colors.PGRAY }}>
            Easily explore and evaluate all potential deals with our platform. Whether you're considering multiple opportunities or focusing on a niche market, our AI-powered tool provides deep insights, helping you filter through options and focus on the deals that align with your strategy. From
            initial evaluation to final decision-making, gain confidence in each step of your acquisition journey
          </Typography>
          {/* <List>
            {featureList.map((feature) => (
              <ListItem key={feature.number} style={{ alignItems: "flex-start", padding: 0 }}>
                <ListItemIcon>
                  <Box
                    sx={{
                      bgcolor: colors.LGREEN,
                      color: "white",
                      borderRadius: "50%",
                      width: 36,
                      height: 36,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "16px",
                      marginTop: "8px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" style={{}}>
                      {feature.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" style={{ color: colors.PGRAY }}>
                      {feature.description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionTwo;
