import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

// List of logos (adjust paths as needed)
import Ardian from "../../../assets/landingpage/Ardian.svg";
import lseFull from "../../../assets/landingpage/lseFull.svg";
import Warwick from "../../../assets/landingpage/Warwick.svg";
import dif from "../../../assets/landingpage/dif.png";
import IRCP from "../../../assets/landingpage/IRCP.svg";
import kpmg from "../../../assets/landingpage/kpmg.webp";
import { OpenCalendar, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const LogosContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "1rem",
  flexDirection: isMobile() ? "column" : "row",
  width: "100%", // Ensure it takes the full width of the container
}));

const Logo = styled("img")(({ height }) => ({
  height: height,
  margin: "20px",
  filter: "grayscale(100%)",
  width: isMobile() ? "40%" : "",
}));

const SectionLogos = () => {
  const maxWidth = windowWidth * 0.85;

  return (
    <Container
      sx={{
        maxWidth: { xs: "100%", lg: "100%" },
        width: "100%",
        padding: 0,
        margin: "0 auto",
        marginTop: { xs: 4, md: 8 },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center", // Center the content horizontally
      }}
      lx={{}}
    >
      <Box textAlign="center" mb={4} sx={{ width: "100%" }}>
        <Typography variant="h6" sx={{ color: "#757575", marginBottom: "20px" }}>
          Built by world class teams from
        </Typography>
      </Box>
      <LogosContainer>
        <Logo src={Ardian} alt="Ardian" height={isMobile() ? "25px" : "30px"} />
        <Logo src={lseFull} alt="LSE" height="50px" />
        <Logo src={Warwick} alt="Warwick" height="80px" />
        <Logo src={dif} alt="DIF" height="50px" />
        <Logo src={IRCP} alt="IRCP" height="50px" />
        <Logo src={kpmg} alt="kpmg" height="50px" />
      </LogosContainer>
    </Container>
  );
};

export default SectionLogos;
