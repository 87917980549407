import React from "react";
import { Grid, Stack, Box } from "@mui/material";
import HeaderComponent from "./HeaderComponent";

const Documentstart = ({ open, title, defaultwidth, children, headerProps }) => {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        overflowX: defaultwidth ? "" : "hidden", // conditionally set overflowX
      }}
    >
      <Grid
        item
        xs={12}
        md={open ? 9 : 12}
        lg={open ? 10 : 12}
        sx={{
          transition: "margin 0.3s",
          marginLeft: open ? "260px" : "95px",
          width: open ? "calc(100% -280px)" : "calc(100% - 115px)",
        }}
      >
        {/* Sticky Header */}
        <Stack
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1100,
            bgcolor: "white",
            width: "100%",
            p: 2,
            paddingBottom: 0,
            // borderBottom: "1px solid gray",
          }}
        >
          <HeaderComponent headerText={title} {...headerProps} />
        </Stack>

        {/* Main Content Area */}
        <Grid
          sx={{
            display: "flex",
            flex: 1, // This allows the content to take up the remaining space
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "gray.100",
            overflowY: "auto", // Ensures the main content can scroll
            overflowX: "hidden", // Ensures the main content can scroll
            p: 2,
            paddingTop: 0,
            width: "100%", // Ensure it takes up full width
          }}
        >
          <Grid
            sx={{
              p: 0,
              paddingTop: 0,
              bgcolor: "white",
              borderRadius: 2,
              width: "100%",
              maxHeight: "100%",
              overflowY: "auto", // Ensure content can scroll when overflowing
              overflowX: "hidden", // Ensure content can scroll when overflowing
            }}
          >
            {/* Render children content */}
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Documentstart;
