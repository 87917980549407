// src/actions/templateWorkstreamAction.js

import { backendClient } from "../../api/backend";
import {
  FETCH_TEMPLATE_WORKSTREAM,
  FETCH_TEMPLATE_WORKSTREAMS_REQUEST,
  FETCH_TEMPLATE_WORKSTREAMS_SUCCESS,
  FETCH_TEMPLATE_WORKSTREAMS_FAILURE,
  CREATE_TEMPLATE_WORKSTREAM,
  UPDATE_TEMPLATE_WORKSTREAM,
  DELETE_TEMPLATE_WORKSTREAM,
  SET_TEMPLATE_WORKSTREAM,
  SET_TEMPLATE_WORKSTREAMS,
  CLEAR_TEMPLATE_WORKSTREAMS,
  COUNT_TEMPLATE_WORKSTREAMS,
} from "./types";

export const createTemplateWorkstream = (templateWorkstream) => {
  return async (dispatch) => {
    const createdWorkstream = await backendClient.createTemplateWorkstream(templateWorkstream);
    dispatch({
      type: CREATE_TEMPLATE_WORKSTREAM,
      payload: createdWorkstream,
    });
    return createdWorkstream;
  };
};

export const fetchTemplateWorkstreams = (industry, organization_id) => {
  return async (dispatch) => {
    console.log("Fetching template workstreams for:", industry, organization_id); // Add logging
    dispatch({ type: FETCH_TEMPLATE_WORKSTREAMS_REQUEST });
    try {
      const templateWorkstreams = await backendClient.fetchTemplateWorkstream(industry, organization_id);
      console.log("Received template workstreams:", templateWorkstreams); // Add logging
      dispatch({
        type: FETCH_TEMPLATE_WORKSTREAMS_SUCCESS,
        payload: templateWorkstreams,
      });
    } catch (error) {
      console.error("Error fetching template workstreams:", error);
      dispatch({
        type: FETCH_TEMPLATE_WORKSTREAMS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchTemplateWorkstream = (templateWorkstreamId) => {
  return async (dispatch) => {
    const templateWorkstream = await backendClient.fetchTemplateWorkstreamById(templateWorkstreamId);
    dispatch({
      type: FETCH_TEMPLATE_WORKSTREAM,
      payload: templateWorkstream,
    });
    return templateWorkstream;
  };
};

export const updateTemplateWorkstream = (id, updatedWorkstream) => async (dispatch) => {
  try {
    // Send the updated workstream to the backend
    const response = await backendClient.updateTemplateWorkstream(id, updatedWorkstream);

    dispatch({
      type: UPDATE_TEMPLATE_WORKSTREAM,
      payload: updatedWorkstream, // Assuming the backend returns the updated workstream object
    });
    // Dispatch the updated workstream to Redux
  } catch (error) {
    console.error("Error updating workstream:", error);
    throw error; // Handle the error further up the chain
  }
};

export const deleteTemplateWorkstream = (templateWorkstreamId) => {
  return async (dispatch) => {
    await backendClient.deleteTemplateWorkstream(templateWorkstreamId);
    dispatch({
      type: DELETE_TEMPLATE_WORKSTREAM,
      payload: templateWorkstreamId,
    });
  };
};

export const setTemplateWorkstream = (templateWorkstream) => {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATE_WORKSTREAM,
      payload: templateWorkstream,
    });
  };
};

export const setTemplateWorkstreams = (templateWorkstreams) => {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATE_WORKSTREAMS,
      payload: templateWorkstreams,
    });
  };
};

export const clearTemplateWorkstreams = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TEMPLATE_WORKSTREAMS,
    });
  };
};

export const countTemplateWorkstreams = (organization_id, industry) => {
  return async (dispatch) => {
    const count = await backendClient.countTemplateWorkstreams(organization_id, industry);
    dispatch({
      type: COUNT_TEMPLATE_WORKSTREAMS,
      payload: count,
    });
    return count;
  };
};
