// src/reducers/templateWorkstreamReducer.js

import {
  CREATE_TEMPLATE_WORKSTREAM,
  FETCH_TEMPLATE_WORKSTREAMS_REQUEST,
  FETCH_TEMPLATE_WORKSTREAMS_SUCCESS,
  FETCH_TEMPLATE_WORKSTREAMS_FAILURE,
  UPDATE_TEMPLATE_WORKSTREAM,
  DELETE_TEMPLATE_WORKSTREAM,
  SET_TEMPLATE_WORKSTREAMS,
  SET_TEMPLATE_WORKSTREAM_ID,
  SET_SELECTED_INDUSTRY,
} from "../actions/types";

const initialState = {
  templateWorkstreams: [], // List of template workstreams
  isLoading: false, // Loading state for fetch requests
  selectedIndustry: "Industry", // Default selected industry
  error: null, // Error handling state
  templateWorkstreamId: null, // Track selected template workstream ID
};

const templateWorkstreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TEMPLATE_WORKSTREAM:
      return {
        ...state,
        templateWorkstreams: [...state.templateWorkstreams, action.payload],
      };

    case FETCH_TEMPLATE_WORKSTREAMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null, // Clear previous errors on new request
      };

    case FETCH_TEMPLATE_WORKSTREAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templateWorkstreams: action.payload,
      };

    case FETCH_TEMPLATE_WORKSTREAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload, // Capture the error message
      };

    case UPDATE_TEMPLATE_WORKSTREAM:
      return {
        ...state,
        templateWorkstreams: state.templateWorkstreams.map((workstream) => (workstream.id === action.payload.id ? action.payload : workstream)),
      };

    case DELETE_TEMPLATE_WORKSTREAM:
      return {
        ...state,
        templateWorkstreams: state.templateWorkstreams.filter((workstream) => workstream.id !== action.payload),
      };

    case SET_TEMPLATE_WORKSTREAMS:
      return {
        ...state,
        templateWorkstreams: action.payload,
      };

    case SET_SELECTED_INDUSTRY:
      return {
        ...state,
        selectedIndustry: action.payload, // Update the selected industry
      };

    case SET_TEMPLATE_WORKSTREAM_ID:
      return {
        ...state,
        templateWorkstreamId: action.payload, // Set the template workstream ID
      };

    default:
      return state;
  }
};

export default templateWorkstreamReducer;
