import { Paper } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, CatchingPokemonSharp, ErrorOutline, PeopleOutline } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme } from "@mui/material/styles";
import { Edit, Eye, MessageCircle, Paperclip, X, Table2 } from "lucide-react";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { closeSnackbar, useSnackbar } from "notistack";
import React, { useEffect, useRef, useState, useCallback, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { clearExploredData } from "../../redux/actions/exploreAction";
import { fetchTransactions, setTransactions } from "../../redux/actions/transactionAction";
import { colors } from "../../utils/colors";
import CardUpload from "./dealscomponents/CardUpload";
import EditDeal from "./dealscomponents/DealsFormDialog";
import PdfViewerDialog from "./dealscomponents/PdfViewerDialog";
import StatusSelector from "./dealscomponents/StatusSelector";
import DealsHeader from "./dealscomponents/dealsheader";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import { backendClient } from "../../api/backend";
import { createConversation } from "../../redux/actions/conversationAction";
import { clearHypothesis, extractHypothesis, fetchHypothesis } from "../../redux/actions/hypothesisAction";
import Documentstart from "../../components/Documentstart";
import InfoCard from "./dealscomponents/InfoCard";
import InfoCardList from "./dealscomponents/InfoCardList";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "inherit",
          transition: "none",
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
  },
});

const CustomPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "transparent !important",
  borderRadius: 0,
  color: "inherit",
  transition: "none",
  "&.MuiPaper-root": {
    backgroundColor: "none !important",
    color: "none",
    transition: "none",
  },
}));

const sample = [
  { name: "Contracted", detail: "N/A" },
  { name: "Merchant:", detail: "N/A" },
  { name: "Others:", detail: "N/A" },
];

const sample1 = [
  { name: "Life (years)", detail: "N/A" },
  { name: "Debt", detail: "N/A" },
];

const StyledDetailsBox = styled(Box)(({ theme }) => ({
  backgroundColor: ({ color }) => color,
  borderRadius: "24px",
  padding: "34px",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "12px",
  height: "100%",
}));

const InfoRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const Label = styled(Typography)({
  fontWeight: "bold",
  fontSize: "18px",
});

const Value = styled(Typography)({
  fontSize: "18px",
  fontWeight: "bold",
  textAlign: "right",
});

const DataTable = ({ title, data }) => (
  <Box
    sx={{
      flex: 1, // Allow the table to expand and fill the available space
      height: "100%", // Make sure the table takes full height
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Table
      sx={{
        flex: 1,
        height: "100%",
        borderCollapse: "collapse",
        "& th": {
          fontWeight: "bold",
          fontSize: "medium",
          bgcolor: "#E9E9E9",
          padding: "12px",
        },
        "& td": {
          bgcolor: "#FAF6FF",
        },
        "& tr:last-of-type td": {
          borderRight: "none", // Remove border from last cell in each row
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={3}>
            {title}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={item.name}>
            <TableCell rowSpan={item.detail.length + 1} align="center" style={{ verticalAlign: "middle", borderRight: index < data.length - 1 ? "2px solid black" : "none" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}>
                <div className="font-semibold">{item.name}</div>
                <div style={{ fontSize: "small", color: "gray" }}>{item.detail}</div>
              </div>
            </TableCell>
            {/* Add additional TableCell elements as needed */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

const TransactionCard = ({ title, transaction, color, open, handleDetails, handleDue, handleKpi, saveTransaction, hyp, isloadinghyp }) => {
  // console.log("transaction ---> ", transaction);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cardBackgroundColor = title === "Details" ? color : "#F5F5F7";

  const dispatch = useDispatch();
  const handleCardClick = () => {
    if (title === "Overview") {
      setIsDialogOpen(true);
      // saveTransaction(transaction);
    } else if (title === "Details") {
      console.log("Details card clicked.");
    } else if (title === "Access due diligence") {
      console.log("Access due diligence card clicked.");
      handleDue();
      saveTransaction(transaction);
    } else if (title === "KPI") {
      console.log("KPI card clicked.");
      handleKpi();
      saveTransaction(transaction);
    } else {
      console.log(`${title} card clicked.`);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const titleStyles = {
    color: colors.PRIMARY,
    fontWeight: "bold",
  };

  const features = {
    Valuation: "N/A",
    Revenue: transaction.revenue || "N/A",
    EBITDA: "N/A",
  };
  // Loading spinner or content based on loading state

  const filteredHypotheses_Details = hyp ? hyp.filter((hypothesis) => hypothesis.hyp_type === "Details") : []; // Replace 'desired_hyp_type' with your actual filter value
  const filteredHypotheses_KPI = hyp ? hyp.filter((hypothesis) => hypothesis.hyp_type === "KPI") : []; // Replace 'desired_hyp_type' with your actual filter value
  console.log("filteredHypothesis", filteredHypotheses_Details);
  const OverviewDialog = () => (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "sm",
          width: "90%",
          borderRadius: "24px",
        },
      }}
    >
      <DialogTitle variant="h6" sx={{ backgroundColor: color, color: "white", fontWeight: "bold" }}>
        Overview: {transaction.name}
      </DialogTitle>
      <IconButton
        onClick={handleCloseDialog}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "white",
        }}
      >
        <X />
      </IconButton>
      <DialogContent
        sx={{
          padding: 0,
          "& .MuiDialogContent-root": {
            padding: 0,
          },
        }}
      >
        <Box sx={{ p: 0 }}>
          <Typography variant="body1" paragraph px={4} pt={2}>
            {transaction.overview}
          </Typography>
          <Divider
            style={{ color: "#000", padding: "1px" }}
            sx={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "black",
            }}
          />
          <Typography variant="h6" gutterBottom px={3} fontWeight="bold" pt={2}>
            Recommendation
          </Typography>
          {[
            { label: "Valuation", value: transaction.valuation },
            { label: "Operations", value: transaction.operation },
            { label: "Complexity", value: transaction.complexity },
            { label: "Location", value: transaction.location },
            { label: "Bankability", value: transaction.bankability },
          ].map((item) => (
            <Box
              key={item.label}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                px: 3,
              }}
            >
              <Typography variant="body2" sx={{ minWidth: "120px", fontWeight: 500 }}>
                {item.label}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={item.value || 0}
                sx={{
                  width: "70%",
                  height: 9,
                  borderRadius: "8px",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: color,
                  },
                  bgcolor: "transparent",
                  border: "1px solid gray",
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              height: "80px",
              padding: "0px",
              margin: "0px",
              width: "100%",
              borderTop: "3px solid black",
            }}
          >
            {/* {Object.entries(features).map(([key, value], index) => (
              <React.Fragment key={key}>
                <Box key={key} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="body1" fontWeight="bold">
                    {key}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: color,
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
                {index < Object.entries(features).length - 1 && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                      padding: "1px",
                    }}
                    sx={{
                      margin: "0",
                      padding: "0",
                      backgroundColor: "black",
                    }}
                  />
                )}
              </React.Fragment>
            ))} */}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
  function applyEllipsis(labelElement, maxLength) {
    // if (!labelElement) return;
    console.log(labelElement);
    if (labelElement.length > maxLength) {
      // Truncate the text and add ellipsis
      labelElement = labelElement.substring(0, maxLength - 3) + "...";
    }
    return labelElement;
  }
  console.log(transaction?.overview?.length, "this is the length");
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        sx={{
          mb: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ ...titleStyles, alignSelf: "normal" }}>
          {title}
        </Typography>
        <Tooltip title={title === "Overview" ? "Click to expand" : title === "Access due diligence" ? "Click to explore the due diligence" : title === "KPI" ? "Explore all the KPIs of the transaction" : ""} arrow>
          <Button onClick={handleCardClick} sx={{ p: 0, textAlign: "left", borderRadius: "24px", width: 300, "&:hover": { bgcolor: "transparent !important" }, display: "flex", justifyContent: "center", alignItems: "center" }} style={{ textTransform: "inherit" }}>
            <Card
              sx={{
                height: 267,
                width: 300,
                bgcolor: `${cardBackgroundColor} !important`,
                borderRadius: "24px !important",
                "&:hover": { bgcolor: cardBackgroundColor },
                boxShadow: "none",
                "& .css-mnu21i-MuiCardContent-root:last-child": {
                  paddingBottom: 0,
                },
              }}
            >
              <CardContent
                sx={{
                  padding: "0px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  paddingBottom: `0 !important`,
                  flexDirection: "column",
                  "& .MuiCardContent-root": {
                    paddingBottom: 0,
                    backgroundColor: cardBackgroundColor,
                  },
                }}
              >
                {title === "Details" ? (
                  <StyledDetailsBox color={color}>
                    {/* Always show the country/region */}
                    <InfoRow>
                      <Label>Region:</Label>
                      <Value>{transaction.country || "N/A"}</Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>Industry:</Label>
                      <Value>{transaction.industry || "N/A"}</Value>
                    </InfoRow>

                    {/* Conditionally render the rest based on filteredHypotheses_Details */}
                    {filteredHypotheses_Details.filter((hypothesis) => hypothesis.source[0] === transaction.id).length > 0 ? (
                      filteredHypotheses_Details
                        .filter((hypothesis) => hypothesis.source[0] === transaction.id)
                        .map((hypothesis) => {
                          const originalLabel = hypothesis.labels[0];
                          const truncatedLabel = applyEllipsis(originalLabel, 10) + " :";

                          // If the label is not truncated, set originalLabel to empty
                          const tooltipLabel = truncatedLabel === originalLabel ? "" : originalLabel;

                          return (
                            <InfoRow key={hypothesis.id}>
                              {tooltipLabel ? (
                                <Tooltip title={tooltipLabel} arrow>
                                  <Label>{truncatedLabel || "Label"}</Label>
                                </Tooltip>
                              ) : (
                                <Label>{truncatedLabel || "Label"}</Label>
                              )}
                              <Value>{hypothesis.values[0] || "N/A"}</Value>
                            </InfoRow>
                          );
                        })
                    ) : (
                      <>
                        <InfoRow>
                          <Label>Employees:</Label>
                          <Value>{transaction.employees || "N/A"}</Value>
                        </InfoRow>
                        <InfoRow>
                          <Label>Earnings (LTM):</Label>
                          <Value>{transaction.earnings || "N/A"}</Value>
                        </InfoRow>
                        <InfoRow>
                          <Label>Ownership:</Label>
                          <Value>{transaction.ownership || "N/A"}</Value>
                        </InfoRow>
                        <InfoRow>
                          <Label>Mgmt to stay:</Label>
                          <Value>{transaction.mgmtToStay || "N/A"}</Value>
                        </InfoRow>
                      </>
                    )}
                  </StyledDetailsBox>
                ) : title === "Overview" ? (
                  <div className="flex flex-col mx-0 px-0 h-full justify-around">
                    <Box sx={{ px: open ? 2 : 4, pt: open ? 1 : 2, pb: open ? 2 : 0 }}>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          lineHeight: "1.2em",
                          height: "3.6em", // Approximately 2 lines
                        }}
                      >
                        {transaction?.overview?.length > 170 ? `${transaction.overview.slice(0, 170)}...` : transaction.overview}
                      </Typography>
                    </Box>
                    <Divider
                      style={{ color: "#000", padding: "1px" }}
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        backgroundColor: "black",
                      }}
                    />
                    <Stack direction="column" px={2} pb={0}>
                      <Typography variant="body1" sx={{ minWidth: "120px", fontWeight: "bold", py: "4px" }}>
                        {"Recommendation"}
                      </Typography>

                      {[
                        { label: "Valuation", value: transaction.valuation },
                        { label: "Operations", value: transaction.operation },
                        { label: "Complexity", value: transaction.complexity },
                        { label: "Location", value: transaction.location },
                        { label: "Bankability", value: transaction.bankability },
                      ].map((item) => (
                        <Box
                          key={item.label}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            gap: "12px",
                          }}
                        >
                          <Typography variant="body2" sx={{ minWidth: "120px", fontWeight: 500 }}>
                            {item.label}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={item.value || 0}
                            sx={{
                              width: "100%",
                              height: 9,
                              borderRadius: "8px",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: color,
                              },
                              bgcolor: "transparent",
                              border: "1px solid gray",
                            }}
                          />
                        </Box>
                      ))}
                    </Stack>

                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        height: "80px",
                        padding: "0px",
                        pb: "8px",
                        margin: "0px",
                        width: "100%",
                        borderTop: "3px solid black",
                      }}
                    >
                      {Object.entries(features).map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                            <Typography variant="body1" fontWeight="bold" pb={0}>
                              {key}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: "bold",
                                color: color,
                              }}
                            >
                              {value}
                            </Typography>
                          </Box>
                          {index < Object.entries(features).length - 1 && (
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              style={{
                                padding: "1px",
                              }}
                              sx={{
                                margin: "0",
                                padding: "0",
                                backgroundColor: "black",
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Box> */}
                  </div>
                ) : title === "KPI" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      pb: "0px !important",
                    }}
                  >
                    <DataTable title="Revenue" data={sample} />
                    <DataTable title="Other Metrics" data={sample1} />
                  </Box>
                ) : title === "Access due diligence" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary" p={2}>
                      {/* {transaction.dd_summary?.length > 120 ? `${transaction.dd_summary.slice(0, 120)}...` : transaction.dd_summary} */}
                      Click to explore industry based due diligence questionnaires
                    </Typography>
                    <Divider
                      style={{ color: "#000", padding: "1px" }}
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        backgroundColor: "black",
                      }}
                    />
                    <Typography
                      variant="h1"
                      sx={{
                        color: color,
                        fontWeight: "semibold",
                      }}
                    >
                      {transaction.dd_score}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: color,
                        fontWeight: "bold",
                      }}
                    >
                      Click to access the due diligence
                    </Typography>
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Button>
        </Tooltip>
      </Grid>
      <OverviewDialog />
    </>
  );
};

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "bidding":
      return "green";
    case "kill":
      return "red";
    case "pending":
      return "orange";
    default:
      return "grey";
  }
};

const StatusChip = ({ status }) => (
  <Chip
    label={status}
    sx={{
      backgroundColor: getStatusColor(status),
      color: "white",
      fontWeight: "bold",
      fontSize: "20px",
      borderRadius: "8px",
      padding: "0px 8px",
      width: "140px",
      height: "40px",
      marginRight: "36px",
    }}
  />
);

const TransactionSection = forwardRef(({ transaction, open, handleDue, handleKpi, handleDetails, colorIndex, saveTransaction, isLoadingTransactions, isStartConversationLoading, setIsStartConversationLoading, setIsLoadingTransactions, errorFiles, hyp, isloadinghyp }, ref) => {
  const navigate = useNavigate();
  // console.log("transaction ---> ", transaction);
  const detailColors = ["#1AB5B8", "#B81A80"];
  const cardBackgroundColor = detailColors[colorIndex % 2];
  const statuses = ["Bidding", "Kill", "Pending"];
  const [status, setStatus] = useState(statuses[colorIndex % 3]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const documents = useSelector((state) => state.documents?.documents[0]?.documents);
  const { enqueueSnackbar } = useSnackbar();
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user?.id);
  const documentsWithErrors = errorFiles.filter((error) => error.transaction_id === transaction.id);

  const list_info = [
    <InfoCard icon={<MessageCircle color="white" />} title={"Start conversation"} subtitle={"Chat with the transaction"} backgroundColor={"#000"} onClick={() => handleStartConversation(transaction)} disabled={!transaction.document_uploaded} />,
    <InfoCard
      icon={<Table2 color="white" />}
      title={"Get tables"}
      subtitle={"Access transaction tables"}
      backgroundColor={"#000"}
      onClick={() => {
        handleClickTable(transaction);
      }}
      disabled={!transaction.document_uploaded}
    />,
    <InfoCard
      icon={<PeopleOutlineIcon sx={{ color: "white" }} />}
      title={"Expert Call"}
      subtitle={"Explore the industry's 101s"}
      backgroundColor={"#000"}
      tooltip={`No expert call available for the ${transaction.industry.toLowerCase()} industry`}
      onClick={() => {}}
      disabled={!transaction.document_uploaded}
    />,
  ];
  const handleClickTable = async (transaction) => {
    if (isLoadingTransactions || isStartConversationLoading) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsLoadingTransactions(true);
    await dispatch(clearDocuments());
    // for (const transactionId of selected) {
    await dispatch(fetchDocuments(transaction.id));
    setIsLoadingTransactions(false);
    navigate(`/table/${transaction.id}`, { state: { transaction } });
    enqueueSnackbar("Document tables Opened!", { variant: "success" });
  };

  useEffect(() => {
    const checkScroll = () => {
      // if (!transaction.document_uploaded) return;
      if (!scrollContainerRef?.current) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    };

    const checkWindowSize = () => {
      setShowScrollButtons(window.innerWidth < 1450);
    };

    const handleResize = () => {
      checkWindowSize();
      checkScroll();
    };

    checkScroll(); // Check on mount
    checkWindowSize();
    scrollContainerRef?.current?.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      scrollContainerRef?.current?.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollLeft = () => {
    // if (!transaction.document_uploaded) return;
    scrollContainerRef.current.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    // if (!transaction.document_uploaded) return;

    scrollContainerRef.current.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  const handleStatusChange = async (transaction, newStatus) => {
    setStatus(newStatus);
    console.log(newStatus);
    const propertyName = "status";
    const newValue = newStatus;
    const append = false;
    const remove = false;
    const transactionUpdated = await backendClient.updateTransactionProperty(transaction.id, propertyName, newValue, append, remove);
    console.log(transactionUpdated);
    enqueueSnackbar("Status updated successfully", { variant: "success" });
  };

  const handleOpenUploadDialog = () => {
    setOpenUpload(true);
  };

  const handleCloseUploadDialog = () => {
    setOpenUpload(false);
  };

  const handleOpenEditDialog = () => {
    setOpenEdit(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEdit(false);
  };

  const handlePreview = async () => {
    // redirect transactions/{transaction.id}
    console.log("transaction ---> ", transaction.id);
    navigate(`/data-import/transactions/${transaction.id}`);
    // setIsLoadingTransactions(true);
    const detail_doc = await dispatch(fetchDocuments(transaction.id));
    console.log(detail_doc);
    // setIsLoadingTransactions(false);
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };
  const handleStartConversation = async (transaction) => {
    if (isLoadingTransactions || isStartConversationLoading) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsLoadingTransactions(true);
    setIsStartConversationLoading(true);
    // get selected transactions
    const filtred_transactions = [transaction];
    await dispatch(setTransactions(filtred_transactions));

    // fetch documents

    await dispatch(clearDocuments());
    // for (const transactionId of selected) {
    await dispatch(fetchDocuments(transaction.id));
    // }

    // create converstation
    const transactionId_documentsIds = filtred_transactions.map((x) => {
      return { transaction_id: x.id, documents_ids: x.documents_ids };
    });
    const conversationId_ = await dispatch(createConversation(transactionId_documentsIds, user_id));

    setIsStartConversationLoading(false);
    setIsLoadingTransactions(false);
    navigate(`/chat/${conversationId_}`);
    enqueueSnackbar("Conversation started!", { variant: "success" });
  };

  return (
    <>
      <Grid ref={ref} item xs={12} sx={{ p: 2, mb: 2, bgcolor: "white", borderRadius: 2, width: "100%", boxShadow: "none" }}>
        <Box sx={{ display: "flex", alignItems: "top", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Spinner */}
            {!transaction.document_uploaded && (
              <>
                <Tooltip title="Transaction uploading">
                  <CircularProgress size={20} />
                </Tooltip>
              </>
            )}

            {/* Transaction Name */}
            <Typography variant="h5" sx={{ color: cardBackgroundColor, fontWeight: "bold" }}>
              {transaction.name}
            </Typography>

            {/* Attention Icon with Tooltip */}
            {documentsWithErrors.length > 0 && (
              <Tooltip title="There was an error processing 1 or more documents. Please try again">
                <ErrorOutline color="error" />
              </Tooltip>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <StatusSelector initialStatus={transaction.status} onStatusChange={(newStatus) => handleStatusChange(transaction, newStatus)} disabled={!transaction.document_uploaded} />
            <Tooltip title="Edit the transaction" arrow>
              <Button disabled={!transaction.document_uploaded} variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }} onClick={handleOpenEditDialog}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Edit />
                  <Typography variant="body2">Edit</Typography>
                </Box>
              </Button>
            </Tooltip>
            {true && (
              <Tooltip title="Preview or Edit transaction documents" arrow>
                <Button disabled={!transaction.document_uploaded} variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }} onClick={handlePreview}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Eye />
                    <Typography variant="body2">Documents</Typography>
                  </Box>
                </Button>
              </Tooltip>
            )}
            {/* <Tooltip title="Start conversation with transaction documents" arrow>
              <Button variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }} disabled={isStartConversationLoading} onClick={() => handleStartConversation(transaction)}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <MessageCircle />
                  <Typography variant="body2"> {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}</Typography>
                </Box>
              </Button>
            </Tooltip> */}
            {/* <Tooltip title="Add documents to the transaction" arrow>
              <Button variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }} onClick={handleOpenUploadDialog}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Paperclip />
                  <Typography variant="body2">Add Documents</Typography>
                </Box>
              </Button>
            </Tooltip> */}
          </Box>
        </Box>

        {/* Horizontal Scroll Section */}
      </Grid>
      {
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", position: "relative" }}>
          <Box
            ref={scrollContainerRef}
            sx={{
              display: "flex",
              width: "95%",
              justifyContent: "flex-start",
              gap: 5,
              overflowX: "auto",
              scrollBehavior: "smooth",
              paddingBottom: 2,
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <TransactionCard title="Overview" transaction={transaction} color={cardBackgroundColor} open={open} handleDetails={handleDetails} handleDue={handleDue} handleKpi={handleKpi} sx={{ width: "280px", height: "400px" }} hyp={hyp} isloadinghyp={isloadinghyp} />
            {/* <TransactionCard title="Details" transaction={transaction} color={cardBackgroundColor} open={open} handleDetails={handleDetails} handleDue={handleDue} handleKpi={handleKpi} hyp={hyp} isloadinghyp={isloadinghyp} />
          <TransactionCard title="KPI" transaction={transaction} color={cardBackgroundColor} open={open} handleDetails={handleDetails} handleDue={handleDue} handleKpi={handleKpi} saveTransaction={saveTransaction} hyp={hyp} isloadinghyp={isloadinghyp} /> */}
            <TransactionCard title="Access due diligence" transaction={transaction} color={cardBackgroundColor} open={open} handleDetails={handleDetails} handleDue={handleDue} handleKpi={handleKpi} saveTransaction={saveTransaction} hyp={hyp} isloadinghyp={isloadinghyp} />
            <InfoCardList title={"Actions"} list_info={list_info} />
          </Box>

          {showScrollButtons && canScrollLeft && (
            <IconButton
              onClick={handleScrollLeft}
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                zIndex: 1000,
                transform: "translateY(-50%)",
                backgroundColor: "white",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <ArrowBackIos />
            </IconButton>
          )}

          {showScrollButtons && canScrollRight && (
            <IconButton
              onClick={handleScrollRight}
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                zIndex: 1000,
                transform: "translateY(-50%)",
                backgroundColor: "white",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          )}
        </Box>
      }
      {openUpload && <CardUpload open={openUpload} onClose={handleCloseUploadDialog} />}
      {openEdit && <EditDeal open={openEdit} handleClose={handleCloseEditDialog} dataTransaction={transaction} />}
      {openPdfViewer && <PdfViewerDialog open={openPdfViewer} handleClose={handleClosePdfViewer} document={documents[0]} />}
    </>
  );
});

const AllDeals = ({ setView, saveTransactions }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState([]);
  const [isLoadingExtractHypothesis, setIsLoadingExtractHypothesis] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isLoadingExplore, setIsLoadingExplore] = useState(false);
  const [isStartConversationLoading, setIsStartConversationLoading] = useState(false);
  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const user_id = useSelector((state) => state.auth.user?.id);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const open = useSelector((state) => state.navBar.is_open);
  const [isFetchingMore, setIsFetchingMore] = useState(false); // To track the spinner when fetching more rows

  const transactions = useSelector((state) => state.transactions.transactions || []);
  const [totalDeals, setTotalDeals] = useState(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    region: "",
    industry: "",
    minValue: "",
    maxValue: "",
    showCompleted: false,
  });

  const lastTransactionRef = useRef(null); // Ref for detecting when the bottom is reached
  const [errorFiles, setErrorFiles] = useState([]);
  const [isloadinghyp, setIsLoadingHyp] = useState(false); // Loading state for hypothesis
  const [hyp, setHyp] = useState([]);
  const fetch_fn = async () => {
    if (!isFetchingMore) {
      setIsLoadingTransactions(true);
    }
    await dispatch(fetchTransactions(page + 1, rowsPerPage));
    setIsFetchingMore(false); // Reset fetching state after data is loaded

    setIsLoadingTransactions(false);
  };
  const fetch_total_deals = async () => {
    if (totalDeals) return;
    const totalDeals_ = await backendClient.countTransactions();
    setTotalDeals(totalDeals_["count"]);
  };

  const getHypotheses = async () => {
    if (!transactions || transactions.length === 0 || transactions.some((transaction) => transaction.document_uploaded === false)) return;
    setIsLoadingHyp(true); // Set loading state
    const response = await backendClient.fetchHypothesis_type("Details");
    const response2 = await backendClient.fetchHypothesis_type("KPI");
    setHyp([...response, ...response2]); // Store fetched hypotheses
    setIsLoadingHyp(false); // Disable loading state
  };

  // useEffect(() => {
  //   if (transactions) {
  //     getHypotheses();
  //   }
  // }, [transactions]);

  useEffect(() => {
    fetch_fn();
  }, [dispatch, page, rowsPerPage]);
  useEffect(() => {
    fetch_total_deals();
  }, []);

  // Callback for the intersection observer to fetch more rows when the last item is visible
  const observerCallback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        console.log("Observer callback entry:", entry);
      });
      const [entry] = entries;
      if (entry.isIntersecting && transactions.length < totalDeals && !isFetchingMore) {
        console.log("Last transaction is intersecting, fetching more data");
        setIsFetchingMore(true);
        setRowsPerPage((prevPage) => prevPage + 5); // Increase the page number
      }
    },
    [transactions.length, totalDeals, isFetchingMore],
  );

  // Set up the observer on the last item
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 0.1 });
    console.log("Setting up observer on:", lastTransactionRef.current);
    if (lastTransactionRef.current) {
      observer.observe(lastTransactionRef.current);
    } else {
      console.log("lastTransactionRef.current is null");
    }

    return () => {
      if (lastTransactionRef.current) {
        observer.unobserve(lastTransactionRef.current);
      }
    };
  }, [filteredTransactions, observerCallback]);

  console.log("transactions", transactions);
  const handleDetails = () => {
    setView("DETAILS");
  };

  const handleSaveCurrentTransaction = (transaction) => {
    saveTransactions(transaction);
  };
  const handleDue = () => {
    setView("DUE");
  };

  const handleExtractHypothesis = async (transaction) => {
    if (true) return;
    if (isLoadingExtractHypothesis || isLoadingTransactions || isStartConversationLoading || isLoadingExplore || !transaction.document_uploaded) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }

    setIsLoadingExtractHypothesis(true);
    await dispatch(clearHypothesis());

    await dispatch(setTransactions([transaction]));

    if (transaction.is_extracted === "True" || transaction.is_extracted === "Pending") {
      await dispatch(fetchHypothesis(transaction.id));
    } else {
      await dispatch(extractHypothesis([transaction.id], user_id));
    }
  };
  const handleKpi = async (transaction) => {
    if (!transaction.document_uploaded) return;
    setView("KPI");
    // setIsLoadingTransactions(true);
    await handleExtractHypothesis(transaction);
    // setIsLoadingTransactions(false);
  };

  useEffect(() => {
    // console.log("filtering", transactions);
    setFilteredTransactions(transactions);
  }, [transactions]);
  useEffect(() => {
    console.log("filtering", transactions);
    setFilteredTransactions(transactions);
  }, [transactions]);

  const applyFilters = useCallback(
    (transactions) => {
      console.log(filters);
      return transactions.filter((transaction) => {
        const matchesSearch = (transaction?.name || "").toLowerCase().includes((searchQuery || "").toLowerCase());
        const matchesStatus = !filters.status || (transaction?.status || "").toLowerCase() === (filters.status || "").toLowerCase();
        const matchesRegion = !filters.region || (transaction?.country || "").toLowerCase() === (filters.region || "").toLowerCase();
        const matchesIndustry = !filters.industry || (transaction?.industry || "").toLowerCase() === (filters.industry || "").toLowerCase();
        const matchesMinValue = !filters.minValue || (transaction?.revenue || 0) >= Number(filters.minValue);
        const matchesMaxValue = !filters.maxValue || (transaction?.revenue || 0) <= Number(filters.maxValue);
        const matchesShowCompleted = !filters.showCompleted || transaction?.status !== "completed";

        console.log({
          matchesSearch,
          matchesStatus,
          matchesRegion,
          matchesIndustry,
          matchesMinValue,
          matchesMaxValue,
          matchesShowCompleted,
        });

        return matchesSearch && matchesStatus && matchesRegion && matchesIndustry && matchesMinValue && matchesMaxValue && matchesShowCompleted;
      });
    },
    [searchQuery, filters],
  );

  useEffect(() => {
    if (transactions) {
      console.log(transactions);
      const filtered = applyFilters(transactions);
      console.log(filtered);
      setFilteredTransactions(filtered);
    }
  }, [transactions, searchQuery, filters, applyFilters]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    handleCloseFilterDialog();
  };

  const handleExploreTransaction = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return;
    }
    setIsLoadingExplore(true);
    const snackbar = enqueueSnackbar("Loading transaction...");
    await dispatch(clearExploredData());
    await dispatch(clearDocuments());

    // get selected transactions
    const filtred_transactions = transactions.filter((x) => selected.includes(x.id));
    await dispatch(setTransactions(filtred_transactions));

    for (const transactionId of selected) {
      console.log("transactionId", transactionId);
      await dispatch(fetchDocuments(transactionId));
    }

    // navigate to explore
    if (location.pathname === "/deals") {
      navigate("/deals/explore");
    } else {
      navigate(`/data-import/transactions/explore`);
    }
    setIsLoadingExplore(false);
    closeSnackbar(snackbar);
    enqueueSnackbar("Explore transaction started!", { variant: "success" });
  };
  const fetchErrors = async () => {
    try {
      const documentsError = await backendClient.get_error_file();
      setErrorFiles(documentsError);
    } catch (err) {
      console.error("Error fetching document errors:", err);
    }
  };

  useEffect(() => {
    fetchErrors();
  }, []);
  const handleDeleteTransaction = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsDeleteModalVisisble(true);
  };

  return (
    <Documentstart
      title={"Deals"}
      open={open}
      defaultwidth={false}
      headerProps={{
        searchQuery: searchQuery,
        setSearchQuery: setSearchQuery,
        handleSearchChange: handleSearchChange,
        handleClearSearch: handleClearSearch,
        showFilter: true,
        handleOpenFilterDialog: handleOpenFilterDialog,
        handleCloseFilterDialog: handleCloseFilterDialog,
        handleApplyFilters: handleApplyFilters,
        initialFilters: filters,
        filterDialogOpen: filterDialogOpen,
      }}
    >
      {isLoadingTransactions ? (
        <Grid item xs={12} md={open ? 9 : 12} lg={open ? 13 : 12}>
          <Spinner />
        </Grid>
      ) : (
        <Grid item xs={12} md={open ? 9 : 12} lg={open ? 13 : 12}>
          {filteredTransactions.length > 0 ? (
            <>
              {filteredTransactions.map((transaction, index) => (
                <TransactionSection
                  key={transaction.id}
                  transaction={transaction}
                  open={open}
                  handleDue={handleDue}
                  handleKpi={() => handleKpi(transaction)}
                  handleDetails={handleDetails}
                  colorIndex={index}
                  saveTransaction={handleSaveCurrentTransaction}
                  isLoadingTransactions={isLoadingTransactions}
                  isStartConversationLoading={isStartConversationLoading}
                  setIsStartConversationLoading={setIsStartConversationLoading}
                  setIsLoadingTransactions={setIsLoadingTransactions}
                  errorFiles={errorFiles}
                  hyp={hyp}
                  isloadinghyp={isloadinghyp}
                  ref={index === filteredTransactions.length - 1 ? lastTransactionRef : null}
                />
              ))}
              {isFetchingMore && (
                <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                  <Spinner />
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" color="text.secondary">
                No deals found
              </Typography>
              <Typography variant="body1" color="text.secondary" mt={2}>
                Try adjusting your search or filter criteria
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Documentstart>
  );
};

export default AllDeals;
