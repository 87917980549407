import React, { useEffect } from "react";
// import { Routes, Route, useNavigate } from "react-router-dom";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "../components/NavigationBar";
import { getUser } from "../redux/actions/authAction";
import Chatbox from "../screens/ChatScreen/ChatBox";
import ChatHistory from "../screens/ChatScreen/ChatHistory";
import ChatScreen from "../screens/ChatScreen/ChatScreen";
import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import DataScreen from "../screens/DataScreen/DataScreen";
import DocumentsScreen from "../screens/DataScreen/DocumentsScreen";
import ExploreScreen from "../screens/DataScreen/ExploreScreen";
import KpiHypothesis from "../screens/DataScreen/KpiHypothesis";
import TransactionsScreen from "../screens/DataScreen/TransactionsScreen";
import NewDueDiligence from "../screens/DataScreen/newDueDiligence";
import NewExplore from "../screens/DataScreen/newExplore";
import DealsPage from "../screens/DealsScreen/DealPages";
import DemoScreen from "../screens/DemoScreen/DemoScreen";
import NewKeywordPairs from "../screens/HypothesisScreen/NewKeywordPairs";
import InvitationScreen from "../screens/LandingPage/InvitationScreen";
import LandingPageScreen from "../screens/LandingPage/LandingPageScreen";
import LandingPageScreenSMB from "../screens/LandingPageSMB/LandingPageScreenSMB";
import NotFoundScreen from "../screens/NotFoundScreen/NotFoundScreen";
import SettingsScreen from "../screens/SettingsScreen/SettingsScreen";
import SignInScreen from "../screens/SignInScreen/SignInScreen";
import NewChatScreen from "../screens/ChatScreen/NewChatScreen";
import DueDiligenceTemplate from "../screens/DueDiligenceTemplate/DueDiligenceTemplate";
import CardUpload from "../screens/DataScreen/CardUpload";
import QuestionnaireTemplate from "../screens/DueDiligenceTemplate/questionnairetemplate";
import JustDD from "../screens/DueDiligenceScreen/justDD";
import Questionnaire from "../screens/ChatScreen/Questionnaire";
import Table from "../screens/ChatScreen/Tables";
// This component must be used inside the component tree that is a child of <Router>
const NavigationBarWrapper = () => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if the current path is exactly '/data-import'
  //   if (location.pathname === "/data-import" && location.hash.length > 1) {
  //     navigate("/data-import");
  //   }
  // }, [location, navigate]);

  const pathsWhereNavigationBarShouldBeShown = [
    "/dashboard",
    "/deals",
    "/deals/hypothesis",
    "/data-import",
    "/data-import/transactions",
    "/data-import/transactions/:transactionId", // Dynamic paths should be handled differently if necessary
    "/data-import/transactions/explore",
    "/hypothesis",
    "/keyword-pairs",
    "/chat",
    "/chat/:conversationId", // Dynamic paths should be handled differently if necessary
    "/settings",
    "/demo",
    "/keyword",
    "/duediligencetemplate",
    "/questionnairetemplate",
    "/questionnairetemplate/:questionnaireId",
    "/duediligence",
    "/questionnaire",
    "/questionnaire/:workstreamId",
    "/table",
    "/table/:transactionId",
    // Add any other paths as needed
  ];

  const shouldShowNavigationBar = isAuthenticated && pathsWhereNavigationBarShouldBeShown.some((path) => location.pathname === path || location.pathname.startsWith(path + "/"));
  return shouldShowNavigationBar ? <NavigationBar selected={"data-import"} /> : null;
};

const HandleAuthCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash.includes("code=")) {
      // Extract the code from the hash
      const code = new URLSearchParams(hash.substring(1)).get("code");
      if (code) {
        // Here, you could dispatch this code to your global state, or perform an API call
        // For example, exchanging the code for an access token

        // Then navigate to the intended route
        navigate("/data-import", { replace: true });
      }
    }
  }, [location, navigate]);

  // This component does not render anything, or it could render a loading indicator
  return null;
};

const AppRouter = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log("isAuthenticated", isAuthenticated);
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  const isLoading = useSelector((state) => state.auth.isLoading); // Adjust according to your Redux setup
  // console.log("isLoading", isLoading);
  // console.log("accounts", accounts);

  // if user authenticated, get user info
  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) {
      // console.log(accounts);
      dispatch(getUser(accounts[0]?.idTokenClaims?.oid));
    }
  }, [isAuthenticated]);

  return (
    <Router>
      {/* {showNavigationBar && <NavigationBar selected={"data-import"} />} */}
      {/* <TokenAcquirer /> */}

      {isAuthenticated ? (
        <>
          <NavigationBarWrapper />

          <Routes>
            <>
              <Route path="/" element={<LandingPageScreen />} />
              <Route path="/smb" element={<LandingPageScreenSMB />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              {/* <Route path="/deals" element={<TransactionsScreen />} /> */}
              {/*  */}
              <Route path="/deals" element={<DealsPage />} />
              <Route path="/deals/:transactionId" element={<DocumentsScreen />} />
              <Route path="/deals/explore" element={<NewDueDiligence />} />
              {/* <Route path="/deals/explore" element={<ExploreScreen />} /> */}
              {/* <Route path="/deals/hypothesis" element={<DealDashboardScreen />} /> */}
              <Route path="/deals/hypothesis" element={<KpiHypothesis />} />
              {/* <Route path="/deals/hypothesis/explore" element={<ExploreScreen />} /> */}
              <Route path="/deals/hypothesis/explore" element={<NewExplore />} />
              <Route path="/data-import" element={<DataScreen />} />
              <Route path="/import-data" element={<CardUpload />} />
              <Route path="/data-import/transactions/" element={<TransactionsScreen />} />
              <Route path="/data-import/transactions/:transactionId" element={<DocumentsScreen />} />
              <Route path="/data-import/transactions/explore" element={<ExploreScreen />} />
              {/* <Route path="/keyword-pairs" element={<KeywordPairsScreen />} /> */}
              <Route path="/keyword-pairs" element={<NewKeywordPairs />} />
              <Route path="/chat" element={<ChatHistory />} />
              <Route path="/chats" element={<Chatbox />} />
              <Route path="/chat/:conversationId" element={<NewChatScreen />} />
              <Route path="/demo" element={<DemoScreen />} />
              <Route path="/settings" element={<SettingsScreen />} />
              <Route path="/duediligencetemplate" element={<DueDiligenceTemplate />} />
              <Route path="/questionnaire/:workstreamId" element={<Questionnaire />} />
              <Route path="/questionnairetemplate/:questionnaireId" element={<QuestionnaireTemplate />} />
              <Route path="/table/:transactionId" element={<Table />} />
              <Route path="/duediligence/" element={<JustDD />} />
            </>
            <Route path="/sign-in" element={<Navigate replace to="/dashboard" />} />
            <Route path="/invitation" element={<InvitationScreen />} />
            <Route path="/not-found-404" element={<NotFoundScreen />} />
            <Route path="/*" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </>
      ) : (
        <>
          <NavigationBarWrapper />
          <Routes>
            <Route path="/sign-in" element={<SignInScreen />} />
            <Route path="/" element={<LandingPageScreen />} />
            <Route path="/smb" element={<LandingPageScreenSMB />} />
            <Route path="/demo" element={<DemoScreen />} />
            <Route path="/invitation" element={<InvitationScreen />} />
            <Route path="/not-found-404" element={<NotFoundScreen />} />
            <Route path="*" element={<LandingPageScreen />} />
            {/* <Route path="/*" element={<Navigate replace to="/sign-in" />} /> */}
          </Routes>
        </>
      )}
    </Router>
  );
};

export default AppRouter;
