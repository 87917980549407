import React, { useEffect, useState } from "react";
import usePDFViewer from "./usePdfViewer";

import PDFOptionsBar from "./PdfOptionsBar";
import { Grid, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import MemoizedVirtualizedPDF from "./VirtualizedPdf";
import { useDispatch, useSelector } from "react-redux";
import { windowWidth } from "../../utils/utils";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { setActivePdf, setPdfLoadingTask } from "../../redux/actions/documentsAction";
import Spinner from "../Spinner";

const ViewPdf = ({ is_conversation, file, isFileReady, documentId, pdfWidth, pdfHeight, handleRemoveDocument }) => {
  const documents = useSelector((state) => state.documents.documents);

  const { scrolledIndex, setCurrentPageNumber, scale, setScaleFit, numPages, setNumPages, handleZoomIn, handleZoomOut, nextPage, prevPage, scaleText, pdfFocusRef, goToPage, setZoomLevel, zoomInEnabled, zoomOutEnabled } = usePDFViewer(file && file);
  const loadingTask = useSelector((state) => state.documents.loadingTask);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Return a cleanup function that ensures the worker is destroyed when the component unmounts
    return () => {
      if (loadingTask) {
        try {
          console.log("DESTROYING");
          loadingTask.destroy(); // This destroys the worker and cleans up
        } catch (error) {
          console.error("VIEWPDF - Failed to destroy PDF worker:", error);
        }
      }
    };
  }, [loadingTask]); // Dependency on loadingTask ensures that cleanup runs when it changes

  const handleChange = async (event) => {
    const newValue = event.target.value;
    console.log("Selected New PDF:", newValue);
    if (!newValue) return;

    if (loadingTask) {
      console.log("Destroying existing PDF worker...");
      await loadingTask
        .destroy()
        .then(async () => {
          console.log("Existing PDF worker destroyed.");
          // setLoadingTask(null);
          await dispatch(setPdfLoadingTask(null));
        })
        .catch((error) => console.error("Failed to destroy PDF worker:", error));
    }

    try {
      console.log("Creating new PDF loading task...");
      const newLoadingTask = await pdfjsLib.getDocument(newValue.url);
      // setLoadingTask(newLoadingTask);
      if (loadingTask) {
        console.log("Destroying existing PDF worker...");
        await loadingTask.destroy();
        console.log("Existing PDF worker destroyed.");
      }
      setLoading(true);
      await dispatch(setPdfLoadingTask(newLoadingTask));
      newLoadingTask.promise
        .then(async (pdfDocument) => {
          console.log("New PDF loaded:", newValue);
          await dispatch(setActivePdf(newValue));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Failed to load PDF:", error);
        });
    } catch (error) {
      console.error("Error setting up new PDF:", error);
    }
  };

  return (
    <>
      <Box gap={1} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <PDFOptionsBar
          file={file}
          scrolledIndex={scrolledIndex}
          numPages={numPages}
          scaleText={scaleText}
          nextPage={nextPage}
          prevPage={prevPage}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          goToPage={goToPage}
          setZoomLevel={setZoomLevel}
          zoomInEnabled={zoomInEnabled}
          zoomOutEnabled={zoomOutEnabled}
          documents={documents}
          changeDocument={handleChange}
        />
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          {loading ? <Spinner /> : file && <MemoizedVirtualizedPDF is_conversation={is_conversation} ref={pdfFocusRef} file={file} setIndex={setCurrentPageNumber} scale={scale} setScaleFit={setScaleFit} setNumPages={setNumPages} pdfWidth={pdfWidth} pdfHeight={pdfHeight} />}
        </Box>
      </Box>
    </>
  );
};

export default ViewPdf;
// export default memo(ViewPdf);
