import React, { useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, TextField, Typography, Box, Tooltip } from "@mui/material";

const options = ["Query the dataroom", "Query past internal papers", "Query similar transactions", "Answer the query from the web", "Ask ChatGPT", "Tabularize results"];

const AddTaskDialog = ({ open, handleClose, addTask }) => {
  const [taskTitle, setTaskTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSubmit = () => {
    const newTask = {
      taskTitle,
      question,
      selectedOptions,
    };
    console.log(newTask);
    addTask(newTask);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Add Task
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Use this form to add a new task
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6">Title:</Typography>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <TextField label="Task Title" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} fullWidth />
        </FormControl>

        <Typography variant="h6" sx={{ mt: 1 }}>
          Question:
        </Typography>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <TextField label="Question" value={question} onChange={(e) => setQuestion(e.target.value)} fullWidth />
        </FormControl>

        <Typography variant="h6" sx={{ mt: 3 }}>
          {`How should the following question be answered?`}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          {options.map((option, index) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={selectedOptions.includes(option)} onChange={() => handleCheckboxChange(option)} />}
              label={
                <Tooltip title="Adding new tasks is disabled for the pilot">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{`${index + 1}. ${option}`}</Typography>
                  </Box>
                </Tooltip>
              }
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "black",
            borderRadius: "6px",
            fontSize: 16,
            padding: "10px 20px",
            width: "180px",
            height: "60px",
          }}
        >
          Cancel
        </Button>
        <Tooltip title="Adding workstream is disabled in the pilot">
          <Button
            onClick={handleSubmit}
            disabled={true}
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              borderRadius: "6px",
              "&:hover": {
                bgcolor: "black",
                color: "white",
              },
              width: "180px",
              height: "60px",
              fontSize: 16,
              padding: "10px 20px",
            }}
          >
            Add Task
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddTaskDialog;
