import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Grid, CircularProgress } from "@mui/material";
import { backendClient } from "../../../api/backend";
import { useDispatch, useSelector } from "react-redux";
import { getSynchronized } from "../../../redux/actions/keywordAction";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SummaryCard = ({ title, value, isLoading }) => {

  const cardBackgroundColor = title === "Total hypothesis" ? "#B81A80" : "white";

  const titleStyle = {
    fontSize: "16px",
    color: title === "Total hypothesis" ? "white" : "#9E9E9E", 
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const valueStyle = {
    fontSize: "28px",
    color: title === "Total hypothesis" ? "white" : "#000000", 
    fontWeight: "semibold",
  };

  return (
    <Card
      sx={{
        height: "260px",
        borderRadius: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        backgroundColor: cardBackgroundColor,
      }}
    >
      <CardContent>
        <Typography sx={titleStyle} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        {isLoading ? (
          <Box sx={{ marginTop: "16px" }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Typography sx={valueStyle} variant="h5" component="div">
            {value}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const SynchronizedCard = ({ synchronized, isLoading }) => {
  const syncStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: synchronized ? "#4CAF50" : "#F44336",
    fontSize: "16px",
    fontWeight: "bold",
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CardContent>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Box sx={syncStyle}>
            {synchronized ? (
              <>
                <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                Synchronized
              </>
            ) : (
              <>
                <HighlightOffIcon sx={{ marginRight: "8px" }} />
                Not Synchronized
              </>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const DealsSummary = () => {
  const dispatch = useDispatch();

  const [isLoadingTotalHypothesis, setIsLoadingTotalHypothesis] = useState(true);
  const [isLoadingTotalKeyKeywords, setIsLoadingTotalKeyKeywords] = useState(true);
  const [isLoadingTotalKeywords, setIsLoadingTotalKeywords] = useState(true);
  const [isLoadingSynchronize, setIsLoadingSynchronize] = useState(true);

  const [totalHypothesis, setTotalHypothesis] = useState(0);
  const [totalKeyKeywords, setTotalKeyKeywords] = useState(0);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [synchronize, setSynchronize] = useState(null);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetch_fn = async () => {
      try {
        setIsLoadingTotalHypothesis(true);
        const totalHypothesis_ = await backendClient.countHypothesis();
        setTotalHypothesis(totalHypothesis_["count"]);
        setIsLoadingTotalHypothesis(false);

        setIsLoadingTotalKeywords(true);
        const totalKeywords_ = await backendClient.countKeywords();
        setTotalKeywords(totalKeywords_["count"]);
        setIsLoadingTotalKeywords(false);

        setIsLoadingTotalKeyKeywords(true);
        const totalKeyKeywords_ = await backendClient.countKeyKeywords();
        setTotalKeyKeywords(totalKeyKeywords_["count"]);
        setIsLoadingTotalKeyKeywords(false);

        setIsLoadingSynchronize(true);
        const synchronize_ = await dispatch(getSynchronized());
        setSynchronize(synchronize_);
        setIsLoadingSynchronize(false);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
    fetch_fn();
  }, [user]);

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: "16px", fontWeight: "bold", color: "#B81A80" }}>
        Hypothesis Summary
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SynchronizedCard synchronized={synchronize?.synchronized} isLoading={isLoadingSynchronize} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Total keywords" value={totalKeywords} isLoading={isLoadingTotalKeywords} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Total hypothesis" value={totalHypothesis} isLoading={isLoadingTotalHypothesis} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Total key hypothesis" value={totalKeyKeywords} isLoading={isLoadingTotalKeyKeywords} />
        </Grid>
      </Grid>
    </>
  );
};

export default DealsSummary;
