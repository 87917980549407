import { HIDE_SNACKBAR, SHOW_SNACKBAR } from "./types";


// Action Creators
export const showSnackbar = (message, severity) => ({
  type: SHOW_SNACKBAR,
  payload: { message, severity },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
