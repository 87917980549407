// src/reducers/templateQuestionnaireReducer.js

import {
  FETCH_TEMPLATE_QUESTIONNAIRES,
  CREATE_TEMPLATE_QUESTIONNAIRE,
  UPDATE_TEMPLATE_QUESTIONNAIRE,
  DELETE_TEMPLATE_QUESTIONNAIRE,
  BULK_UPDATE_TEMPLATE_QUESTIONNAIRES,
  BULK_DELETE_TEMPLATE_QUESTIONNAIRES,
  SET_TEMPLATE_QUESTIONNAIRES,
  COUNT_QUESTIONS,
  TEMPLATE_QUESTIONNAIRE_REQUEST,
  TEMPLATE_QUESTIONNAIRE_FAILURE,
} from "../actions/types";

const initialState = {
  templateQuestionnaires: [],
  questionCount: 0,
  loading: false, // Track loading state
  error: null, // Track error state
};

const templateQuestionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    // Loading state
    case TEMPLATE_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when request starts
      };

    // Handle error state
    case TEMPLATE_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload, // Set error message
      };

    // Fetch template questionnaires
    case FETCH_TEMPLATE_QUESTIONNAIRES:
      return {
        ...state,
        templateQuestionnaires: action.payload,
        loading: false, // Loading is done
        error: null, // Reset error state
      };

    // Create a new template questionnaire
    case CREATE_TEMPLATE_QUESTIONNAIRE:
      return {
        ...state,
        templateQuestionnaires: [...state.templateQuestionnaires, action.payload],
        loading: false,
        error: null,
      };

    // Update a template questionnaire
    case UPDATE_TEMPLATE_QUESTIONNAIRE:
      return {
        ...state,
        templateQuestionnaires: state.templateQuestionnaires.map((q) => (q.id === action.payload.id ? action.payload : q)),
        loading: false,
        error: null,
      };

    // Delete a template questionnaire
    case DELETE_TEMPLATE_QUESTIONNAIRE:
      return {
        ...state,
        templateQuestionnaires: state.templateQuestionnaires.filter((q) => q.id !== action.payload),
        loading: false,
        error: null,
      };

    // Bulk update template questionnaires
    case BULK_UPDATE_TEMPLATE_QUESTIONNAIRES:
      return {
        ...state,
        templateQuestionnaires: state.templateQuestionnaires.map((q) => action.payload.find((updatedQ) => updatedQ.id === q.id) || q),
        loading: false,
        error: null,
      };

    // Bulk delete template questionnaires
    case BULK_DELETE_TEMPLATE_QUESTIONNAIRES:
      return {
        ...state,
        templateQuestionnaires: state.templateQuestionnaires.filter((q) => !action.payload.includes(q.id)),
        loading: false,
        error: null,
      };

    // Count questions in a questionnaire
    case COUNT_QUESTIONS:
      return {
        ...state,
        questionCount: action.payload.count,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default templateQuestionnaireReducer;
