import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, FormGroup, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SourceFilter = ({ sourceFilter, isCollapsed, handleCollapseClick, handleGroupToggle, handleItemToggle, checkedState }) => {
  return (
    <div className="mb-4 pt-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm">SOURCE FILTERS</h3>
        <ExpandMoreIcon style={{ color: "gray" }} onClick={handleCollapseClick} className={`transform ${isCollapsed ? "rotate-180" : ""}`} />
      </div>
      <Collapse in={!isCollapsed}>
        {sourceFilter &&
          sourceFilter.map((group, index) => {
            const groupKey = Object.keys(group)[0];
            const items = group[groupKey];
            return (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel label={<span className="text-sm">{groupKey}</span>} aria-label={groupKey} onClick={handleGroupToggle(groupKey)} control={<Checkbox checked={checkedState[groupKey]?.checked} />} />
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {items.map((item, subIndex) => {
                      const itemKey = item.type ? `${item.name} (${item.type})` : `${item.name}`;
                      return <FormControlLabel label={<span className="text-sm">{itemKey}</span>} key={subIndex} control={<Checkbox checked={checkedState[groupKey]?.items[itemKey]} onChange={handleItemToggle(groupKey, itemKey, item)} />} />;
                    })}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Collapse>
    </div>
  );
};

export default SourceFilter;
