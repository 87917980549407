// Assuming these are the action types you've defined
import { CREATE_KEYWORD, FETCH_KEYWORDS, FETCH_KEYWORD, UPDATE_KEYWORD, DELETE_KEYWORD, GET_SYNCHRONIZED, START_SYNCHRONIZE, SET_KEYWORD_SYNCHRONIZED } from "../actions/types";

const initialState = {
  keywords: [],
  keyword: null,
};

const keywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_KEYWORD:
      return {
        ...state,
        keywords: [...state.keywords, action.payload],
      };

    case GET_SYNCHRONIZED:
      return {
        ...state,
        synchronize: action.payload,
      };
    case START_SYNCHRONIZE:
      return {
        ...state,
        synchronize: action.payload,
      };
    case SET_KEYWORD_SYNCHRONIZED:
      // update synchronized of keyword.id
      const updateKeywords = state.keywords.map((keyword) =>
        keyword.id === action.payload.id
          ? {
              ...keyword,
              synchronized: action.payload.synchronized,
            }
          : keyword
      );
      return {
        ...state,
        keywords: updateKeywords,
      };

    case FETCH_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
      };

    case FETCH_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };

    case UPDATE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.map((keyword) => (keyword.id === action.payload.id ? action.payload : keyword)),
        keyword: action.payload,
      };

    case DELETE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.filter((t) => !action.payload.includes(t.id)),
        keyword: state.keyword && action.payload.includes(state.keyword.id) ? null : state.keyword,
      };

    default:
      return state;
  }
};

export default keywordReducer;
