import { backendClient } from "../../api/backend";
import {
  FETCH_WORKSTREAMS_REQUEST,
  FETCH_WORKSTREAMS_SUCCESS,
  FETCH_WORKSTREAMS_FAILURE,
  CREATE_WORKSTREAM_REQUEST,
  CREATE_WORKSTREAM_SUCCESS,
  CREATE_WORKSTREAM_FAILURE,
  UPDATE_WORKSTREAM_REQUEST,
  UPDATE_WORKSTREAM_SUCCESS,
  UPDATE_WORKSTREAM_FAILURE,
  DELETE_WORKSTREAM_REQUEST,
  DELETE_WORKSTREAM_SUCCESS,
  DELETE_WORKSTREAM_FAILURE,
  FETCH_WORKSTREAM_BY_ID_REQUEST,
  FETCH_WORKSTREAM_BY_ID_SUCCESS,
  FETCH_WORKSTREAM_BY_ID_FAILURE,
} from "./types";

// Fetch Workstreams
export const fetchWorkstreams = (transactionId, skip, limit) => async (dispatch) => {
  console.log("Dispatching fetchWorkstreams action");
  dispatch({ type: FETCH_WORKSTREAMS_REQUEST });
  try {
    const response = await backendClient.fetchWorkstream(transactionId, skip, limit);
    console.log("API response:", response);
    dispatch({ type: FETCH_WORKSTREAMS_SUCCESS, payload: response });
  } catch (error) {
    console.log("Error fetching workstreams:", error.message);
    dispatch({ type: FETCH_WORKSTREAMS_FAILURE, payload: error.message });
  }
};
// Action to fetch a workstream by its ID
export const fetchWorkstreamById = (workstreamId) => async (dispatch) => {
  dispatch({ type: FETCH_WORKSTREAM_BY_ID_REQUEST });
  try {
    const workstream = await backendClient.fetchWorkstreamById(workstreamId);
    dispatch({ type: FETCH_WORKSTREAM_BY_ID_SUCCESS, payload: workstream });
  } catch (error) {
    dispatch({ type: FETCH_WORKSTREAM_BY_ID_FAILURE, payload: error.message });
  }
};
// Create Workstream
export const createWorkstream = (workstreamData) => async (dispatch) => {
  dispatch({ type: CREATE_WORKSTREAM_REQUEST });
  try {
    const newWorkstream = await backendClient.createWorkstream(workstreamData);
    dispatch({ type: CREATE_WORKSTREAM_SUCCESS, payload: newWorkstream });
  } catch (error) {
    dispatch({ type: CREATE_WORKSTREAM_FAILURE, payload: error.message });
  }
};

// Update Workstream
export const updateWorkstream = (workstreamId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_WORKSTREAM_REQUEST });
  try {
    const updatedWorkstream = await backendClient.updateWorkstream(workstreamId, updatedData);
    dispatch({ type: UPDATE_WORKSTREAM_SUCCESS, payload: updatedWorkstream });
  } catch (error) {
    dispatch({ type: UPDATE_WORKSTREAM_FAILURE, payload: error.message });
  }
};

// Delete Workstream
export const deleteWorkstream = (workstreamId) => async (dispatch) => {
  dispatch({ type: DELETE_WORKSTREAM_REQUEST });
  try {
    await backendClient.deleteWorkstream(workstreamId);
    dispatch({ type: DELETE_WORKSTREAM_SUCCESS, payload: workstreamId });
  } catch (error) {
    dispatch({ type: DELETE_WORKSTREAM_FAILURE, payload: error.message });
  }
};
