export const countries = ["Germany", "France", "Netherlands", "Belgium", "Austria", "Switzerland", "Luxembourg", "United Kingdom", "Sweden", "Denmark", "Finland", "Norway", "Ireland", "Italy", "Spain", "Greece", "Portugal"];

// export const industries = ["Wind Onshore", "Wind Offshore", "Solar", "Health Care", "Data Center", "Oil", "Fibre"];
export const industries = ["Wind Onshore", "Wind Offshore", "Automobile", "Fibre", "Data Centres", "SaaS"];

export const relationships = ["is", "includes"];

export const keyword_types = ["person", "people", "organization"];

export const documentNames = [
  "Information Memorandum",
  "Legal Due Dilligence Report",
  "Tax Due Dilligence Report",
  "Technical Due Dilligence Report",
  "Energy Yield Assessment Report",
  "Hedging Report",
  "Board Presentation",
  "Board Minutes",
  "Accounting Due Dilligence Report",
  "Company related documents",
  "Share Purchase Agreement",
  "Shareholder Agreement",
  "Agreement (General)",
  "Facilities Agreement",
  "PPP Agreement",
  "Letter of Credit",
  "Other Debt",
  "Lease Agreement",
  "Turbine Supply Agreement",
  "EPC Agreement / Construction Management Agreement",
  "OFTO Agreement",
  "Grid Supply agreement",
  "Substation agreement",
  "Insurance Contract",
  "Insurance Policies",
  "Supply and Maintenance Agreement",
  "Warranty agreement",
  "PPA Agreement",
  "Contract for Difference Agreement",
  "Maintenance Supply Agreement",
  "Operations and Maintenance Agreement",
  "Management Services Agreement / Asset management Agreement",
  "Interest Rate Swap Agreement",
  "CPI Swap Agreement",
  "Novation Agreement",
  "Trading Services Agreement",
  "Balance Of Plant",
  "Audited Accounts",
  "Tax computation",
  "Financial Model",
  "Management Accounts",
  "Budget",
];

export const document_types_categories = {
  Report: [
    { name: "Information Memorandum", type: "PDF" },
    { name: "Legal Due Dilligence Report", type: "PDF" },
    { name: "Tax Due Dilligence Report", type: "PDF" },
    { name: "Technical Due Dilligence Report", type: "PDF" },
    { name: "Energy Yield Assessment Report", type: "PDF" },
    { name: "Hedging Report", type: "PDF" },
    { name: "Board Presentation", type: "PDF" },
    { name: "Board Minutes", type: "PDF / Word" },
    { name: "Accounting Due Dilligence Report", type: "PDF" },
    { name: "Company related documents", type: "PDF" },
  ],
  Contract: [
    { name: "Share Purchase Agreement", type: "Word / PDF" },
    { name: "Shareholder Agreement", type: "PDF" },
    { name: "Agreement (General)", type: "PDF" },
    { name: "Facilities Agreement", type: "PDF" },
    { name: "PPP Agreement", type: "PDF" },
    { name: "Letter of Credit", type: "PDF" },
    { name: "Other Debt", type: "PDF" },
    { name: "Lease Agreement", type: "PDF" },
    { name: "Turbine Supply Agreement", type: "PDF" },
    { name: "EPC Agreement / Construction Management Agreement", type: "PDF" },
    { name: "OFTO Agreement", type: "PDF" },
    { name: "Grid Supply agreement", type: "PDF" },
    { name: "Substation agreement", type: "PDF" },
    { name: "Insurance Contract", type: "PDF" },
    { name: "Insurance Policies", type: "PDF" },
    { name: "Supply and Maintenance Agreement", type: "PDF" },
    { name: "Warranty agreement", type: "PDF" },
    { name: "Power Purchase Agreement", type: "PDF" },
    { name: "Contract for Difference Agreement", type: "PDF" },
    { name: "Maintenance Supply Agreement", type: "PDF" },
    { name: "Operations and Maintenance Agreement", type: "PDF" },
    { name: "Management Services Agreement / Asset management Agreement", type: "PDF" },
    { name: "Interest Rate Swap Agreement", type: "PDF" },
    { name: "CPI Swap Agreement", type: "PDF" },
    { name: "Novation Agreement", type: "PDF" },
    { name: "Trading Services Agreement", type: "PDF" },
    { name: "Balance Of Plant", type: "PDF" },
  ],
  "Financial Statement": [
    { name: "Audited Accounts", type: "PDF " },
    { name: "Tax computation", type: "PDF" },
  ],
  "Management Account": [
    { name: "Financial Model", type: "Excel" },
    { name: "Management Accounts", type: "PDF / Excel" },
    { name: "Budget", type: "PDF / Excel" },
  ],
  Other: [{ name: "Other", tye: "PDF" }],
};

export const connectors = [
  {
    title: "Select from existing",
    description: "Explore the vast array of existing datasets, benchmark their relevance and utility, and leverage these resources to craft a novel piece of analysis.",
    image: require("../../assets/data/existing.png"),
  },
  {
    title: "Select from Data Room",
    description: "Organize, manage, and collaborate on your important documents effortlessly. With Dataroom, secure your sensitive data…",
    image: require("../../assets/data/dataroom.png"),
  },
  {
    title: "Upload from OneDrive",
    description: "OneDrive, Microsoft’s file sync app formerly known as SkyDrive, lets you store your files online.",
    image: require("../../assets/data/onedrive.png"),
  },
  // {
  //   title: "AWS",
  //   description: "AWS Lambda is a compute service that runs your code in response to events and automatically manages the compute…",
  //   image: require("../../assets/data/aws.png"),
  // },
  // {
  //   title: "DropBox",
  //   description: "Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free…",
  //   image: require("../../assets/data/dropbox.png"),
  // },
  // {
  //   title: "Google Drive",
  //   description: "Google Drive is Google’s file sync app that lets you store all of your files online alongside your Google Docs documents…",
  //   image: require("../../assets/data/googledrive.png"),
  // },
];

// const messages = [
//   {
//     id: 1,
//     user: "DueDil",
//     text: "The document provided is a contract outlining the terms of the operations and maintenance agreement of the project. It contains the start date, price as well as the detailed scope of the workings to be undertaken under the agreement. Feel free to ask any question with regards to this document.",
//     type: "received",
//   },
//   {
//     id: 2,
//     user: "Robert James",
//     text: "What is the pricing under the contract as well as the works to be undertaken. Is the contract subject to CPI inflation or RPI? When is the first indexation period under the contract.",
//     type: "sent",
//   },
//   {
//     id: 3,
//     user: "DueDil",
//     text: "The start date of the contract is August 2010 with a start price of 30k€/WTG ending at 47.5k€/WTG at the end of the contract (2037). These prices are subject to CPI and are inflated in August.",
//     type: "received",
//   },
//   {
//     id: 4,
//     user: "Robert James",
//     text: "What is the pricing under the contract as well as the works to be undertaken. Is the contract subject to CPI inflation or RPI? When is the first indexation period under the contract.",
//     type: "sent",
//   },
//   {
//     id: 5,
//     user: "DueDil",
//     text: "The start date of the contract is August 2010 with a start price of 30k€/WTG ending at 47.5k€/WTG at the end of the contract (2037). These prices are subject to CPI and are inflated in August.",
//     type: "received",
//   },
//   {
//     id: 6,
//     user: "Robert James",
//     text: "What is the pricing under the contract as well as the works to be undertaken. Is the contract subject to CPI inflation or RPI? When is the first indexation period under the contract.",
//     type: "sent",
//   },
//   {
//     id: 7,
//     user: "DueDil",
//     text: "The start date of the contract is August 2010 with a start price of 30k€/WTG ending at 47.5k€/WTG at the end of the contract (2037). These prices are subject to CPI and are inflated in August.",
//     type: "received",
//   },
// ];

const transactions = [
  {
    title: "Healthcare PPP",
    id: "SDLK2990201SD",
    industry: "Health Care",
    country: "UK",
    description: "Healthcare Project in the UK",
    date: "12/12/2023",
  },
  {
    title: "SFR DataCenters",
    id: "KsdLoSLKD20989S",
    industry: "Data Center",
    country: "France",
    description: "Data Centers in France",
    date: "12/12/2023",
  },
  {
    title: "German Fiber",
    id: "SJFIEARJSU98EJS",
    industry: "Fiber",
    country: "Germany",
    description: "German Fiber",
    date: "12/12/2023",
  },
  {
    title: "SouthMead Hospital",
    id: "APOISDJNZ72823S",
    industry: "Health Care",
    country: "UK",
    description: "Hospital in UK",
    date: "12/12/2023",
  },
  {
    title: "UK Data centers",
    id: "298SDJNU8SJXZD",
    industry: "Data Center",
    country: "UK",
    description: "Data centers in UK",
    date: "12/12/2023",
  },
  {
    title: "WindFarm UK",
    id: "SDKNJSLKKJ20KLSs",
    industry: "Wind Farm",
    country: "UK",
    description: "Wind farm in UK",
    date: "12/12/2023",
  },
  // ...add more transactions as needed
];
