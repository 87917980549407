import React, { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // For initial loading
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

// Define the AnimatedCheckmark here as shown above

const InvitationScreen = () => {
  const navigate = useNavigate();
  const [invitationCode, setInvitationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const handleInputChange = (event) => {
    setInvitationCode(event.target.value);
  };

  const checkInvitationCode = async () => {
    setLoading(true);
    // Simulate the API call
    if (invitationCode === "DueDil2024") {
        navigate('/sign-in')
    }
    setLoading(false);
    setVerified(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center", // This centers vertically in the flex container
        gap: "20px",
        height: "100vh", // Use 100vh to take the full height of the viewport
        margin: "0 auto", // This centers the container itself if needed, though with flex the content is already centered
        paddingBottom: 200,
      }}
    >
      {loading && <CircularProgress />}
      {!loading && verified && <CheckCircleOutlineIcon style={{ fontSize: 40 }} />}
      <TextField label="Invitation Code" variant="outlined" value={invitationCode} onChange={handleInputChange} disabled={loading} />
      <Button variant="contained" onClick={checkInvitationCode} disabled={loading || invitationCode.trim() === ""}>
        Check Code
      </Button>
    </div>
  );
};

export default InvitationScreen;
