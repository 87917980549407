import ChatIcon from "@mui/icons-material/Chat"; // Import at the top of your file
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, CardContent, Checkbox, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateWithTimezoneOffset, isMobile, windowWidth } from "../../utils/utils";

import { Search, SendHorizontal, X } from "lucide-react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { backendClient } from "../../api/backend";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { deleteConversations, fetchConversations } from "../../redux/actions/conversationAction";
import { clearDocuments } from "../../redux/actions/documentsAction";
import { colors } from "../../utils/colors";
import SearchBar from "../../components/SearchBar";
import Spinner from "../../components/Spinner";
import Documentstart from "../../components/Documentstart";

const ChatHistory = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const conversations = useSelector((state) => state.conversation.conversations || []);
  const user_id = useSelector((state) => state.auth.user?.id);
  const user_name = useSelector((state) => state.auth.user?.name);

  const fetch_fn = async (isDelete = false) => {
    setIsLoadingConversations(true);
    await dispatch(fetchConversations(user_id, page + 1, rowsPerPage));
    if (totalConversations === "" || isDelete) {
      // console.log("user_id", user_id);
      const totalConversations_ = await backendClient.countConversations();
      // const totalConversations_ = await backendClient.countConversations();
      console.log("totalConversations_", totalConversations_);
      setTotalConversations(totalConversations_["count"]);
    }
    setIsLoadingConversations(false);
  };

  useEffect(() => {
    fetch_fn(false);
  }, [dispatch, page, rowsPerPage]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = conversations?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };
  const handleOpenConversation = async (chatId) => {
    // navigate("/chat/new");
    await dispatch(clearDocuments());
    navigate(`/chat/${chatId}`);
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  const [isLoadingConversations, setIsLoadingConversations] = useState(false);
  const [totalConversations, setTotalConversations] = useState("");

  // Filter conversations based on the search query

  console.log("conversations ", conversations);
  const filteredChats = conversations?.filter((chat) => chat.title?.toLowerCase().includes(searchQuery) || chat.id.toLowerCase().includes(searchQuery));

  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDeleteConveration = async () => {
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    console.log("selected", selected);
    await dispatch(deleteConversations(selected, user_id));
    await fetch_fn(true);
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Conversation deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log("Submitted:", inputValue);
    setInputValue("");
  };

  const documents = useSelector((state) => state.documents.documents);
  const transactions = useSelector((state) => state.transactions.transactions);
  // console.log('PPPPPP-edocuments', documents)
  // console.log('PPPPPP-transactions', transactions)

  return (
    <Documentstart
      title={`Explore previous chats -`}
      open={open}
      headerProps={{
        showSearchBar: true,
        showFilter: false,
        showView: false,
        searchQuery: searchQuery,
        setSearchQuery: setSearchQuery,
        // backbutton: true,
        // handleBackClick: () => {},
        // custombutton: true,
        // custombuttontext: "Add Template Question",
        // custombuttononclick: () => {},
        // sx: { margin: "0" },
        // startIcon: <Plus size="22px" />,
        // hoverBackgroundColor: "",
        // variant: "contained",
      }}
    >
      <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
        <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Conversations"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />

        {/* Search bar and Explore Transaction button */}
        <div className="flex justify-between items-center mb-4">
          {!isMobile() ? (
            <>
              {selected?.length > 0 && (
                <Button
                  style={{ marginLeft: windowWidth / 80, backgroundColor: selected.length === 0 ? "" : "red", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                  variant={selected.length === 0 ? "outlined" : "contained"}
                  disabled={selected.length === 0}
                  onClick={handleDeleteConveration}
                >
                  {selected.length <= 1 ? "Delete conversation" : "Delete conversations"}
                </Button>
              )}
            </>
          ) : (
            <>
              {selected?.length > 0 && (
                <Button
                  sx={{
                    marginLeft: windowWidth / 240,
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                    // mr: 1,
                    backgroundColor: selected.length === 0 ? "" : "red",
                  }}
                  variant={selected.length === 0 ? "outlined" : "contained"}
                  disabled={selected.length === 0}
                  onClick={handleDeleteConveration}
                >
                  {selected.length <= 1 ? "Delete conversation" : "Delete conversations"}
                </Button>
              )}
            </>
          )}
        </div>

        {isLoadingConversations ? (
          <Spinner />
        ) : (
          <>
            <Typography variant="h2" component="h2" gutterBottom align="center" fontWeight={"bold"}>
              Hello {user_name.split(" ")[0]}
            </Typography>
            <Typography variant="h4" component="h4" gutterBottom align="center" fontWeight={"bold"}>
              You will find here your previous chats
            </Typography>

            <Grid
              container
              spacing={3} // Increased spacing between cards
              sx={{
                marginBottom: 2,
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 390px)", // Adjust this value based on your layout
              }}
            >
              {filteredChats.map((chat, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4} // Adjusted for better responsiveness
                  lg={2.4} // Adjusted for better responsiveness
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Center the card within the grid item
                  }}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: 250, // Increased width for a larger card
                      height: 180, // Increased height for a larger card
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: 3,
                      },
                      bgcolor: "#f0f0f0",
                      margin: 1, // Added margin for extra separation
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        padding: 2, // Adjusted padding for a better layout
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          color: "black",
                          textAlign: "center",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        {chat.title}
                      </Typography>
                    </CardContent>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenConversation(chat.id)}
                      sx={{
                        alignSelf: "flex-end",
                        padding: 0.5,
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SendHorizontal />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {/* <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Or Type your question here..."
            value={inputValue}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <Button type="submit" sx={{ minWidth: "auto" }}>
                  →
                </Button>
              ),
            }}
          />
        </form> */}
      </Grid>
    </Documentstart>
  );
};

export default ChatHistory;
