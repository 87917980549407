// actions/templateQuestionActions.js
import { backendClient } from "../../api/backend";
import {
  FETCH_TQUESTIONS_REQUEST,
  FETCH_TQUESTIONS_SUCCESS,
  FETCH_TQUESTIONS_FAILURE,
  CREATE_TQUESTION_REQUEST,
  CREATE_TQUESTION_SUCCESS,
  CREATE_TQUESTION_FAILURE,
  UPDATE_TQUESTION_REQUEST,
  UPDATE_TQUESTION_SUCCESS,
  UPDATE_TQUESTION_FAILURE,
  DELETE_TQUESTION_REQUEST,
  DELETE_TQUESTION_SUCCESS,
  DELETE_TQUESTION_FAILURE,
} from "../actions/types";

const initialState = {
  questions: [],
  isLoading: false,
  error: null,
};

const templateQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TQUESTIONS_REQUEST:
    case CREATE_TQUESTION_REQUEST:
    case UPDATE_TQUESTION_REQUEST:
    case DELETE_TQUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FETCH_TQUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: action.payload,
      };

    case CREATE_TQUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: [...state.questions, action.payload],
      };

    case UPDATE_TQUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: state.questions.map((question) => (question.id === action.payload.id ? action.payload : question)),
      };

    case DELETE_TQUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.filter((q) => !action.payload.includes(q.id)), // Remove multiple questions
        loading: false,
      };

    case FETCH_TQUESTIONS_FAILURE:
    case CREATE_TQUESTION_FAILURE:
    case UPDATE_TQUESTION_FAILURE:
    case DELETE_TQUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default templateQuestionReducer;
