import { CardContent, Paper } from "@material-ui/core";
import { Box, Button, Card, Chip, Divider, Grid, List, Typography } from "@mui/material";
import { ChartBarIcon, Delete, Divide, MessageCircle, Repeat } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const CardsHypothesis = (card) => {
  const detailColors = ["#1AB5B8", "#FF6347", "#FFD700", "#4682B4", "#F0E68C"];
  const cardBackgroundColor = detailColors[Math.floor(Math.random() * detailColors.length)];

  return (
    <Box spacing={2}>
      <Typography variant="h5" component="div" sx={{ color: cardBackgroundColor, fontWeight: "bold" }} gutterBottom>
        {card.labels[0]}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          marginBottom: 2,
        }}
      >
        {card.organization_id}
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" component="div">
            {"Risk"}
          </Typography>
          <Chip label={"75% Confidence rate"} />
          <Card sx={{ height: "250px" }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {card.labels[0]}
              </Typography>
              <Typography variant="body2">Risk: {card.risk.join(", ")}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" component="div">
            {"Values"}
          </Typography>
          <Chip label={"15% Confidence rate"} />
          <Card sx={{ height: "250px", bgcolor: cardBackgroundColor }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {card.labels[0]}
              </Typography>
              <Typography variant="body2">Values: {card.values.join(", ")}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" component="div">
            {"Actions"}
          </Typography>
          <Chip label={"15% Confidence rate"} />
          <Card sx={{ height: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CardContent>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                <Button color="primary" startIcon={<MessageCircle />} sx={{ marginBottom: 2, minWidth: 300 }}>
                  Start Conversation
                </Button>
                <Button color="secondary" startIcon={<Repeat />} sx={{ marginBottom: 2, minWidth: 300 }}>
                  Reprompt
                </Button>
                <Button color="error" startIcon={<Delete />} sx={{ marginBottom: 2, minWidth: 300 }}>
                  Delete
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const hypothesisCards = [
  {
    id: "2f51935a-380c-45fa-b064-ccc162d43474",
    created_at: "2024-08-14T21:15:53.989119",
    updated_at: "2024-08-14T21:15:53.989119",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["£8.7m"],
    values: ["£8.7m"],
    labels: ["Shareholder Loan"],
    risk: ["N/A"],
    conversation_id: "891e7142-2687-4e2b-bc41-167c5456f40f",
    organization_id: "k-fin",
  },
  {
    id: "af8f066d-4c84-4f1b-a5bb-3b9949a843af",
    created_at: "2024-08-14T21:16:00.036141",
    updated_at: "2024-08-14T21:16:00.036141",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["195bps-214.5bps"],
    values: ["195bps-214.5bps"],
    labels: ["Swap Rate"],
    risk: ["N/A"],
    conversation_id: "1a1f3207-044b-4a9f-ade7-a3084ccd41c2",
    organization_id: "k-fin",
  },
  {
    id: "de2785e4-74c3-48e6-bd36-f5e0660271db",
    created_at: "2024-08-14T21:16:00.051726",
    updated_at: "2024-08-14T21:16:00.051726",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["12%"],
    values: ["12%"],
    labels: ["Indexation"],
    risk: ["N/A"],
    conversation_id: "6d616cbc-7d58-410a-b0a2-ec4b75a95517",
    organization_id: "k-fin",
  },
  {
    id: "52cc91d7-a7a8-4d20-a41d-cb5ef3100409",
    created_at: "2024-08-14T21:16:00.082972",
    updated_at: "2024-08-14T21:16:00.082972",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["£3 per day"],
    values: ["£3 per day"],
    labels: ["Availability"],
    risk: ["N/A"],
    conversation_id: "797a4cd9-0515-4a84-988c-dcc3a6b76abc",
    organization_id: "k-fin",
  },
  {
    id: "bfb93c7d-ebc5-4779-8cd2-986cb6781c77",
    created_at: "2024-08-14T21:16:00.098597",
    updated_at: "2024-08-14T21:16:00.098597",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["24MW"],
    values: ["24MW"],
    labels: ["Capacity"],
    risk: ["N/A"],
    conversation_id: "806a630c-f401-49f6-b65d-e870c2bbc760",
    organization_id: "k-fin",
  },
  {
    id: "74e607fc-7ccf-4676-a3c8-5a679a9efe34",
    created_at: "2024-08-14T21:16:00.114219",
    updated_at: "2024-08-14T21:16:00.114219",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["42.3% Production Bonus"],
    values: ["42.3% Production Bonus"],
    labels: ["Turbines"],
    risk: ["N/A"],
    conversation_id: "80476c72-60ea-4294-9fbb-79d1de725eeb",
    organization_id: "k-fin",
  },
  {
    id: "4e508338-cf28-4970-ada2-f967a4e85921",
    created_at: "2024-08-14T21:16:00.020481",
    updated_at: "2024-08-14T21:16:00.020481",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["£31.9m"],
    values: ["£31.9m"],
    labels: ["Senior Loan"],
    risk: ["N/A"],
    conversation_id: "f7a803ab-5865-4bdc-8069-1b14f3d1d7a8",
    organization_id: "k-fin",
  },
  {
    id: "5479835f-7ffd-4b81-bd50-be4676f7f376",
    created_at: "2024-08-14T21:16:00.051726",
    updated_at: "2024-08-14T21:16:00.051726",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["32p-43p per kWh"],
    values: ["32p-43p per kWh"],
    labels: ["Cost"],
    risk: ["N/A"],
    conversation_id: "84f3579e-4107-437c-889e-3e1338c29cea",
    organization_id: "k-fin",
  },
  {
    id: "5e41328d-d500-48bb-b46d-a0c0a50aa14b",
    created_at: "2024-08-14T21:16:00.067368",
    updated_at: "2024-08-14T21:16:00.067368",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["0 - 32 per MWh"],
    values: ["0 - 32 per MWh"],
    labels: ["Production"],
    risk: ["N/A"],
    conversation_id: "79433502-464a-490f-9dd6-a8054b5c41ec",
    organization_id: "k-fin",
  },
  {
    id: "e9abd6dd-f667-477d-a7c7-f182dd85e09c",
    created_at: "2024-08-14T21:16:00.082972",
    updated_at: "2024-08-14T21:16:00.082972",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["£75/MWh"],
    values: ["£75/MWh"],
    labels: ["CPI Inflation-Linked Threshold"],
    risk: ["N/A"],
    conversation_id: "3fd102e8-379e-4d49-af2b-aa37ee26f64e",
    organization_id: "k-fin",
  },
  {
    id: "34578473-c070-41ee-b724-26a2945da597",
    created_at: "2024-08-14T21:16:00.098597",
    updated_at: "2024-08-14T21:16:00.098597",
    name: "generic",
    source: ["fdcc52bc-4618-448e-ab01-229710dd3bca"],
    ref_value: ["90% availability"],
    values: ["90% availability"],
    labels: ["Turbines"],
    risk: ["N/A"],
    conversation_id: "80476c72-60ea-4294-9fbb-79d1de725eeb",
    organization_id: "k-fin",
  },
];

const KpiHypothesis = () => {
  const open = useSelector((state) => state.navBar.is_open);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Paper style={{ maxHeight: "95vh", overflow: "auto", maxWidth: "100vh" }}>
        <Grid item xs={12} sx={{ transition: "margin 0.5s", marginX: "15px" }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: "bold" }} gutterBottom>
            KPI
          </Typography>
          <List>
            <Divider sx={{ marginBottom: 4 }} />
            {hypothesisCards.map((card) => (
              <CardsHypothesis key={card.id} {...card} />
            ))}
          </List>
        </Grid>
      </Paper>
    </Box>
  );
};
export default KpiHypothesis;
