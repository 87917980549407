import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import GeneralForm from "./GeneralForm";
import { Box, Container, Paper, Typography } from "@mui/material";
import { windowHeight, windowWidth } from "../../../utils/utils";
import { colors } from "../../../utils/colors";

const GeneralParameters = ({}) => {
  // REDUX
  const dispatch = useDispatch();
  //   const fetchUserInfo_rd = () => dispatch(fetchUserInfo());
  //   const setUserInfoLocal_rd = (item) => dispatch(setUserInfoLocal(item));
  //   const updateUserInfo_rd = (item) => dispatch(updateUserInfo(item));
  //   const fetchOrganizationInfo_rd = (item) => dispatch(fetchOrganizationInfo(item));
  // const user_info = useSelector((state) => (state.user.user_info ? state.user.user_info : null));
  // const organization_info = useSelector((state) => (state.organization.organization_info ? state.organization.organization_info : null));

  // useEffect(() => {
  //   async function fetch_fn() {
  //     //   fetchUserInfo_rd();
  //     //   fetchOrganizationInfo_rd();
  //   }
  //   // if (!user_info) {
  //   fetch_fn();
  //   // }
  // }, []);

  const onSubmit = (item) => {
    console.log('item', item)
    // setUserInfoLocal_rd(item);
    // updateUserInfo_rd(item);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: windowHeight / 12 }}>
        <Paper elevation={0} sx={{  width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h2 className="text-xl font-bold text-gray-800">Information</h2>
        </Paper>
      </Box>
      <Container sx={{ justifyContent: "center", alignItems: "center", mt: 2 }}>
        <GeneralForm onSubmit={onSubmit} />
      </Container>
    </>
  );
};

export default GeneralParameters;
