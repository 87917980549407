import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Card, CardContent, CardActions, Typography, Button, LinearProgress, Box, Stack, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { AttachFile, Close, InsertDriveFile } from "@material-ui/icons";
import { FileUp } from "lucide-react";
import { useSnackbar } from "notistack";
import { backendClient } from "../../../api/backend";
import { CloudUpload } from "lucide-react";

const CardUpload = ({ open, onClose }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => Object.assign(file, { progress: 0 }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    // Simulate upload progress
    newFiles.forEach((file) => {
      const interval = setInterval(() => {
        setFiles((prevFiles) => prevFiles.map((f) => (f === file ? { ...f, progress: Math.min(f.progress + 10, 100) } : f)));
        if (file.progress >= 100) clearInterval(interval);
      }, 500);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
  });

  const removeFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handleCancel = () => {
    onClose();
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      // uppload files
      const metadata = files.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      const transactionCreated = await backendClient.uploadDocument(files, metadata);
      enqueueSnackbar("Files uploaded successfully", { variant: "success" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Error uploading files", { variant: "error" });
      console.error(error);
    }
    setLoading(false);
  };

  const formatFileSize = (bytes) => {
    if (typeof bytes !== "number" || isNaN(bytes) || bytes === 0) return "Unknown size";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Upload additional documents
      </DialogTitle>

      <DialogContent
        sx={{
          height: "100%",
        }}
      >
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          Select or drag and drop additional PDF files to upload. You can upload multiple files at once.
        </Typography>
        <Card
          sx={{
            margin: "auto",
            borderRadius: "12px",
            padding: 2,
            boxShadow: "none",
            "&.MuiPaper-elevation1": {
              boxShadow: "none",
            },
          }}
        >
          <CardContent>
            <Box
              {...getRootProps()}
              sx={{
                p: 2,
                border: "2px dashed",
                borderColor: isDragActive ? "primary.main" : "grey.300",
                borderRadius: 1,
                textAlign: "center",
                cursor: "pointer",
                bgcolor: isDragActive ? "primary.light" : "background.paper",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <input {...getInputProps()} />
              <FileUp size={44} />
              <Typography>{isDragActive ? "Drop the PDF files here..." : "Drag and drop PDF files here, or click to select files"}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              {files.map((file, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1, p: 1, bgcolor: "grey.100", borderRadius: 3 }}>
                  <InsertDriveFile sx={{ mr: 1, color: "primary.main" }} />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">{file.name ? file.name : "Name not available"}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatFileSize(file.size)} - PDF
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                      <LinearProgress
                        variant="determinate"
                        value={file.progress}
                        sx={{
                          width: "400px",
                          height: 13,
                          borderRadius: 6,
                          backgroundColor: "#B0B7C0",
                          "& .MuiLinearProgress-bar": {
                            borderRadius: 6,
                            backgroundColor: "black",
                          },
                        }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        {file.progress}%
                      </Typography>
                    </Stack>
                  </Box>
                  <Button size="small" onClick={() => removeFile(file)} sx={{ minWidth: "auto", p: 0.5 }}>
                    <Close fontSize="small" color="error" />
                  </Button>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "flex-end", // Align to the right
          alignItems: "center",
          flexDirection: "row", // Ensure buttons are in a row
        }}
      >
        <Stack spacing={2} direction="row" mb={1} alignItems="center">
          {" "}
          {/* Remove mx={5} for better alignment */}
          <Button
            variant="outlined"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: 2,
              color: "black",
              borderColor: "black",
              "&:hover": {
                bgcolor: "white",
                color: "black",
                borderColor: "black",
              },
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: 2,
              bgcolor: "black",
              color: "white",
              "&:hover": {
                bgcolor: "black",
              },
            }}
            startIcon={<CloudUpload />}
            onClick={handleUpload}
          >
            {loading ? "Uploading..." : "Upload"}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CardUpload;
