import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Card, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { ResponsivePie } from "@nivo/pie";
import { backendClient } from "../../../api/backend";
import { useSelector } from "react-redux";
import { colors } from "../../../utils/colors";
import { convertCountryFormat, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const Analysis = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));

  const cardStyle = {
    borderRadius: 2,
    height: "260px", // Ensure card takes full height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };

  const titleStyle = {
    fontSize: "16px",
    color: colors.GRAY_ONE,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const valueStyle = {
    fontSize: "28px",
    color: colors.BLACK,
    fontWeight: "semibold",
  };

  const percentageStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: colors.GREEN,
    "& svg": {
      fontSize: "1rem",
      marginRight: "4px",
    },
  };

  const barChartStyle = {
    height: "20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginTop: "2px", // Aligns the bar chart at the bottom
  };

  const barStyle = (value, index, data) => ({
    width: "6px",
    height: `${value}%`,
    backgroundColor: index === data.length - 1 ? colors.GREEN : colors.GRAY_TWO,
    borderRadius: "3px",
    marginRight: "10px",
  });

  // Dummy data for the bar chart
  const dealsData = [30, 60, 40, 90, 80, 100];
  const documentsData = [15, 40, 70, 91, 88, 100];
  const conversationsData = [20, 25, 60, 75, 80, 110];

  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [isLoadingConversations, setIsLoadingConversations] = useState(true);
  const [isLoadingCountryData, setIsLoadingCountryData] = useState(true);

  const [totalDeals, setTotalDeals] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalConversations, setTotalConversations] = useState(0);
  const [countryData, setCountryData] = useState([]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetch_fn = async () => {
      const totalDeals_ = await backendClient.countTransactions();
      setTotalDeals(totalDeals_["count"]);
      setIsLoadingDeals(false);

      const totalDocuments_ = await backendClient.countDocuments();
      setTotalDocuments(totalDocuments_["count"]);
      setIsLoadingDocuments(false);

      const totalConversations_ = await backendClient.countConversations();
      setTotalConversations(totalConversations_["count"]);
      setIsLoadingConversations(false);

      const countryCount_ = await backendClient.valueCountTransaction("country");
      console.log(convertCountryFormat(countryCount_));
      setCountryData(convertCountryFormat(countryCount_));
      setIsLoadingCountryData(false);
    };

    fetch_fn();
  }, [user]);

  const SummaryCard = ({ title, value, trend, data, isLoading }) => {
    const cardBackgroundColor = title === "Total Documents" ? "#1AB5B8" : colors.WHITE;

    const cardStyleWithBackground = {
      ...cardStyle,
      backgroundColor: cardBackgroundColor,
    };

    return (
      <Card sx={cardStyleWithBackground}>
        <CardContent>
          <Typography sx={{ ...titleStyle, color: title === "Total Documents" ? colors.WHITE : colors.GRAY_ONE }}>{title}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Typography sx={{ ...valueStyle, color: title === "Total Documents" ? colors.WHITE : colors.BLACK }}>{value}</Typography>
                  <Box sx={{ ...percentageStyle, color: title === "Total Documents" ? colors.WHITE : colors.GREEN }}>
                    <TrendingUpIcon />
                    {trend}
                  </Box>
                </>
              )}
            </Box>
            <Box sx={barChartStyle}>
              {data.map((value, index) => (
                <Box key={index} sx={barStyle(value, index, data)} />
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "16px",
          fontWeight: "bold",
          color: "#1AB5B8",
        }}
      >
        Analysis
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SummaryCard title={"Total Deals"} isLoading={isLoadingDeals} value={totalDeals} trend={"-%"} data={dealsData} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title={"Total Documents"} isLoading={isLoadingDocuments} value={totalDocuments} trend={"-%"} data={documentsData} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title={"Total Conversations"} isLoading={isLoadingConversations} value={totalConversations} trend={"-%"} data={conversationsData} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={cardStyle}>
            <CardContent>
              <div
                style={{
                  height: windowHeight/4,
                  width: "100%",
                  display: "flex", // Enable flexbox
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                {isLoadingCountryData ? (
                  <CircularProgress size={24} />
                ) : (
                  <ResponsivePie
                    data={countryData && countryData}
                    margin={{ top: 20, bottom: 20, right: 0, left: 0 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analysis;
