import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, CardMedia, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Paper, Stack, SvgIcon, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { ChevronLeft, CirclePlus, CloudDownload, Edit, Eye, FileLock2, KeyIcon, Plus, Table } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backendClient } from "../../api/backend";
import excelImage from "../../assets/excel.png";
import CustomButton from "../../components/CustomButton";
import CustomProgressBar from "./dealscomponents/CustomProgressBar";
import DueDiligenceCard from "./dealscomponents/duediligencecard";
import WorkstreamModal from "./dealscomponents/workstreammodal";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "notistack";
import { fetchWorkstreams } from "../../redux/actions/workstreamAction";
import Documentstart from "../../components/Documentstart";
import { fetchTransactions, setTransaction } from "../../redux/actions/transactionAction";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { useNavigate } from "react-router-dom";

const ExcelIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M321.49,244.09l-202.42-35.72v263.94c0,12.05,9.77,21.83,21.83,21.83l0,0h349.28 c12.05,0,21.83-9.77,21.83-21.83l0,0v-97.24L321.49,244.09z" fill="#185C37" />
    <path d="M321.49,17.86H140.9c-12.05,0-21.83,9.77-21.83,21.83l0,0v97.24L321.49,256l107.16,35.72L512,256V136.93 L321.49,17.86z" fill="#21A366" />
    <path d="M119.07,136.93h202.42V256H119.07V136.93z" fill="#107C41" />
    <path d="M263.94,113.12H119.07v297.67h144.87c12.04-0.04,21.79-9.79,21.83-21.83V134.94 C285.73,122.9,275.98,113.16,263.94,113.12z" fill="#000" fillOpacity="0.1" />
    <path d="M252.04,125.02H119.07V422.7h132.97c12.04-0.04,21.79-9.79,21.83-21.83V146.85 C273.82,134.81,264.07,125.06,252.04,125.02z" fill="#000" fillOpacity="0.2" />
    <path d="M240.13,125.02H119.07v273.86h121.06c12.04-0.04,21.79-9.79,21.83-21.83V146.85 C261.91,134.81,252.17,125.06,240.13,125.02z" fill="#000" fillOpacity="0.2" />
    <path d="M21.83,125.02h218.3c12.05,0,21.83,9.77,21.83,21.83v218.3c0,12.05-9.77,21.83-21.83,21.83H21.83 C9.77,386.98,0,377.21,0,365.15v-218.3C0,134.79,9.77,125.02,21.83,125.02z" fill="url(#excel-gradient)" />
    <defs>
      <linearGradient id="excel-gradient" x1="45.5065" y1="-1464.0308" x2="216.4467" y2="-1167.9695" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 1572)">
        <stop offset="0" stopColor="#18884F" />
        <stop offset="0.5" stopColor="#117E43" />
        <stop offset="1" stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

const SectionHeaderWithButton = ({ title, buttonText, onButtonClick, phase }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: "100%", mb: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "black",
          flexShrink: 0,
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          flexGrow: 1,
          borderBottomWidth: 1,
          bgcolor: "#5e5e5d",
        }}
      />
      {/* Render button only for phase 2 */}
      {phase === 2 && (
        <Tooltip title="Add a workstream to a Due Diligence phase" arrow>
          <Button
            startIcon={<CirclePlus color="black" />}
            onClick={onButtonClick}
            disabled={true}
            sx={{
              fontWeight: "bold",
              color: "black",
              flexShrink: 0,
            }}
          >
            {buttonText}
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};

const DueDiligence = ({ setView, transaction, saveWorkstream, handleTransactions }) => {
  // console.log("-------------------");
  console.log(transaction);
  // console.log("-------------------");
  const open = useSelector((state) => state.navBar.is_open);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { workstreams: workstream, isLoading } = useSelector((state) => state.workstream || []);
  const state = useSelector((state) => state);
  const organization_id = useSelector((state) => state.auth.user?.organization_id);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(`${transaction ? transaction.name : "Select Deal"}`);
  const open2 = Boolean(anchorEl);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const transactions = useSelector((state) => state.transactions.transactions || []);
  const navigate = useNavigate();

  // const organization_id = useSelector((state) => state.auth.user?.organization_id);
  // const fetch_fn = async () => {
  //   setIsLoadingTransactions(true);

  //   await dispatch(fetchTransactions(page + 1, rowsPerPage));

  //   setIsLoadingTransactions(false);
  // };
  // useEffect(() => {
  //   fetch_fn();
  // }, [dispatch, page, rowsPerPage]);

  const industriesPhase1 = transactions.map((e) => e.name);
  console.log(workstream);
  console.log(state);

  const template_to_conv = async () => {
    await backendClient.createWorkstreams(transaction.id);
  };
  // useEffect(() => {
  //   template_to_conv();
  // }, []);

  useEffect(() => {
    console.log("Running dispatch to fetch workstream");
    if (transaction?.id) {
      dispatch(fetchWorkstreams(transaction.id, 0, 100));
    }
  }, [dispatch, transaction]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = async (industry) => {
    setSelectedIndustry(industry); // Set the selected industry
    handleTransactions(transactions.filter((e) => e.name === industry)[0]);
    handleClose();
  };
  const handleBackClick = () => setView("DEALS");
  const handleEditClick = () => setIsEditMode(!isEditMode);
  // const handleDetailsClick = (workstream) => {
  //   console.log("Setting view to ", workstream);
  //   saveWorkstream(workstream);
  //   setView("DETAILS");
  // };

  const handleClickquestionnaire = async (workstream_id, transaction) => {
    if (isLoadingTransactions) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsLoadingTransactions(true);
    await dispatch(clearDocuments());
    // for (const transactionId of selected) {
    await dispatch(fetchDocuments(transaction.id));
    setIsLoadingTransactions(false);
    navigate(`/questionnaire/${workstream_id}`, { state: { transaction } });
    enqueueSnackbar("Questionnaire Opened!", { variant: "success" });
  };
  const handleAddWorkstream = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleEditWorkstream = (id, newTitle) => {
    // Implement your logic to update workstream
  };

  const handleDeleteWorkstream = (id) => {
    // Implement your logic to delete workstream
  };
  const phase1Workstreams = workstream.length > 0 ? workstream.filter((ws) => ws?.phase === "Phase 1") : [];
  const phase2Workstreams = workstream.length > 0 ? workstream.filter((ws) => ws?.phase === "Phase 2") : [];
  return (
    <Documentstart
      title={`${transaction ? transaction.name : "Select"} - Due Diligence`}
      open={open}
      headerProps={{
        showSearchBar: false,
        showFilter: false,
        showView: false,
        backbutton: true && !handleTransactions,
        handleBackClick: handleBackClick,
        custombutton: true && handleTransactions,
        custombuttontext: selectedIndustry,
        custombuttononclick: handleClick,
        // startIcon: <Plus size="22px" />,
        iconbutton: <ExpandMoreIcon />,
        hoverBackgroundColor: "",
        variant: "contained",
      }}
    >
      <>
        <Menu
          anchorEl={anchorEl}
          open={open2}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "350px", // Adjust match to Custombutton width
            },
          }}
        >
          {industriesPhase1.map((industry) => (
            <MenuItem key={industry} onClick={() => handleMenuItemClick(industry)}>
              {industry}
            </MenuItem>
          ))}
        </Menu>
      </>
      {transaction ? (
        <>
          <Stack mb={2} direction={"row"} spacing={3}>
            <Typography
              // gutterBottom
              variant="h5"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "#1AB5B8",
              }}
            >
              Due Diligence
            </Typography>
            <Tooltip title="Edit the workstream" arrow>
              <Button
                startIcon={<Edit size={20} color="#000" />}
                onClick={handleEditClick} // Toggle Edit Mode
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  px: "20px",
                }}
              >
                Edit
              </Button>
            </Tooltip>

            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <CustomProgressBar value={transaction.dd_score ? Number(transaction.dd_score.split("%")[0]) : 0} borderColor="#bfbdbd" hasBorder={"true"} />
            </Stack>
          </Stack>

          {isLoading || isLoadingTransactions ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <Spinner /> {/* Show a spinner while loading */}
            </Box>
          ) : (
            <>
              {/* Phase 01 Workstreams */}
              <SectionHeaderWithButton title="Phase 01" phase={1} />
              <Grid container ml={0} spacing={8} pb={3} pl={1} gap={3}>
                {phase1Workstreams.length > 0 ? (
                  phase1Workstreams.map((workstream, index) => (
                    <DueDiligenceCard
                      key={workstream.id}
                      id={workstream.id}
                      title={workstream.name}
                      progressPercentage={workstream.progress}
                      completedTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "Success").length, 0)}
                      inProgressTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "In Progress").length, 0)}
                      userInputTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "User Input").length, 0)}
                      notStartedTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "Pending").length, 0)}
                      type={index % 2 === 0 ? "inpair" : "pair"}
                      isEditMode={isEditMode}
                      onEdit={handleEditWorkstream}
                      onDelete={handleDeleteWorkstream}
                      onClick={() => handleClickquestionnaire(workstream.id, transaction)}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      marginTop: 6,
                      marginBottom: 2,
                      padding: 2,
                      marginLeft: 10,
                    }}
                  >
                    No Template workstreams found for Phase 1
                  </Typography>
                )}
              </Grid>

              {/* Phase 02 Workstreams */}
              <SectionHeaderWithButton title="Phase 02" buttonText="Add workstream" onButtonClick={handleAddWorkstream} phase={2} />
              <Grid container ml={0} spacing={8} pb={3} pl={1} gap={3}>
                {phase2Workstreams.length > 0 ? (
                  phase2Workstreams.map((workstream, index) => (
                    <DueDiligenceCard
                      key={workstream.id}
                      id={workstream.id}
                      title={workstream.name}
                      progressPercentage={workstream.progress}
                      completedTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "Success").length, 0)}
                      inProgressTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "In Progress").length, 0)}
                      userInputTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "User Input").length, 0)}
                      notStartedTasks={workstream.questionnaires.reduce((acc, questionnaire) => acc + questionnaire.questions.filter((question) => question.status === "Pending").length, 0)}
                      type={index % 2 === 0 ? "inpair" : "pair"}
                      isEditMode={isEditMode}
                      onEdit={handleEditWorkstream}
                      onDelete={handleDeleteWorkstream}
                      onClick={() => handleClickquestionnaire(workstream.id, transaction)}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      marginTop: 6,
                      marginBottom: 2,
                      padding: 2,
                      marginLeft: 10,
                    }}
                  >
                    No Template workstreams found for Phase 2
                  </Typography>
                )}
              </Grid>

              <WorkstreamModal open={isModalOpen} onClose={handleCloseModal} />
            </>
          )}
        </>
      ) : (
        <>
          <Box width={"90vw"} display={"flex"} justifyContent={"center"} marginTop={"16px"}>
            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} gap={1}>
              <AccountBalanceIcon style={{ fontSize: 40, color: "lightgray" }} /> {/* Import ChatIcon from '@mui/icons-material' */}
              <div>Select Deal to get started</div>
              {/* <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAddKeywordPair} // Implement this function
                              >
                              Create a KPI
                            </Button> */}
            </Box>
          </Box>
        </>
      )}
    </Documentstart>
  );
};

export default DueDiligence;
