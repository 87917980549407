import React from "react";
import { Typography, Container, Box, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import AssistantIcon from "@mui/icons-material/Assistant";
import BadgeIcon from "@mui/icons-material/Badge";
import SecurityIcon from "@mui/icons-material/Security";
const FeatureCardContainer = styled(Paper)({
  padding: "20px",
  textAlign: "left",
  color: "black",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #e0e0e0",
  backgroundColor: "#f9f9f9", // Background color for the card
});

const FeatureTitle = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "bold",
  marginBottom: "10px",
});

const FeatureDescription = styled(Typography)({
  fontSize: "1rem",
  color: "#757575",
});

const FeatureCard = ({ icon: Icon, title, description, image }) => {
  return (
    <FeatureCardContainer>
      <Box display="flex" alignItems="start" mb={2} flexDirection={"column"}>
        <Box display="flex" alignItems="center">
          <Icon sx={{ fontSize: "3rem", color: "black", marginBottom: "10px" }} />
          <Box ml={2}>
            <FeatureTitle>{title}</FeatureTitle>
          </Box>
        </Box>
        <FeatureDescription sx={{ ml: 4 }}>{description}</FeatureDescription>
      </Box>
      {image && <img src={image} alt={title} style={{ width: "100%", borderRadius: "10px", marginTop: "10px" }} />}
    </FeatureCardContainer>
  );
};

const SectionFeatures = React.forwardRef((props, ref) => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: { xs: 4, md: 8 }, flexGrow: 1 }}>
      <Box ref={ref} textAlign="center" mb={4}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          A solution to expand your margins and deal flow
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard
            icon={FindInPageIcon}
            title="Data Management Efficiency"
            description="Connect and extract relevant financial and legal insights from your data. DueDeal enables you to access and leverage your organisation's entire data effortlessly"
            // image="/path-to-your-image/prospecting-chart.png"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard icon={AssistantIcon} title="Deploy AI Bot" description="Meet DAN, our AI-powered M&A specialist providing you with effective support during and after your transactions" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard icon={BadgeIcon} title="Improve Your Team's Experience" description="Eliminate waiting time for responses on urgent requests, free up valuable staff time, automize process-heavy tasks and identify gaps in your analysis" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard
            icon={SecurityIcon}
            title="Data Security Insurance"
            description="Our software runs on your company's own IT infrastructure and no confidential data is submitted to third parties. Your sensitive financial information is safeguarded within your existing security protocols"
            // image="/path-to-your-image/discovery-call.png"
          />
        </Grid>
      </Grid>
    </Container>
  );
});

export default SectionFeatures;
