import React from "react";
import { Modal, Box, Typography, Button, SvgIcon } from "@mui/material";
import CloudOffIcon from "@mui/icons-material/CloudOff"; // Import the cloud off icon

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2, // Rounded corners
  textAlign: "center", // Center the text
  border: "2px solid #000", // Add a border
  borderColor: "primary.main", // Use the primary color for the border
};

const NoUploadModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="no-upload-modal-title" aria-describedby="no-upload-modal-description">
      <Box sx={style}>
        {/* Big, Fancy Icon */}
        <SvgIcon color="error" sx={{ fontSize: 60, mb: 2 }}>
          {" "}
          {/* Adjust color and size as needed */}
          <CloudOffIcon />
        </SvgIcon>
        <Typography id="no-upload-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Maximum Transaction Limit Reached
        </Typography>
        <Typography id="no-upload-modal-description" sx={{ mb: 2 }}>
        You have reached the maximum number of transactions allowed in this environment. If this is incorrect, please contact support for further assistance.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default NoUploadModal;
