// src/actions/templateQuestionnaireActions.js

import { backendClient } from "../../api/backend";
import {
  FETCH_TQUESTIONS_REQUEST,
  FETCH_TQUESTIONS_SUCCESS,
  FETCH_TQUESTIONS_FAILURE,
  CREATE_TQUESTION_REQUEST,
  CREATE_TQUESTION_SUCCESS,
  CREATE_TQUESTION_FAILURE,
  UPDATE_TQUESTION_REQUEST,
  UPDATE_TQUESTION_SUCCESS,
  UPDATE_TQUESTION_FAILURE,
  DELETE_TQUESTION_REQUEST,
  DELETE_TQUESTION_SUCCESS,
  DELETE_TQUESTION_FAILURE,
} from "./types";

// Fetch template questionnaires for a workstream
export const fetchQuestions = (template_questionnaire_id) => async (dispatch) => {
  dispatch({ type: FETCH_TQUESTIONS_REQUEST });
  try {
    const questions = await backendClient.fetchTemplateQuestions(template_questionnaire_id);
    dispatch({ type: FETCH_TQUESTIONS_SUCCESS, payload: questions });
  } catch (error) {
    dispatch({ type: FETCH_TQUESTIONS_FAILURE, payload: error.message });
  }
};

// Create a new question
export const createQuestion = (questionData) => async (dispatch) => {
  dispatch({ type: CREATE_TQUESTION_REQUEST });
  try {
    const newQuestion = await backendClient.createTemplateQuestion(questionData);
    dispatch({ type: CREATE_TQUESTION_SUCCESS, payload: newQuestion });
    return newQuestion;
  } catch (error) {
    dispatch({ type: CREATE_TQUESTION_FAILURE, payload: error.message });
  }
};

// Update a question
export const updateQuestion = (questionId, questionData) => async (dispatch) => {
  dispatch({ type: UPDATE_TQUESTION_REQUEST });
  try {
    const updatedQuestion = await backendClient.updateTemplateQuestion(questionId, questionData);
    dispatch({ type: UPDATE_TQUESTION_SUCCESS, payload: updatedQuestion });
  } catch (error) {
    dispatch({ type: UPDATE_TQUESTION_FAILURE, payload: error.message });
  }
};

// Delete a question
export const deleteQuestions = (questionIds) => async (dispatch) => {
  dispatch({ type: DELETE_TQUESTION_REQUEST });
  try {
    await backendClient.deleteTemplateQuestions(questionIds); // Assume this is a bulk delete API call
    dispatch({ type: DELETE_TQUESTION_SUCCESS, payload: questionIds }); // Pass the deleted IDs as payload
  } catch (error) {
    dispatch({ type: DELETE_TQUESTION_FAILURE, payload: error.message });
  }
};
