import React from "react";
import { Typography, Container, Box, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StatsPaper = styled(Paper)({
  padding: "20px",
  textAlign: "center",
  color: "black",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #e0e0e0",
});

const StatNumber = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#ff5722",
});

const StatLabel = styled(Typography)({
  fontSize: "1rem",
  color: "#757575",
});

const Sectionstats = () => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: { xs: 4, md: 8 }, flexGrow: 1 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Best-in-class data to access new sources of capital
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, lineHeight: 1.5, color: "#757575" }}>
          Build relationships and identify new opportunities in the private wealth sector without the burden of manual research.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>80,000</StatNumber>
            <StatLabel>UHNW Individuals</StatLabel>
          </StatsPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>6,000</StatNumber>
            <StatLabel>Family Offices</StatLabel>
          </StatsPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>450 Million</StatNumber>
            <StatLabel>Datapoints</StatLabel>
          </StatsPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>100,000</StatNumber>
            <StatLabel>Financial Advisors</StatLabel>
          </StatsPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>120,000</StatNumber>
            <StatLabel>Contact Emails</StatLabel>
          </StatsPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsPaper>
            <StatNumber>$15 Trillion</StatNumber>
            <StatLabel>Total Wealth</StatLabel>
          </StatsPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sectionstats;
