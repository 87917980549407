import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Search } from '@material-ui/icons';
import { X } from 'lucide-react';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <TextField
      id="search-bar"
      variant="outlined"
      placeholder="Search..."
      fullWidth
      value={searchQuery}
      onChange={handleSearchChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "28px",
          backgroundColor: "#F5F5F7",
          "&:hover": {
            backgroundColor: "#E8E8E8",
          },
          "&.Mui-focused": {
            backgroundColor: "#FFFFFF",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          },
        },
        "& .MuiOutlinedInput-input": {
          padding: "14px 14px 14px 0",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton sx={{ p: "10px" }}>
              <Search size={20} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchQuery && (
          <InputAdornment position="end">
            <IconButton onClick={handleClearSearch} sx={{ p: "10px" }}>
              <X size={20} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
