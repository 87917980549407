import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, TextField, Typography } from "@mui/material";

const EditQuestionDialog = ({ open, handleClose, question }) => {
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionContent, setQuestionContent] = useState("");

  useEffect(() => {
    if (question) {
      setQuestionTitle(question.title);
      setQuestionContent(question.content);
    }
  }, [question]);

  const handleSave = () => {
    // Logic to save the edited question (e.g., dispatch an action or call an API)
    console.log("Saving edited question:", { title: questionTitle, content: questionContent });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Edit Question
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Modify the question details below
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6">Title:</Typography>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <TextField label="Question Title" value={questionTitle} onChange={(e) => setQuestionTitle(e.target.value)} fullWidth />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "black", borderRadius: "6px", fontSize: 16, padding: "10px 20px", width: "180px", height: "60px" }}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            bgcolor: "black",
            color: "white",
            borderRadius: "6px",
            "&:hover": { bgcolor: "black", color: "white" },
            width: "180px",
            height: "60px",
            fontSize: 16,
            padding: "10px 20px",
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuestionDialog;
