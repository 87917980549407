import {
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  VALIDATE_QUESTION_REQUEST,
  VALIDATE_QUESTION_SUCCESS,
  VALIDATE_QUESTION_FAILURE,
  VALIDATE_QUESTION,
} from "../actions/types";

const initialState = {
  questions: [],
  isLoading: false,
  error: null,
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_QUESTION_REQUEST:
    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_QUESTION_REQUEST:
    case VALIDATE_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
        isLoading: false,
      };

    case FETCH_QUESTIONS_FAILURE:
      return {
        ...state,
        // questions: action.payload,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_QUESTION_FAILURE:
    case UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_QUESTION_FAILURE:
    case VALIDATE_QUESTION:
      return {
        ...state,
        questions: [...state.questions, action.payload],
      };
    case VALIDATE_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: [...state.questions, action.payload],
        isLoading: false,
      };

    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.map((question) => (question.id === action.payload.id ? action.payload : question)),
        isLoading: false,
      };

    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.filter((question) => question.id !== action.payload),
        isLoading: false,
      };

    case VALIDATE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.map((question) => (question.id === action.payload.id ? action.payload : question)),
        isLoading: false,
      };

    default:
      return state;
  }
};

export default questionReducer;
