/** @jsxImportSource @emotion/react */

import React, { useRef, useEffect } from "react";
import { Typography, Container, Box, Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

// Update these paths to the correct locations of your images
import platformImage from "../../../assets/landingpage/pv.png";
import platformVideo from "../../../assets/landingpage/pv2.mp4"; // Make sure to update the path to your video file

import { colors } from "../../../utils/colors";
import { OpenCalendar, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: "#000", // Text color for Join Waitlist button
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  "&:hover": {
    backgroundColor: colors.LGREEN,
    color: "white",
    boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Adjust shadow for hover state if needed
  },
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
  margin: "0 10px", // Add margin between buttons
});

const StyledButtonBlack = styled(Button)({
  backgroundColor: "#000", // Replace with the exact color code from the image
  color: "white", // Text color for Book Demo button
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  "&:hover": {
    backgroundColor: "#333",
    color: "white",
    boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Adjust shadow for hover state if needed
  },
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
  margin: "0 10px", // Add margin between buttons
});

const pseudoElementStyle = css`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid white; /* Adjust the size of the border as needed */
    box-sizing: border-box;
    pointer-events: none; /* Ensure the pseudo-element does not interfere with video interactions */
    z-index: 1; /* Ensure the pseudo-element overlays the video */
  }
`;
const SectionZero = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.storylane.io/js/v1/storylane.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const button = document.getElementById("storylane-button");
      if (button) {
        button.addEventListener("click", () => {
          if (window.gtag) {
            window.gtag("event", "click", {
              event_category: "engagement",
              event_label: "view_demo",
              value: "Storylane View Demo Click",
            });
          }
          const iframe = document.querySelector(".sl-demo");
          if (iframe) {
            iframe.style.display = "block";
            window.Storylane.Play({
              type: "preview_embed",
              demo_type: "image",
              width: 1920,
              height: 869,
              element: button,
              demo_url: "https://app.storylane.io/demo/sejhgsi7fqrv",
            });
          }
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const embedHtml = `
    <style>
      .sl-heading-text {
        max-width:53%;
      }
      @media (max-width: 1024px) {
        .sl-heading-text {
          max-width:90%;
        }
      }
      .sl-preview-cta:hover .sl-preview-cta-ripple {
        transition:all 1s cubic-bezier(0,0,.2,1);
        inset:-0.75em!important;
        opacity:0!important;
      }
      .sl-preview-cta:hover .sl-preview-cta-ripple-shadow {
        opacity:0.125!important;
      }
    </style>
    <div class="sl-embed-container" style="position:relative;display:flex;align-items:center;justify-content:center;border: 1px solid rgba(63,95,172,0.35);box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px">
      <div class="sl-preview-heading" style="position:absolute;inset:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:rgba(40, 37, 54, 0.9);z-index:999999;font-family:Poppins, Arial, sans-serif;font-size:clamp(20px, 2.664vw, 28px);font-weight:500;line-height:normal;text-align:center;border-radius:10px;">
        <div class="sl-heading-text" style="color:#fff;margin-bottom:clamp(20px, 1.776vw, 24px);text-shadow: 0px 1px 2px rgba(26, 19, 72, 0.40);">Take a tour of product</div>
        <button id="storylane-button" class="sl-preview-cta" style="background-color:#9939EB;border:none;border-radius:8px;box-shadow:0px 0px 15px rgba(26, 19, 72, 0.45);color:#FFFFFF;display:inline-block;font-family:Poppins, Arial, sans-serif;font-size:clamp(16px, 1.599vw, 20px);font-weight:600;height:clamp(40px, 3.996vw, 50px);line-height:1.2;padding:0 clamp(15px, 1.776vw, 20px);text-overflow:ellipsis;transform:translateZ(0);transition:background 0.4s;white-space:nowrap;width:auto;z-index:999999;cursor:pointer">VIEW DEMO
          <div class="sl-preview-cta-ripple" style="position:absolute;border:1px solid #9939EB;inset:0;border-radius:inherit;pointer-events:none">
            <div class="sl-preview-cta-ripple-shadow" style="box-shadow:#9939EB 0px 0px 4px 4px;opacity:0;border-radius:inherit;position:absolute;inset:0"></div>
          </div>
        </button>
      </div>
      <div class="sl-embed" data-sl-demo-type="image" style="position:relative;padding-bottom:calc(45.26% + 25px);width:100%;height:0;transform:scale(1);overflow:hidden;">
        <div class="sl-preview" style="width:100%;height:100%;z-index:99999;position:absolute;background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_0f4f3177-4da7-43cd-8f04-7dc82f585ce5/project/project_5d29682b-1e8c-4977-a213-e917fc9080b3/page/1723038793406.png') no-repeat;background-size:100% 100%;border-radius:inherit;"></div>
        <iframe class="sl-demo" src="" name="sl-embed" allow="fullscreen" allowfullscreen style="display:none;position:absolute;top:0;left:0;width:100%;height:100%;border:none;" title="Storylane Demo"></iframe>
      </div>
    </div>
  `;
  return (
    <Container maxWidth="lg" sx={{ marginTop: { xs: 2, md: 4 }, flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box textAlign="center" my={4}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "3rem" },
            variant: "h1",
          }}
          fontWeight="bold"
          gutterBottom
        >
          Affordable Business Due Diligence
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, lineHeight: 1.5, marginBottom: 2 }}>
          Our AI-powered financial advisor guides you through every step of commercial and financial due diligence, empowering you to make confident and informed decisions
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          {/* <StyledButton variant="contained">Join Waitlist</StyledButton> */}
          <StyledButtonBlack onClick={OpenCalendar} variant="contained">
            Book a Demo
          </StyledButtonBlack>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid item xs={12} md={10} display={"flex"} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Box
            // css={pseudoElementStyle}
            sx={{
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: { xs: windowWidth / 2, md: "520px" }, // Adjust the height for mobile
              // width: { xs: windowWidth / 1.08, md: "950px" }, // Adjust the height for mobile
              backgroundColor: "transparent",
              overflow: "hidden",
              // backgroundImage: `url(${platformImage})`,
              // backgroundSize: "contain",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "center",
            }}
          >
            {" "}
            <div style={{ width: windowWidth }} dangerouslySetInnerHTML={{ __html: embedHtml }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionZero;
