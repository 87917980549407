import React, { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, InputAdornment, IconButton, TextField, Tooltip, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import NavigationBar from "../../components/NavigationBar";
import { getIndustryColor, isMobile, sleep, windowHeight, windowWidth } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import at the top of your file
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { clearTransactions, deleteTransaction, deleteTransactions, fetchTransaction, fetchTransactions, setTransactions } from "../../redux/actions/transactionAction";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { clearExploredData, exploreTransactions } from "../../redux/actions/exploreAction";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import ImportFormModal from "./components/ImportFormModal";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { createConversation } from "../../redux/actions/conversationAction";
import { closeSnackbar, useSnackbar } from "notistack";
import { clearHypothesis, extractHypothesis, fetchHypothesis } from "../../redux/actions/hypothesisAction";
import { colors } from "../../utils/colors";
import { backendClient } from "../../api/backend";

const TransactionsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("description");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isStartConversationLoading, setIsStartConversationLoading] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const transactions = useSelector((state) => state.transactions.transactions);
  console.log(transactions)
  // const transactions = useSelector((state) => state.transactions.transactions);
  const user_id = useSelector((state) => state.auth.user?.id);
  const user = useSelector((state) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();

  const [totalTransactions, setTotalTransactions] = useState(0);
  const fetch_fn = async () => {
    setIsLoadingTransactions(true);
    await dispatch(fetchTransactions(page + 1, rowsPerPage));
    if (totalTransactions === 0) {
      const totalTransactions_ = await backendClient.countTransactions();
      setTotalTransactions(totalTransactions_["count"]);
    }
    setIsLoadingTransactions(false);
  };
  useEffect(() => {
    fetch_fn();
  }, [dispatch, page, rowsPerPage]);

  const [isLoadingExtractHypothesis, setIsLoadingExtractHypothesis] = useState(false);
  const handleExtractHypothesis = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    if (selected.length > 1) {
      enqueueSnackbar("Select only one transaction to extract hypothesis.", { variant: "error" });
    }
    setIsLoadingExtractHypothesis(true);
    await dispatch(clearHypothesis());

    const filtred_transactions = transactions.filter((x) => selected.includes(x.id));
    await dispatch(setTransactions(filtred_transactions));

    for (const transaction of filtred_transactions) {
      if (transaction.is_extracted === "True" || transaction.is_extracted === "Pending") {
        await dispatch(fetchHypothesis(transaction.id));
      } else {
        await dispatch(extractHypothesis([transaction.id], user_id));
      }
    }

    // get conversation transactionsIds_documentsIds
    const transactionsIds_documentsIds = transactions.map((x) => {
      return { transaction_id: x.id, documents_ids: x.documents_ids };
    });
    console.log("transactionsIds_documentsIds", transactionsIds_documentsIds);
    navigate(`/deals/hypothesis`, { state: { transactionsIds_documentsIds } });
    setIsLoadingExtractHypothesis(false);
  };

  const handleStartConversation = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsStartConversationLoading(true);
    // get selected transactions
    const filtred_transactions = transactions.filter((x) => selected.includes(x.id));
    await dispatch(setTransactions(filtred_transactions));

    // fetch documents

    await dispatch(clearDocuments());
    for (const transactionId of selected) {
      await dispatch(fetchDocuments(transactionId));
    }

    // create converstation
    const transactionId_documentsIds = filtred_transactions.map((x) => {
      return { transaction_id: x.id, documents_ids: x.documents_ids };
    });
    const conversationId_ = await dispatch(createConversation(transactionId_documentsIds, user_id));

    setIsStartConversationLoading(false);
    navigate(`/chat/${conversationId_}`);
    enqueueSnackbar("Conversation started!", { variant: "success" });
  };

  const handleRequestSort = (property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = transactions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // TO DO --> Add multi select back
  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex >= 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }

  //   setSelected(newSelected);
  // };
  const handleClick = (event, id) => {
    if (selected.includes(id)) {
      setSelected([]);
    } else {
      setSelected([id]);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };
  // Filter transactions based on the search query
  const filteredTransactions = transactions.filter((transaction) => {
    // Check if the search query is included in any of the transaction fields
    return (
      transaction.name.toLowerCase().includes(searchQuery) ||
      transaction.id.toLowerCase().includes(searchQuery) ||
      transaction.industry.toLowerCase().includes(searchQuery) ||
      transaction.country.toLowerCase().includes(searchQuery) ||
      transaction.description.toLowerCase().includes(searchQuery) ||
      transaction.date.toLowerCase().includes(searchQuery)
    );
  });

  const [isLoadingExplore, setIsLoadingExplore] = useState(false);

  const handleExploreTransaction = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsLoadingExplore(true);
    const snackbar = enqueueSnackbar("Loading transaction...");

    // clear transactions
    // await dispatch(clearTransactions());
    // clear explored data
    await dispatch(clearExploredData());
    // clear documents
    await dispatch(clearDocuments());

    // get selected transactions
    const filtred_transactions = transactions.filter((x) => selected.includes(x.id));
    console.log("printooo", filtred_transactions);
    await dispatch(setTransactions(filtred_transactions));

    // fetch transactions and documents
    for (const transactionId of selected) {
      // const transaction = await dispatch(fetchTransaction(transactionId, true));
      await dispatch(fetchDocuments(transactionId));
    }
    // navigate to explore
    if (location.pathname === "/deals") {
      navigate("/deals/explore");
    } else {
      navigate(`/data-import/transactions/explore`);
    }
    setIsLoadingExplore(false);
    closeSnackbar(snackbar);
    enqueueSnackbar("Explore transaction started!", { variant: "success" });
  };

  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDeleteTransaction = async () => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    await dispatch(deleteTransactions(selected));
    await fetch_fn();
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Transaction deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const [transaction_, setTransaction] = useState(null);

  const handleEdit = async (event, transaction_) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    if (isLoadingExtractHypothesis || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setTransaction(transaction_);
    setIsModalEditVisible(true);
  };

  const handleEditDocuments = async (event, transaction_) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    if (isLoadingExtractHypothesis || isLoadingTransactions || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    const transactionId = transaction_.id;
    if (location.pathname === "/deals") {
      navigate(`/deals/${transactionId}`, {
        state: {
          documentIds: transaction_.documents_ids,
          transactionName: transaction_.name,
        },
      });
    } else {
      navigate(`/data-import/transactions/${transactionId}`, {
        state: {
          documentIds: transaction_.documents_ids,
          transactionName: transaction_.name,
        },
      });
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, borderRadius: 2, width: "100%", height: "100%" }}>
            {/* MODAL */}
            <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Deals"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />
            {transaction_ && <ImportFormModal isSubmitLoading={isSubmitLoading} setIsSubmitLoading={setIsSubmitLoading} transaction_={transaction_} open={isModalEditVisible} handleClose={() => setIsModalEditVisible(false)} />}

            {/* HEADER */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: isMobile() ? 0 : 2 }}>
                {location.pathname !== "/deals" && <BackButton />}
                <h2 className="text-xl font-bold text-gray-800">Deals</h2>
              </Box>

              {!isMobile() && (
                <Box sx={{}}>
                  <Button
                    style={{ marginRight: windowWidth / 80, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                    variant={selected.length === 0 ? "outlined" : "contained"}
                    color="primary"
                    disabled={selected.length === 0 || isLoadingExtractHypothesis}
                    onClick={handleExtractHypothesis}
                  >
                    {isLoadingExtractHypothesis ? "Extracting hypothesis..." : "Extract Hypothesis"}
                  </Button>
                  <Button style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} variant={selected.length === 0 ? "outlined" : "contained"} disabled={selected.length === 0 || isStartConversationLoading} onClick={handleStartConversation}>
                    {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                  </Button>
                </Box>
              )}
            </Box>

            {isMobile() && (
              <Box sx={{ display: "flex", marginBottom: windowHeight / 400 }}>
                <Button
                  sx={{
                    flexGrow: 1,
                    mr: 1, // marginRight: 10px replaced by material ui spacing system
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                  }}
                  variant={selected.length === 0 ? "outlined" : "contained"}
                  color="primary"
                  disabled={selected.length === 0 || isLoadingExtractHypothesis}
                  onClick={handleExtractHypothesis}
                >
                  {isLoadingExtractHypothesis ? "Extracting hypothesis..." : "Extract Hypothesis"}
                </Button>
                <Button
                  sx={{
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                  }}
                  variant={selected.length === 0 ? "outlined" : "contained"}
                  disabled={selected.length === 0 || isStartConversationLoading}
                  onClick={handleStartConversation}
                >
                  {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                </Button>
              </Box>
            )}

            {/* Search bar and Explore Transaction button */}
            {!isMobile() ? (
              <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 4 }}>
                <TextField
                  id="search-bar"
                  className="flex-grow"
                  variant="outlined"
                  placeholder="Search by keyword..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {selected.length > 0 && (
                  <Button variant={selected.length === 0 ? "outlined" : "contained"} disabled={isLoadingDelete} onClick={handleDeleteTransaction} style={{ marginLeft: windowWidth / 80, backgroundColor: selected.length === 0 ? "" : "red" }}>
                    {selected.length <= 1 ? "Delete deal" : "Delete deals"}
                  </Button>
                )}
                <Button style={{ marginLeft: windowWidth / 80, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} variant={selected.length === 0 ? "outlined" : "contained"} color="primary" disabled={selected.length === 0 || isLoadingExplore} onClick={handleExploreTransaction}>
                  {isLoadingExplore ? "Loading..." : selected.length <= 1 ? "Explore deal" : "Explore deals"}
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
                <TextField
                  id="search-bar"
                  className="flex-grow"
                  variant="outlined"
                  placeholder="Search by keyword..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ display: "flex", marginTop: windowHeight / 600 }}>
                  {selected.length > 0 && (
                    <Button
                      style={{ backgroundColor: selected.length === 0 ? "" : "red" }}
                      sx={{
                        flexGrow: 1,
                        whiteSpace: "nowrap",
                        fontSize: "0.75rem",
                        mr: 1,
                        backgroundColor: selected.length === 0 ? "" : "red",
                      }}
                      variant={selected.length === 0 ? "outlined" : "contained"}
                      disabled={isLoadingExtractHypothesis || isLoadingTransactions || isStartConversationLoading || isLoadingExplore}
                      onClick={handleDeleteTransaction}
                    >
                      {selected.length <= 1 ? "Delete deal" : "Delete deals"}
                    </Button>
                  )}
                  <Button
                    sx={{ flexGrow: 1, whiteSpace: "nowrap", fontSize: "0.75rem" }}
                    variant={selected.length === 0 ? "outlined" : "contained"}
                    color="primary"
                    disabled={selected.length === 0 || isLoadingExtractHypothesis || isLoadingTransactions || isStartConversationLoading || isLoadingExplore}
                    onClick={handleExploreTransaction}
                  >
                    {isLoadingExplore ? "Loading..." : selected.length <= 1 ? "Explore deal" : "Explore deals"}
                  </Button>
                </Box>
              </Box>
            )}

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* TODO Re-enable this once we have entire transactions */}
                    <TableCell padding="checkbox">
                      {/* <Checkbox indeterminate={selected.length > 0 && selected.length < transactions.length} checked={transactions.length > 0 && selected.length === transactions.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all transactions" }} /> */}
                    </TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Industry</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingTransactions ? (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: "center" }}>
                        <CircularProgress size={48} thickness={4} />
                      </TableCell>
                    </TableRow>
                  ) : filteredTransactions?.length > 0 ? (
                    filteredTransactions.map((transaction) => (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, transaction.id)}
                        role="checkbox"
                        aria-checked={selected.indexOf(transaction.id) !== -1}
                        tabIndex={-1}
                        key={transaction.id}
                        selected={selected.indexOf(transaction.id) !== -1}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selected.indexOf(transaction.id) !== -1} inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${transaction.id}` }} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div style={{ display: "flex", alignItems: "center", gap: !transaction.document_uploaded && "10px" }}>
                            <div>
                              {!transaction.document_uploaded && (
                                <Tooltip title="Uploading documents...">
                                  <CircularProgress size={20} />
                                </Tooltip>
                              )}
                            </div>
                            <div>
                              <div style={{ fontWeight: "bold" }}>{transaction.name}</div>
                              <div>{transaction.id}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.5em 1em",
                              borderRadius: "16px",
                              color: "white",
                              backgroundColor: getIndustryColor(transaction.industry),
                            }}
                          >
                            {transaction.industry}
                          </span>
                        </TableCell>
                        <TableCell>{transaction.country}</TableCell>
                        <TableCell>{transaction.description}</TableCell>
                        <TableCell>{transaction.date}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton onClick={(event) => handleEdit(event, transaction)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Explore Documents">
                            <IconButton onClick={(event) => handleEditDocuments(event, transaction)}>
                              <FindInPageIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: "center" }}>
                        <div>
                          <SwapHorizIcon style={{ fontSize: 40, color: "lightgray" }} />
                          <div>No transaction created yet.</div>
                          <Button variant="contained" color="primary" onClick={() => navigate("/data-import")}>
                            Create a deal
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={totalTransactions} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransactionsScreen;
