import React, { createContext, useState, useContext } from "react";

// Define PdfFocusState type
export const PdfFocusState = {
  documentId: "",
  pageNumber: 0,
  citation: undefined,
};

// Create PdfFocusContext
export const PdfFocusContext = createContext();

// Define PdfFocusProvider component
export const PdfFocusProvider = ({ children }) => {
  const [pdfFocusState, setPdfFocusState] = useState(PdfFocusState);

  return (
    <PdfFocusContext.Provider
      value={{
        pdfFocusState: pdfFocusState,
        setPdfFocusState: setPdfFocusState,
      }}
    >
      {children}
    </PdfFocusContext.Provider>
  );
};

// Define usePdfFocus custom hook
export const usePdfFocus = () => {
  const context = useContext(PdfFocusContext);
  if (context === undefined) {
    throw new Error("usePDF must be used within a PDFProvider");
  }
  return context;
};
