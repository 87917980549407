import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SourceFilter from "./SourceFilter";
import DateFilter from "./DateFilter";
import MoreFilter from "./MoreFilter";
import { useDispatch, useSelector } from "react-redux";
import { setFiltredExploredData } from "../../../../redux/actions/exploreAction";

const Filter = ({ refresh, isCollapsedContainer, handleCollapseContainerClick }) => {
  // SOURCE FILTER - State
  const [sourceFilter, setSourceFilter] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false); // State to manage collapse
  const [checkedState, setCheckedState] = useState(null);

  // DATE FILTER - State
  const [selectedDate, setSelectedDate] = useState("last2years");

  // MORE FILTER - State
  const [moreFiltersConfig, setMoreFiltersConfig] = useState([]);
  const [isMoreFilterCollapsed, setIsMoreFilterCollapsed] = useState(false);
  const [moreFilterCheckedState, setMoreFilterCheckedState] = useState(null);

  // REDUX
  const documents = useSelector((state) => state.documents.documents);
  const transactions = useSelector((state) => state.transactions.transactions);
  const exploredData = useSelector((state) => state.explore.exploredData);
  const dispatch = useDispatch();

  // MORE FILTER - Functions
  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed); // Toggle collapse state
  };

  //////////////////////////////////////////////////////////////////////
  // MORE FILTER - Functions
  //////////////////////////////////////////////////////////////////////

  const getMoreFiltersConfig = async () => {
    const industriesSet = new Set();
    const countriesSet = new Set();

    transactions.forEach((transaction) => {
      industriesSet.add(transaction.industry);
      countriesSet.add(transaction.country);
    });

    // Convert sets to arrays and add 'All' option
    const industries = [...industriesSet];
    const countries = [...countriesSet];

    const moreFiltersConfig = [
      {
        name: "industries",
        label: "Industries",
        options: industries,
      },
      {
        name: "countries",
        label: "Countries",
        options: countries,
      },
    ];
    setMoreFiltersConfig(moreFiltersConfig);

    // CHECK STATE
    const checkedState = moreFiltersConfig.reduce((acc, group) => {
      const groupKey = group.name;
      acc[groupKey] = {
        checked: false,
        items: group.options.reduce((a, option) => ({ ...a, [option]: false }), {}),
      };
      return acc;
    }, {});
    setMoreFilterCheckedState(checkedState);
  };
  useEffect(() => {
    getMoreFiltersConfig();
  }, [refresh]);
  const handleMoreFilterCollapseClick = () => {
    setIsMoreFilterCollapsed(!isMoreFilterCollapsed);
  };
  const handleMoreFilterChange = (newCheckedState) => {
    // Filter exploredData
    if (exploredData) {
      if (!isAllNotCheck(newCheckedState)) {
        dispatch(setFiltredExploredData(exploredData));
      } else {
        const filtredExploredData = exploredData.filter((item) => {
          const industry = item.metadata.db_transaction_industry;
          const country = item.metadata.db_transaction_country;
          const industryChecked = newCheckedState.industries.items[industry];
          const countryChecked = newCheckedState.countries.items[country];
          return industryChecked || countryChecked;
        });
        dispatch(setFiltredExploredData(filtredExploredData));
      }
    }
  };
  const handleMoreFilterGroupToggle = (groupKey) => (event) => {
    event.stopPropagation();
    // Set Checked State
    const newCheckedState = {
      ...moreFilterCheckedState,
      [groupKey]: {
        checked: !moreFilterCheckedState[groupKey].checked,
        items: Object.keys(moreFilterCheckedState[groupKey].items).reduce((a, itemKey) => {
          a[itemKey] = !moreFilterCheckedState[groupKey].checked;
          return a;
        }, {}),
      },
    };
    setMoreFilterCheckedState(newCheckedState);

    // Filter exploredData
    handleMoreFilterChange(newCheckedState);
  };
  const handleMoreFilterItemToggle = (groupKey, itemKey) => () => {
    // Set Checked State
    const newCheckedState = {
      ...moreFilterCheckedState,
      [groupKey]: {
        ...moreFilterCheckedState[groupKey],
        items: {
          ...moreFilterCheckedState[groupKey].items,
          [itemKey]: !moreFilterCheckedState[groupKey].items[itemKey],
        },
      },
    };
    const areAllChecked = Object.values(newCheckedState[groupKey].items).every(Boolean);
    newCheckedState[groupKey].checked = areAllChecked;
    setMoreFilterCheckedState(newCheckedState);

    // Filter exploredData
    handleMoreFilterChange(newCheckedState);
  };

  //////////////////////////////////////////////////////////////////////
  // DATE FILTER - Functions
  //////////////////////////////////////////////////////////////////////
  const filterDocumentsByDate = (documents, dateFilter) => {
    const currentDate = new Date();
    let dateLimit;

    switch (dateFilter) {
      case "last2years":
        dateLimit = new Date(currentDate.setFullYear(currentDate.getFullYear() - 2));
        break;
      case "last1year":
        dateLimit = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
        break;
      default:
        // 'allDates' or any other value will not apply any date filtering
        dateLimit = null;
    }

    let filteredIds = [];

    documents.forEach((docGroup) => {
      docGroup.documents.forEach((doc) => {
        const createdDate = new Date(doc.created_at);
        if (!dateLimit || createdDate >= dateLimit) {
          filteredIds.push(doc.id);
        }
      });
    });

    return filteredIds;
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    if (exploredData) {
      const filtredDocumentsIds = filterDocumentsByDate(documents, event.target.value);
      const filtredExploredData = exploredData.filter((item) => filtredDocumentsIds.includes(item.metadata.db_document_id));
      dispatch(setFiltredExploredData(filtredExploredData));
    }
  };

  //////////////////////////////////////////////////////////////////////
  // SOURCE FILTER - Functions
  //////////////////////////////////////////////////////////////////////
  const getSourceFilter = async () => {
    console.log("doXXSXcuments", documents);
    const reducedDocuments = documents
      .flatMap((transaction) => transaction.documents)
      .reduce((acc, doc) => {
        // Use document_type as key
        const docType = doc.document_type;
        // Create an array for this type if it doesn't exist
        if (!acc[docType]) {
          acc[docType] = [];
        }
        // Add the document category and format to the array for this type
        const docEntry = { name: doc.document_category, type: doc.document_format };
        // Check if the entry already exists to avoid duplicates
        if (!acc[docType].some((entry) => entry.name === docEntry.name && entry.type === docEntry.type)) {
          acc[docType].push(docEntry);
        }

        return acc;
      }, {});

    // Convert the object to an array of objects
    let result = Object.keys(reducedDocuments).map((docType) => ({
      [docType]: reducedDocuments[docType],
    }));
    let transactions_ = [];
    transactions.forEach((x) => {
      transactions_.push({ id: x.id, name: x.name, type: "" });
    });
    result.push({ Transactions: transactions_ });
    setSourceFilter(result);

    // CHECK STATE
    const checked = result.reduce((acc, group) => {
      const groupKey = Object.keys(group)[0];
      acc[groupKey] = {
        checked: false,
        items: group[groupKey].reduce((a, v) => ({ ...a, [`${v.name} (${v.type})`]: false }), {}),
      };
      return acc;
    }, {});
    setCheckedState(checked);
  };
  useEffect(() => {
    getSourceFilter();
  }, [refresh]);

  const handleFitlerChange = (newCheckedState) => {
    console.log("documents", documents);
    console.log("exploredData", exploredData);
    console.log("newCheckedState", newCheckedState);
    // Filter exploredData
    if (exploredData) {
      if (!isAllNotCheck(newCheckedState)) {
        console.log('HEROO')
        dispatch(setFiltredExploredData(exploredData));
      } else {
        const filterDocumentIds = (documents, newCheckedState) => {
          let filteredIds = [];
          documents.forEach((docGroup) => {
            // filter document based on transactions
            // if (newCheckedState["Transactions"]) {
              // get transaction name or ids
              // const CheckedTransactionIds = Object.entries(newCheckedState["Transactions"].items)
              //   .filter((x) => x[1])
              //   .map((x) => x[0]);
              // console.log("CheckedTransactionIds", CheckedTransactionIds);
              // if (!CheckedTransactionIds.includes(docGroup.transactionId)) {
                docGroup.documents.forEach((doc) => {
                  const typeState = newCheckedState[doc.document_type];
                  console.log('typeState', typeState)
                  // if (typeState && typeState.checked) {
                    const categoryKey = Object.keys(typeState.items).find((key) => key.replace(/\s*\([^)]*\)/g, "") === doc.document_category);
                    if (typeState.items[categoryKey]) {
                      filteredIds.push(doc.id);
                    }
                  // }
                });
              // }
            // }
          });

          return filteredIds;
        };
        const filteredDocumentsIds = filterDocumentIds(documents, newCheckedState);
        const filtredExploredData = exploredData.filter((item) => filteredDocumentsIds.includes(item.metadata.db_document_id));
        console.log('NEWfiltredExploredData', filtredExploredData)
        console.log('filteredDocumentsIds', filteredDocumentsIds)
        console.log('newCheckedStateXXXX', newCheckedState)
        console.log('documentsXXX', documents)

        dispatch(setFiltredExploredData(filtredExploredData));
      }
    }
  };
  const handleGroupToggle = (groupKey) => (event) => {
    // Set Checked State
    event.stopPropagation();
    const newCheckedState = {
      ...checkedState,
      [groupKey]: {
        checked: !checkedState[groupKey].checked,
        items: Object.keys(checkedState[groupKey].items).reduce((a, itemKey) => {
          a[itemKey] = !checkedState[groupKey].checked;
          return a;
        }, {}),
      },
    };
    setCheckedState(newCheckedState);

    // Filter exploredData
    handleFitlerChange(newCheckedState);
  };
  const handleItemToggle = (groupKey, itemKey, item) => () => {
    console.log("itemXX", item);
    let itemKey_ = itemKey;
    // handle transaction
    if (item.id) {
      itemKey_ = item.id;
    }
    const newCheckedState = {
      ...checkedState,
      [groupKey]: {
        ...checkedState[groupKey],
        items: {
          ...checkedState[groupKey].items,
          [itemKey_]: !checkedState[groupKey].items[itemKey_],
        },
      },
    };

    // Determine if all items are checked or not
    const areAllChecked = Object.values(newCheckedState[groupKey].items).every(Boolean);
    newCheckedState[groupKey].checked = areAllChecked;
    setCheckedState(newCheckedState);

    // Filter exploredData
    handleFitlerChange(newCheckedState);
  };

  //// common utils
  function isAllNotCheck(obj) {
    return Object.values(obj).some((value) => {
      if (typeof value === "object") {
        return isAllNotCheck(value.items);
      }
      return value !== false;
    });
  }

  return (
    <div className={`m-4 flex flex-col h-full ${isCollapsedContainer ? "-ml-2 -mr-2" : ""}`}>
      <div className="sticky top-0 z-10 bg-white">
        {/* <div className="rounded-t-lg"> */}
        <div className={`flex ${isCollapsedContainer ? "flex-col" : "flex-row"} mb-4 justify-between content-center items-center cursor-pointer`} onClick={handleCollapseContainerClick}>
          <h2 className={`transform ${isCollapsedContainer ? "-rotate-90 mb-9 mt-6" : "rotate-0"} text-xl font-bold`}>Filters</h2>
          <ExpandMoreIcon className={`transition-transform ${isCollapsedContainer ? "-rotate-90" : "rotate-90"}`} /> {/* Rotate arrow when collapsed */}
        </div>
      </div>

      {!isCollapsedContainer &&
        checkedState && ( // Only render filters if not collapsed
          <div className="flex-1 overflow-y-auto">
            {sourceFilter && <SourceFilter sourceFilter={sourceFilter && sourceFilter} checkedState={checkedState} handleItemToggle={handleItemToggle} handleGroupToggle={handleGroupToggle} isCollapsed={isCollapsed} handleCollapseClick={handleCollapseClick} />}
            {/* <DateFilter selectedDate={selectedDate} handleDateChange={handleDateChange} /> */}

            <MoreFilter
              isCollapsed={isMoreFilterCollapsed} // Pass isCollapsed as a prop
              checkedState={moreFilterCheckedState}
              handleItemToggle={handleMoreFilterItemToggle}
              handleGroupToggle={handleMoreFilterGroupToggle}
              handleCollapseClick={handleMoreFilterCollapseClick}
              moreFiltersConfig={moreFiltersConfig} // Pass moreFiltersConfig as a prop
            />
          </div>
        )}
    </div>
  );
};

export default Filter;
