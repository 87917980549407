import { combineReducers } from "redux";
import navBarReducer from "./navBarReducer";
import transactionReducer from "./transactionReducer";
import documentsReducer from "./documentsReducer";
import conversationReducer from "./conversationReducer";
import authReducer from "./authReducer";
import keywordReducer from "./keywordReducer";
import exploreReducer from "./exploreReducer";
import snackbarReducer from "./snackBarReducer";
import hypothesisReducer from "./hypothesisReducer";
import templateWorkstreamReducer from "./templateworkstreamReducer";
import templatequestionnaireReducer from "./templatequestionnaireReducer";
import templatequestionReducer from "./templatequestionReducer";
import workstreamReducer from "./workstreamReducer";
import questionnaireReducer from "./questionnaireReducer";
import questionReducer from "./questionReducer";
// import other reducers

export default combineReducers({
  navBar: navBarReducer,
  transactions: transactionReducer,
  documents: documentsReducer,
  conversation: conversationReducer,
  auth: authReducer,
  keywords: keywordReducer,
  explore: exploreReducer,
  snackbar: snackbarReducer,
  hypothesis: hypothesisReducer,
  templateWorkstream: templateWorkstreamReducer,
  templatequestionnaire: templatequestionnaireReducer,
  templatequestion: templatequestionReducer,
  workstream: workstreamReducer,
  questionnaire: questionnaireReducer,
  questions: questionReducer,
  // other reducers
});
