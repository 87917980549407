// actions/questionnaireActions.js
import {
  FETCH_QUESTIONNAIRES_REQUEST,
  FETCH_QUESTIONNAIRES_SUCCESS,
  FETCH_QUESTIONNAIRES_FAILURE,
  CREATE_QUESTIONNAIRE_REQUEST,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_FAILURE,
  UPDATE_QUESTIONNAIRE_REQUEST,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  DELETE_QUESTIONNAIRE_REQUEST,
  DELETE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTIONNAIRE_FAILURE,
  BULK_DELETE_QUESTIONNAIRES_REQUEST,
  BULK_DELETE_QUESTIONNAIRES_SUCCESS,
  BULK_DELETE_QUESTIONNAIRES_FAILURE,
} from "./types";
import { backendClient } from "../../api/backend";

// Fetch questionnaires
export const fetchQuestionnaires = (workstreamId) => async (dispatch) => {
  dispatch({ type: FETCH_QUESTIONNAIRES_REQUEST });
  try {
    const response = await backendClient.get(`api/questionnaire/get_questionnaires/?_workstream_id=${workstreamId}`);

    // Check if the response is okay and parse it as JSON
    if (response.ok) {
      const data = await response.json(); // Convert stream to JSON
      dispatch({ type: FETCH_QUESTIONNAIRES_SUCCESS, payload: data });
    } else {
      // Handle non-ok responses
      dispatch({ type: FETCH_QUESTIONNAIRES_FAILURE, payload: `Error: ${response.statusText}` });
    }
  } catch (error) {
    dispatch({ type: FETCH_QUESTIONNAIRES_FAILURE, payload: error.message });
  }
};

// Create questionnaire
export const createQuestionnaire = (questionnaireData) => async (dispatch) => {
  dispatch({ type: CREATE_QUESTIONNAIRE_REQUEST });
  try {
    const newQuestionnaire = await backendClient.post("/api/questionnaire/create_questionnaire/", questionnaireData);
    dispatch({ type: CREATE_QUESTIONNAIRE_SUCCESS, payload: newQuestionnaire });
  } catch (error) {
    dispatch({ type: CREATE_QUESTIONNAIRE_FAILURE, payload: error.message });
  }
};

// Update questionnaire
export const updateQuestionnaire = (questionnaireId, questionnaireData) => async (dispatch) => {
  dispatch({ type: UPDATE_QUESTIONNAIRE_REQUEST });
  try {
    const updatedQuestionnaire = await backendClient.put(`/api/questionnaire/update_questionnaires/${questionnaireId}`, questionnaireData);
    dispatch({ type: UPDATE_QUESTIONNAIRE_SUCCESS, payload: updatedQuestionnaire });
  } catch (error) {
    dispatch({ type: UPDATE_QUESTIONNAIRE_FAILURE, payload: error.message });
  }
};

// Delete questionnaire
export const deleteQuestionnaire = (questionnaireId) => async (dispatch) => {
  dispatch({ type: DELETE_QUESTIONNAIRE_REQUEST });
  try {
    await backendClient.delete(`/api/questionnaire/delete_questionnaires/${questionnaireId}`);
    dispatch({ type: DELETE_QUESTIONNAIRE_SUCCESS, payload: questionnaireId });
  } catch (error) {
    dispatch({ type: DELETE_QUESTIONNAIRE_FAILURE, payload: error.message });
  }
};

// Bulk delete questionnaires
export const bulkDeleteQuestionnaires = (questionnaireIds) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_QUESTIONNAIRES_REQUEST });
  try {
    await backendClient.delete("/api/questionnaire/bulk_delete/", { data: questionnaireIds });
    dispatch({ type: BULK_DELETE_QUESTIONNAIRES_SUCCESS, payload: questionnaireIds });
  } catch (error) {
    dispatch({ type: BULK_DELETE_QUESTIONNAIRES_FAILURE, payload: error.message });
  }
};
