import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, FormGroup, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MoreFilter = ({ moreFiltersConfig, isCollapsed, moreFilters, handleCollapseClick, handleGroupToggle, handleItemToggle, checkedState }) => {
  return (
    <div className="mb-4 pt-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm">MORE FILTERS</h3>
        <ExpandMoreIcon style={{ color: "gray" }} onClick={handleCollapseClick} className={`transform ${isCollapsed ? "rotate-180" : ""}`} />
      </div>
      <Collapse in={!isCollapsed}>
        {moreFiltersConfig?.length > 0 &&
          moreFiltersConfig.map((group, index) => {
            const groupKey = group.name;
            const options = group.options;
            return (
              <React.Fragment key={groupKey}>
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FormControlLabel label={<span className="text-sm">{groupKey.charAt(0).toUpperCase() + groupKey.slice(1)}</span>} aria-label={groupKey} onClick={handleGroupToggle(groupKey)} control={<Checkbox checked={checkedState && checkedState[groupKey]?.checked} />} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {options.map((option, subIndex) => {
                        const itemKey = option;
                        return <FormControlLabel label={<span className="text-sm">{itemKey}</span>} key={subIndex} control={<Checkbox checked={checkedState && checkedState[groupKey]?.items[itemKey]} onChange={handleItemToggle(groupKey, itemKey)} />} />;
                      })}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            );
          })}
      </Collapse>
    </div>
  );
};

export default MoreFilter;
