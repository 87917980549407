// src/pages/Screen1.js

import React from "react";
// import NavBar from "./components/NavBar";
import SearchBar from "./components/SearchBar";
import Dashboard from "./components/Dashboard";
import { Container } from "@mui/material";

import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";

import { colors } from "../../utils/colors";
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import { ResponsiveBar } from "@nivo/bar";

const DemoScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const companyData = [
    {
      name: "XPeng Inc",
      matchPercentage: "85%",
      country: "China",
      description: "XPeng Inc is a Smart Electric Vehicle company designing, developing, manufacturing, and marketing smart...",
      relevance: [
        { label: "Peer Group", value: 80 },
        { label: "Keyword", value: 60 },
        { label: "Industry", value: 90 },
        { label: "Topic", value: 70 },
        { label: "Context", value: 50 },
      ],
      revenue: "$3.297.2m",
      marketCap: "$8,068.8m",
      ebitda: "-$618.8m",
    },
    // Add more company data as needed
  ];
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }} display={"flex"} flexDirection={"column"}>
        <SearchBar />
        <Dashboard cards={companyData} />
      </Grid>
    </Grid>
  );
};

export default DemoScreen;
