import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Paper, Box, Grid, Stack, Typography, Switch, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import Chat from "./component/Chat";
import DisplayMultiplePdfs from "../../components/pdf-viewer/DisplayMultiplePdfs";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, windowHeight } from "../../utils/utils";
import { faq_chat_answer, faq_chat_question } from "../../utils/constants";
import FAQModal from "../../components/FAQModal";
import { styled } from "@material-ui/core";
import { ChevronLeft, Search } from "lucide-react";
import ConversationModeToggle from "./ConversationModeToggle";
import CardSwitchToggle from "./ConversationModeToggle";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ChatScreen = () => {
  const [conversationMode, setConversationMode] = useState("conversation");
  const { conversationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openFAQModal, setOpenFAQModal] = useState(true);
  const handleModeChange = (newMode) => {
    setConversationMode(newMode);
  };

  const open = useSelector((state) => state.navBar.is_open);
  const conversation = useSelector((state) => state.conversation.conversation);

  const isShowPdf = conversation?.messages.length > 0;
  const parentScreen = location.state?.parent_screen;

  // Check local storage to see if the modal should be shown
  useEffect(() => {
    const showModal = localStorage.getItem("showFAQModal_chat") !== "false";
    setOpenFAQModal(showModal);
  }, []);

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleBackNavigation = () => {
    if (parentScreen) {
      navigate(parentScreen);
    } else {
      navigate(-1);
    }
  };

  return (
    <PdfFocusProvider>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", width: "90vw" }}>
        <Box sx={{ display: "flex", alignItems: "center", p: 2, borderBottom: 1, borderColor: "divider" }}>
          <Box sx={{ marginLeft: { xs: 0, md: open ? "260px" : "95px" } }}>
            <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <h2 className="text-xl font-bold text-gray-800">Conversation</h2>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" width={"100%"}>
              <Box sx={{ display: "flex", width: "100%", height: "70px", alignItems: "center" }}>
                <Button
                  startIcon={<ChevronLeft color="black" />}
                  onClick={handleBackNavigation}
                  sx={{
                    mt: 2,
                    mb: 2,
                    ml: 0,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Back
                </Button>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      placeholder="Search in Conversation..."
                      fullWidth
                      value={searchText}
                      onChange={handleTextSearchChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "28px",
                          backgroundColor: "#F5F5F7",
                          "&:hover": {
                            backgroundColor: "#E8E8E8",
                          },
                          "&.Mui-focused": {
                            backgroundColor: "#FFFFFF",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "primary.main",
                            },
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "14px 14px 14px 0",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton sx={{ p: "10px" }}>
                              <Search size={20} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {/* CardSwitchToggle component */}
                    <CardSwitchToggle mode={conversationMode} onModeChange={handleModeChange} />

                    {/* Button next to the CardSwitchToggle */}
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#000", // Black color
                        color: "#fff", // White text color
                        ml: 2, // Add some margin to the left
                        borderRadius: "8px", // Rounded corners
                        "&:hover": {
                          backgroundColor: "#000", // Darker shade on hover
                        },
                      }}
                    >
                      Select Documents
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Grid item xs={12} md={11} lg={11}>
              <Grid sx={{ p: 2, display: "flex", alignItems: "center", bgcolor: "gray.100", height: "100%" }}>
                <Grid item xs={12} md={isShowPdf ? 6 : 12} style={{ height: "95vh" }}>
                  <Chat conversationId={conversationId} is_show_pdf={isShowPdf} />
                </Grid>

                <FAQModal type="chat" question={faq_chat_question} answer={faq_chat_answer} open={openFAQModal} setOpen={setOpenFAQModal} />

                {isShowPdf && !isMobile() && (
                  <Grid item xs={12} md={open ? 9 : 11} sx={{ height: "95vh" }}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <DisplayMultiplePdfs is_conversation={true} documentsIds={conversation.documentsIds} pdfWidth={open ? 260 : 90} pdfHeight={75} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </PdfFocusProvider>
  );
};

export default ChatScreen;
