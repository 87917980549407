// reducers/questionnaireReducer.js
import {
  FETCH_QUESTIONNAIRES_REQUEST,
  FETCH_QUESTIONNAIRES_SUCCESS,
  FETCH_QUESTIONNAIRES_FAILURE,
  CREATE_QUESTIONNAIRE_REQUEST,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_FAILURE,
  UPDATE_QUESTIONNAIRE_REQUEST,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  DELETE_QUESTIONNAIRE_REQUEST,
  DELETE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTIONNAIRE_FAILURE,
  BULK_DELETE_QUESTIONNAIRES_REQUEST,
  BULK_DELETE_QUESTIONNAIRES_SUCCESS,
  BULK_DELETE_QUESTIONNAIRES_FAILURE,
} from "../actions/types";

const initialState = {
  questionnaires: [],
  isLoading: false,
  error: null,
};

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTIONNAIRES_REQUEST:
    case CREATE_QUESTIONNAIRE_REQUEST:
    case UPDATE_QUESTIONNAIRE_REQUEST:
    case DELETE_QUESTIONNAIRE_REQUEST:
    case BULK_DELETE_QUESTIONNAIRES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        questionnaires: action.payload,
        isLoading: false,
      };
    case FETCH_QUESTIONNAIRES_FAILURE:
    case CREATE_QUESTIONNAIRE_FAILURE:
    case UPDATE_QUESTIONNAIRE_FAILURE:
    case DELETE_QUESTIONNAIRE_FAILURE:
    case BULK_DELETE_QUESTIONNAIRES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case CREATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaires: [...state.questionnaires, action.payload],
        isLoading: false,
      };
    case UPDATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaires: state.questionnaires.map((q) => (q.id === action.payload.id ? action.payload : q)),
        isLoading: false,
      };
    case DELETE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaires: state.questionnaires.filter((q) => q.id !== action.payload),
        isLoading: false,
      };
    case BULK_DELETE_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        questionnaires: state.questionnaires.filter((q) => !action.payload.includes(q.id)),
        isLoading: false,
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
