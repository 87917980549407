import { Button, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import { countries, industries } from "../api/utils/dataModel";

const FilterDialog = ({ open, handleClose, applyFilters, initialFilters }) => {
  console.log(initialFilters);
  const [status, setStatus] = useState(initialFilters.status || "");
  const [region, setRegion] = useState(initialFilters.region || "");
  const [minValue, setMinValue] = useState(initialFilters.minValue || "");
  const [maxValue, setMaxValue] = useState(initialFilters.maxValue || "");
  const [industry, setIndustry] = useState(initialFilters.industry || "");
  const [showCompleted, setShowCompleted] = useState(initialFilters.showCompleted || false);

  useEffect(() => {
    setStatus(initialFilters.status || "");
    setRegion(initialFilters.region || "");
    setMinValue(initialFilters.minValue || "");
    setMaxValue(initialFilters.maxValue || "");
    setIndustry(initialFilters.industry || "");
    setShowCompleted(initialFilters.showCompleted || false);
  }, [initialFilters]);

  const handleApply = () => {
    applyFilters({ status, region, industry, minValue, maxValue, showCompleted });
    handleClose();
  };

  const handleClear = () => {
    setStatus("");
    setRegion("");
    setMinValue("");
    setMaxValue("");
    setIndustry("");
    setShowCompleted(false);
    applyFilters({ status: "", region: "", industry: "", minValue: "", maxValue: "", showCompleted: false });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "sm",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Filter Deals
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Apply filters to refine your search results
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel id="industry">Industry</InputLabel>
          <Select labelId="industry" value={industry} onChange={(e) => setIndustry(e.target.value)}>
            {industries.map((industry, i) => (
              <MenuItem key={i} value={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="bidding">Bidding</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="kill">Kill</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel>Region</InputLabel>
          <Select value={region} onChange={(e) => setRegion(e.target.value)}>
            <MenuItem value="na">North America</MenuItem>
            <MenuItem value="eu">Europe</MenuItem>
            <MenuItem value="asia">Asia</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth margin="normal" label="Min Value" type="number" value={minValue} onChange={(e) => setMinValue(e.target.value)} sx={{ bgcolor: "#f7f7f7" }} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth margin="normal" label="Max Value" type="number" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} sx={{ bgcolor: "#f7f7f7" }} />
          </Grid>
        </Grid>
        <FormControlLabel control={<Checkbox checked={showCompleted} onChange={(e) => setShowCompleted(e.target.checked)} />} label="Remember the filters" />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
        <Button
          onClick={handleClear}
          sx={{
            color: "black",
            borderRadius: "6px",
            fontSize: 14,
            padding: "10px 20px",
            width: "160px",
            height: "40px",
          }}
        >
          Clear Filters
        </Button>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={handleClose}
            sx={{
              color: "black",
              borderRadius: "6px",
              fontSize: 14,
              padding: "10px 20px",
              width: "160px",
              height: "40px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              borderRadius: "6px",
              "&:hover": {
                bgcolor: "black",
                color: "white",
              },
              width: "160px",
              height: "40px",
              fontSize: 14,
              padding: "10px 20px",
            }}
          >
            Apply Filters
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
