import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const CustomProgressBar = ({ value, width = 400, height = 18, color = "#1AB5B8", backgroundColor = "lightgrey", textColor = "white", hasBorder = false, borderColor = "#000" }) => {
  return (
    <Box sx={{ position: "relative", width, height }}>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: height / 2,
          backgroundColor,
          border: hasBorder ? `1px solid ${borderColor}` : "none",
          "& .MuiLinearProgress-bar": {
            borderRadius: height / 2,
            backgroundColor: color,
          },
        }}
      />
      <Typography
        variant="body2"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: textColor,
          fontWeight: "bold",
          fontSize: `${height * 0.6}px`,
          lineHeight: 1,
        }}
      >
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  );
};

export default CustomProgressBar;
