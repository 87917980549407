// src/components/CompanyCard.js

import React from "react";
import { Box, Typography, Paper, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CompanyCardContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "10px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: "#ffffff",
  maxWidth: "300px",
}));

const CompanyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: "#333",
}));

const MatchTag = styled(Box)(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#ffffff",
  backgroundColor: "#c7e6a3",
  padding: "2px 6px",
  borderRadius: "5px",
  marginTop: theme.spacing(1),
}));

const CountryTag = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#ff3333",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#333",
  marginTop: theme.spacing(1),
}));

const BarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const BarLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "#666",
  flex: 1,
}));

const Bar = styled(Box)(({ theme }) => ({
  height: "8px",
  backgroundColor: "#d1a8e4",
  borderRadius: "4px",
  width: "100%",
  overflow: "hidden",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: (props) => `${props.width}%`,
    background: "linear-gradient(90deg, #d1a8e4, #e3c4f0)",
  },
}));

const FinancialDataContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(1),
}));

const FinancialDataLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "#666",
  fontWeight: "bold",
}));

const FinancialDataValue = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "#666",
}));

const AddToListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: "#000",
  cursor: "pointer",
  fontSize: "0.75rem",
}));

const CompanyDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "#333",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
const DividerComponent = () => {
  return <Divider orientation="vertical" variant="middle" flexItem />;
};
const CompanyCard = ({ company }) => {
  return (
    <CompanyCardContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <CompanyTitle>{company.name}</CompanyTitle>
          <MatchTag>{company.matchPercentage} match</MatchTag>
        </Box>
        <AddToListContainer>
          <AddCircleOutlineIcon />
          <Typography variant="body2">Add to list</Typography>
        </AddToListContainer>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
        <CountryTag>{company.country}</CountryTag>
      </Box>
      <CompanyDescription variant="body2" mt={1}>
        {company.description}
      </CompanyDescription>
      <Divider sx={{ my: 1 }} />
      <SectionTitle>Why this company is relevant:</SectionTitle>
      {company.relevance.map((item, index) => (
        <BarContainer key={index}>
          <BarLabel>{item.label}</BarLabel>
          <Bar width={item.value} />
        </BarContainer>
      ))}
      <Divider sx={{ my: 1 }} />
      <FinancialDataContainer>
        <Box>
          <FinancialDataLabel>Revenue:</FinancialDataLabel>
          <FinancialDataValue>{company.revenue}</FinancialDataValue>
        </Box>
        <DividerComponent />

        <Box>
          <FinancialDataLabel>MarketCap:</FinancialDataLabel>
          <FinancialDataValue>{company.marketCap}</FinancialDataValue>
        </Box>
        <DividerComponent />
        <Box>
          <FinancialDataLabel>EBITDA:</FinancialDataLabel>
          <FinancialDataValue>{company.ebitda}</FinancialDataValue>
        </Box>
      </FinancialDataContainer>
    </CompanyCardContainer>
  );
};

export default CompanyCard;
