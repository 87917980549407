import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Box, Grid, CircularProgress } from "@mui/material";
import Filter from "./components/Filter/Filter";
import DocumentsList from "./components/DocumentsList";
import BackButton from "../../components/BackButton";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import DisplayMultiplePdfs from "../../components/pdf-viewer/DisplayMultiplePdfs";
import { useDispatch, useSelector } from "react-redux";
import { createConversation } from "../../redux/actions/conversationAction";
import { useNavigate } from "react-router-dom";
import { addDocumentToDocumentsWithTransactions, clearDocuments, fetchDocument, removeDocumentFromDocuments, setDocumentsTransactionId } from "../../redux/actions/documentsAction";
import { removeTransactionDocumentId, updateTransactionDocumentsIds } from "../../redux/actions/transactionAction";
import { useSnackbar } from "notistack";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api_subscription_key, backendUrl } from "../../config";
import { store } from "../../redux/store";
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import { colors } from "../../utils/colors";
import { extractHypothesis, fetchHypothesis } from "../../redux/actions/hypothesisAction";
import { faq_explore_answer, faq_explore_question } from "../../utils/constants";
import FAQModal from "../../components/FAQModal";
import { set } from "zod";

const ExploreScreen = () => {
  const navigate = useNavigate();

  // REDUX
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const transaction = useSelector((state) => state.transactions.transaction);
  const transactions = useSelector((state) => state.transactions.transactions);
  const document = useSelector((state) => state.documents.document);
  const user_id = useSelector((state) => state.auth.user?.id);

  // STATES
  const [uploadMessage, setUploadMessage] = useState("Uploading documents...");
  const [isCollapsedContainer, setIsCollapsedContainer] = useState(false); // State to manage collapse
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [isStartConversationLoading, setIsStartConversationLoading] = useState(false);
  const documents = useSelector((state) => state.documents.documents);

  const { enqueueSnackbar } = useSnackbar();

  const [isUploadingDocuments, setIsUploadingDocuments] = useState(true);
  const [refreshFilter, setRefreshFilter] = useState(null);

  const [numberOfDocumentsUploaded, setNumberOfDocumentsUploaded] = useState(0);
  const [isLoadingExtractHypothesis, setIsLoadingExtractHypothesis] = useState(false);
  const [openFAQModel, setOpenFAQModel] = useState(true);

  const totalDocuments = transactions.reduce((total, transaction) => total + transaction.upload_files, 0);
  console.log("totalDocuments", totalDocuments);

  useEffect(() => {
    console.log("Uploading documents");
    console.log(transactions);
    let counter = 0;
    transactions.forEach((transaction_) => {
      if (transaction_ && !transaction_.document_uploaded) {
        setIsUploadingDocuments(true);
        console.log("Transaction not uploaded");

        const url = `${backendUrl}api/document/events_upload_files/?session_id=${transaction_.id}`;
        const eventSource = new EventSource(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.accessToken}`,
            "Ocp-Apim-Subscription-Key": api_subscription_key,
          },
        });

        eventSource.onmessage = async (event) => {
          console.log("EVENT.DATA", event.data);
          let parsedData;
          try {
            parsedData = JSON.parse(event.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            parsedData = null;
          }

          if (parsedData) {
            console.log("parsedData", parsedData);
            if (parsedData.status === "data") {
              console.log("EVENTSOURCE data status...");
              console.log("parsedData second", parsedData);
              await dispatch(updateTransactionDocumentsIds(transaction_.id, parsedData.data.id));
              await dispatch(addDocumentToDocumentsWithTransactions(parsedData.data, transaction_.id));
              setUploadMessage(`Document ${parsedData.data.name} uploaded!`);
              setNumberOfDocumentsUploaded((prev) => prev + 1);
            } else if (parsedData.status === "complete") {
              console.log("EVENTSOURCE closing connection complete...");
              eventSource.close();
              setIsUploadingDocuments(false);
            } else if (parsedData.status !== "ping" && parsedData.status !== "data") {
              console.log("EVENTSOURCE setting status...");
              setUploadMessage(parsedData.status);
            }
          }
        };

        eventSource.onerror = (error) => {
          console.log("EventSource failed:", error);
          // eventSource.close();
          // setIsUploadingDocuments(false);
        };

        return () => {
          console.log("Cleaning up EventSource connection");
          if (eventSource) {
            console.log("OK - Cleaning up EventSource connection");
            eventSource.close();
          }
        };
      } else {
        console.log("Transaction already uploaded or not present");
        setNumberOfDocumentsUploaded((prev) => prev + 1);
        counter += 1;
        // setIsUploadingDocuments(false);
      }
    });
    if (counter === transactions.length) {
      setIsUploadingDocuments(false);
    }
  }, [transactions, dispatch]);

  // FUNCTIONS
  const handleCollapseContainerClick = () => {
    setIsCollapsedContainer(!isCollapsedContainer); // Toggle collapse state
  };

  const handleExtractHypothesis = async () => {
    if (isLoadingExtractHypothesis || isStartConversationLoading || isUploadingDocuments) {
      return;
    }
    setIsLoadingExtractHypothesis(true);
    // await dispatch(extractHypothesis([transaction.id], user_id));
    let transactionsIds_documentsIds = [];
    for (const transaction of transactions) {
      console.log("transactionXX", transaction);
      if (transaction.is_extracted === "True" || transaction.is_extracted === "Pending") {
        await dispatch(fetchHypothesis(transaction.id));
      } else {
        await dispatch(extractHypothesis([transaction.id], user_id));
      }
      transactionsIds_documentsIds.push({ transaction_id: transaction.id, documents_ids: transaction.documents_ids });
    }
    console.log("transactionsIds_documentsIds", transactionsIds_documentsIds);
    navigate(`/deals/hypothesis`, { state: { transactionsIds_documentsIds } });
    setIsLoadingExtractHypothesis(false);
  };

  const handleStartConversation = async () => {
    if (isLoadingExtractHypothesis || isStartConversationLoading || isUploadingDocuments) {
      return;
    }
    setIsStartConversationLoading(true);

    let transactionId_documentsIds;
    // if selected quotes, get documents
    if (selectedQuotes.length > 0) {
      const grouped = {};
      // Iterate over each quote
      selectedQuotes.forEach((quote) => {
        const transactionId = quote.metadata.db_transaction_id;
        const documentId = quote.metadata.db_document_id;
        // If the transaction ID is not yet in the grouped object, initialize it
        if (!grouped[transactionId]) {
          grouped[transactionId] = {
            transaction_id: transactionId,
            documents_ids: [],
          };
        }
        // Add the document ID to the transaction's list of documents
        // Check to avoid duplicate document IDs
        if (!grouped[transactionId].documents_ids.includes(documentId)) {
          grouped[transactionId].documents_ids.push(documentId);
        }
      });
      transactionId_documentsIds = Object.values(grouped);
    } else {
      transactionId_documentsIds = transactions.map((x) => {
        return { transaction_id: x.id, documents_ids: x.documents_ids };
      });
    }

    // clear documents
    console.log("cleaningDOC");
    await dispatch(clearDocuments());

    // Create conveastion
    const conversationId_ = await dispatch(createConversation(transactionId_documentsIds, user_id));
    setIsStartConversationLoading(false);
    navigate(`/chat/${conversationId_}`, { state: { parent_scree: "explore" } });
    enqueueSnackbar("Conversation started!", { variant: "success" });
  };

  useEffect(() => {
    const fetchDocument_fn = async () => {
      await dispatch(fetchDocument(selectedQuotes[0].metadata.db_document_id));
    };
    // second condition if to check if selected quote documents is in redux store
    if (selectedQuotes.length > 0 && selectedQuotes[selectedQuotes.length - 1].metadata.db_document_id !== document?.id) {
      fetchDocument_fn();
    }
  }, [selectedQuotes]);

  const handleRemoveDocument = async (document) => {
    if (isLoadingExtractHypothesis || isStartConversationLoading || isUploadingDocuments) {
      return;
    }
    const documentId = document.id;
    // update transactions
    await dispatch(removeTransactionDocumentId(documentId));
    // update documents
    await dispatch(removeDocumentFromDocuments(documentId));
  };

  const hiddenScrollbarStyle = {
    "&::-webkit-scrollbar": {
      display: "none", // For Webkit browsers
    },
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // Corrected to camelCase for Internet Explorer 10+
  };

  // Check local storage to see if the modal should be shown
  useEffect(() => {
    const showModal = localStorage.getItem("showFAQModal_explore") !== "false";
    setOpenFAQModel(showModal);
  }, []);

  return (
    <PdfFocusProvider>
      <Grid container sx={{ height: "100vh" }}>
        <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
          <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
            <Grid sx={{ p: 2, backgroundColor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
              {/* HEADER */}
              <Box className="flex justify-between items-center mb-0 border-b pb-4">
                <Box sx={{ display: "flex", alignItems: "center", gap: isMobile() ? 0 : 2 }}>
                  <BackButton />
                  <h2 className="text-xl font-bold text-gray-800">{transactions.length === 1 ? "Explore Transaction" : "Explore Transactions"}</h2>
                </Box>

                <FAQModal type={"explore"} question={faq_explore_question} answer={faq_explore_answer} open={openFAQModel} setOpen={setOpenFAQModel} />

                {!isMobile() && (
                  <Box sx={{}}>
                    <Button sx={{ marginRight: windowWidth / 820, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} variant="contained" disabled={isUploadingDocuments || isLoadingExtractHypothesis} color="primary" onClick={handleExtractHypothesis}>
                      {isLoadingExtractHypothesis ? "Extracting hypothesis..." : "Extract Hypothesis"}
                    </Button>
                    <Button sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} disabled={isUploadingDocuments || isStartConversationLoading} variant="contained" color="primary" onClick={handleStartConversation}>
                      {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                    </Button>
                  </Box>
                )}
              </Box>

              {isMobile() && (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: isMobile() ? windowHeight / 300 : 0 }}>
                  <Button sx={{ fontSize: "0.75rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} variant="contained" disabled={isUploadingDocuments || isLoadingExtractHypothesis} color="primary" onClick={handleExtractHypothesis}>
                    {isLoadingExtractHypothesis ? "Extracting hypothesis..." : "Extract Hypothesis"}
                  </Button>
                  <Button style={{ fontSize: "0.75rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} variant="contained" disabled={isUploadingDocuments || isStartConversationLoading} color="primary" onClick={handleStartConversation}>
                    {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                  </Button>
                </Box>
              )}

              <Box className="flex" sx={{ paddingTop: 2, overflow: "auto", ...hiddenScrollbarStyle }}>
                {/* FILTER */}

                {!isMobile() && (
                  <Box sx={{ flexGrow: 0, flexShrink: 0, flexBasis: isCollapsedContainer ? "3%" : "20%", overflow: "auto", ...hiddenScrollbarStyle }} className="pr-2 h-[74vh]">
                    <Filter refresh={refreshFilter} isCollapsedContainer={isCollapsedContainer} handleCollapseContainerClick={handleCollapseContainerClick} />
                  </Box>
                )}

                {!isMobile() && <Divider orientation="vertical" flexItem />}

                {/* DOCUMENTS SELECTION */}
                {!isMobile() ? (
                  <Box sx={{ flexGrow: 0, flexShrink: 0, marginTop: -2, flexBasis: isCollapsedContainer ? "45%" : "28%", overflow: "auto", ...hiddenScrollbarStyle }} className="px-3 h-[83vh] w-[390px]">
                    <DocumentsList isUploadingDocuments={isUploadingDocuments} selectedQuotes={selectedQuotes} setSelectedQuotes={setSelectedQuotes} />
                  </Box>
                ) : (
                  <Box sx={{ flexGrow: 0, flexShrink: 0, overflow: "auto", ...hiddenScrollbarStyle }} className="h-[100%] w-full">
                    <DocumentsList isUploadingDocuments={isUploadingDocuments} selectedQuotes={selectedQuotes} setSelectedQuotes={setSelectedQuotes} />
                  </Box>
                )}

                {!isMobile() && <Divider orientation="vertical" flexItem />}

                {/* DOCUMENTS VIEW */}
                {!isMobile() && (
                  <Box sx={{ flexGrow: 0, flexShrink: 0, marginTop: -2, flexBasis: isCollapsedContainer ? "51.8%" : open ? "51.8%" : "51.8%", overflow: "auto", ...hiddenScrollbarStyle }} className=" h-[83vh]">
                    {/* {documentsIds && documentsIds.length > 0 ? ( */}
                    {!isUploadingDocuments ? (
                      <DisplayMultiplePdfs isUploadingDocuments={isUploadingDocuments} handleRemoveDocument={handleRemoveDocument} pdfWidth={open ? 250 : 60} pdfHeight={160} />
                    ) : (
                      <Box className="flex flex-col justify-center items-center h-[72vh]">
                        {/* Document count text */}
                        <p className="text-gray-600 font-bold text-sm mb-4">
                          Document {numberOfDocumentsUploaded}/{totalDocuments}
                        </p>

                        {/* Progress spinner and message */}
                        <div className="flex flex-col items-center">
                          <CircularProgress size={24} />
                          <p className="text-gray-600 font-light text-sm mt-4">{"🤖 " + uploadMessage}</p>
                        </div>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PdfFocusProvider>
  );
};

export default ExploreScreen;
