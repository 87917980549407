import { useState } from "react";
import AllDeals from "./AllDeals.js";
//import DealDetails from "./DealDetails.js";
import DueDiligence from "./DueDiligence.js";
import DealKpi from "./DealKpi.js";
import Diligence from "./Diligence.js";
import { useLocation } from "react-router-dom";

const DealsPage = () => {
  const location = useLocation();
  const [view, setView] = useState(location.state?.view || "DEALS");
  const [savedTransactions, setSavedTransactions] = useState(location.state?.transaction ? location.state?.transaction : []);
  const [savedWorkstream, setSavedWorkstream] = useState([]);
  const handleSetView = (value) => {
    if (value === "DEALS" || value === "DETAILS" || value === "DUE" || value === "KPI") {
      setView(value);
    }
  };

  const handleTransactions = (transactions) => {
    setSavedTransactions(transactions);
  };
  const handleworkstream = (workstream) => {
    setSavedWorkstream(workstream);
  };
  return (
    <>
      {view === "DEALS" && <AllDeals setView={handleSetView} saveTransactions={handleTransactions} />}
      {view === "DETAILS" && <Diligence setView={handleSetView} transaction={savedTransactions} savedWorkstream={savedWorkstream} setworkstream={handleworkstream} />}
      {view === "DUE" && <DueDiligence setView={handleSetView} transaction={savedTransactions} saveWorkstream={handleworkstream} />}
      {view === "KPI" && <DealKpi setView={handleSetView} transaction={savedTransactions} />}
    </>
    // </div>
  );
};
export default DealsPage;
