import React from "react";
import { Navigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";

// REDUX
import { useDispatch, useSelector } from "react-redux";
// import { signInWithEmailLink_ } from "../../redux/actions/authActions";

// NAVIGATE
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { logOut } from "../../../redux/actions/authAction";
import { windowHeight } from "../../../utils/utils";

const LogOut = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const dispatch = useDispatch();

  const handleLogout = async () => {
    await instance
      .logoutPopup()
      .then(() => {
        dispatch(logOut());
      })
      .catch((e) => {
        console.log("error: ", e);
      });
    navigate("/sign-in");
  };

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      {/* <Grid item sx={{ borderBottom: 1, borderColor: "black" }} /> */}

      <Grid item container direction="column" alignItems="center" justifyContent="center" sx={{ mt: windowHeight / 50 }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ fontFamily: "Poppins", fontWeight: "600" }}>
          Are you sure you want to disconnect?
        </Typography>
        <Typography color="text.secondary" align="center" sx={{ mt: 2 }}>
          Looking forward to welcoming you back in the future.
        </Typography>
        <Grid item sx={{ mt: 6 }}>
          <Button onClick={handleLogout} variant="contained" sx={{ bgcolor: "#000", fontWeight: "600", width: "200px",height:"50px","&:hover": {backgroundColor: "black",  }, }}>
            Disconnect
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogOut;
