import { Box, CircularProgress } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Button, Card, Chip, Tooltip, Collapse, Divider, Grid, List, ListItemButton, IconButton, ListItemText, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Check, ChevronLeft, Trash, Edit } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiligenceQuestionsComponent from "./dealscomponents/DiligenceQuestion";
import CustomProgressBar from "./dealscomponents/CustomProgressBar";
import { colors } from "../../utils/colors";
import CustomButton from "../../components/CustomButton";
import AddTaskDialog from "./dealscomponents/AddTaskDialog"; // Import the dialog component
import { useNavigate } from "react-router-dom";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { useSnackbar } from "notistack";
// import { followupquestions } from "../../redux/actions/questionAction";
import Documentstart from "../../components/Documentstart";
import EditQuestionDialog from "./dealscomponents/EditQuestionDialog"; // Importing Edit Dialog
import DeleteConfirmModal from "../../components/DeleteConfirmModal"; // Import Delete Confirm Modal
import { fetchQuestions, createQuestion, updateQuestion, deleteQuestion, followupquestions } from "../../redux/actions/questionAction";
import { fetchWorkstreams } from "../../redux/actions/workstreamAction";
import { backendClient } from "../../api/backend";

const MaxChars = ({ text, limit = 25 }) => {
  if (!text) return;
  const truncatedText = text.length > limit ? text.slice(0, limit - 3) + "..." : text;

  return <span>{truncatedText}</span>;
};

const Diligence = ({ setView, transaction, savedWorkstream, setworkstream }) => {
  const navigate = useNavigate();
  const open = useSelector((state) => state.navBar.is_open);
  const [openList, setOpenList] = useState(false);
  const [dynamicPercentage, setDynamicPercentage] = useState(0);

  const [openCard, setOpenCard] = useState(null);
  console.log(savedWorkstream);
  const [totalQuestions, setTotalQuestions] = useState(0);

  const [openTaskDialog, setOpenTaskDialog] = useState(false); // Task dialog state

  const [openEditDialog, setOpenEditDialog] = useState(false); // Edit dialog state
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false); // Delete confirmation modal state
  const [selectedQuestion, setSelectedQuestion] = useState(null); // Track selected question for edit/delete

  // Extracting the questions from the savedWorkstream
  const questionnaires = savedWorkstream?.questionnaires || [];
  const questions = questionnaires.length > 0 ? questionnaires[0]?.questions || [] : [];
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [denomBeg, setDenomBeg] = useState(questions.length);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingStates, setLoadingStates] = useState({});
  const { questions: realquestions = [], isLoading = false, error = "" } = useSelector((state) => state.questions);
  const { workstreams = [] } = useSelector((state) => state.workstreams) || [];
  const [selectedCards, setSelectedCards] = useState(
    questions.map((q) => {
      console.log(q.user_validated);
      return q.user_validated;
    }),
  );
  console.log("selected cards", selectedCards);
  console.log("question questions", questions);

  // useEffect(() => {
  //   setSelectedCards(Array(questions.length).fill(false));
  // }, [questions]);

  const [cards, setCards] = useState([]);

  // upload data to backend
  const handleUpload = () => {
    console.log("Data uploaded to backend");
  };

  const handleSeeMore = (index) => {
    setOpenCard(openCard === index ? null : index);
  };

  const handleClick = () => {
    setOpenList(!openList);
  };

  useEffect(() => {
    setTotalQuestions(questions.length);
  }, [questions]);

  // Handler for Add Task dialog
  const handleAddTaskOpen = () => setOpenTaskDialog(true);
  const handleAddTaskClose = () => setOpenTaskDialog(false);

  const handleAddTask = (newTask) => {
    console.log("New task added:", newTask);
    // Add the new task logic
    handleAddTaskClose();
  };

  const handleBackClick = () => {
    console.log("Card clicked");
    setView("DUE");
  };

  const handleuservalidation = () => {
    console.log("user validated");
    dispatch(followupquestions);
  };

  useEffect(() => {
    const selectedCount = selectedCards.filter((e) => e).length;
    const newPercentage = (selectedCount / questions.length) * 100;
    setProgressPercentage(newPercentage);
  }, [selectedCards]);

  const handleAddQuestion = (question) => {
    const newCard = {
      title: question.title,
      redFlags: question.red_flags,
      confidence: question.confidence_score,
      status: question.status,
      userInput: question.user_validated,
    };
    setCards([...cards, newCard]);
    setSelectedCards([...selectedCards, false]);
    setTotalQuestions((prevCount) => prevCount + 1);
  };

  let denom;
  const handleUpdateCardCount = (count) => {
    denom = count + denomBeg; // Calculate denom once

    const calculatedPercentage = totalQuestions > 0 ? (totalQuestions / denom) * 100 : 0;
    console.log("total:", totalQuestions);
    console.log("count", count);
    console.log("denom", denom);

    setDynamicPercentage(calculatedPercentage);
  };

  const handleEditQuestionOpen = (question) => {
    setSelectedQuestion(question);
    setOpenEditDialog(true);
  };

  const handleEditQuestionClose = () => setOpenEditDialog(false);

  const handleDeleteConfirmOpen = (question) => {
    setSelectedQuestion(question);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirmClose = () => setOpenDeleteConfirm(false);

  const handleDeleteQuestion = () => {
    console.log("Deleting question:", selectedQuestion);
    // Add logic to delete the question
    handleDeleteConfirmClose();
  };

  // Updated: Handle loading per conversation
  const handleNavigateToConversation = async (conversation_id, transaction_id) => {
    setLoadingStates((prevState) => ({ ...prevState, [conversation_id]: true }));
    await dispatch(clearDocuments());

    navigate(`/chat/${conversation_id}`);
    setLoadingStates((prevState) => ({ ...prevState, [conversation_id]: false }));
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  const handleCardClick = (index, questionId) => {
    // Toggle the selected card state
    console.log("updating the question");
    const newSelectedCards = [...selectedCards];
    newSelectedCards[index] = !newSelectedCards[index];
    setSelectedCards(newSelectedCards);

    // Update the question when the card is clicked
    const updatedData = {
      user_validated: newSelectedCards[index],
    };
    handleUpdateQuestion(questionId, updatedData);
  };

  const handleUpdateQuestion = async (questionId, updatedData) => {
    try {
      console.log("updating the question");
      await dispatch(updateQuestion(questionId, updatedData));
      // console.log("updated question", realquestions.filter((e) => e.id === questionId)[0]);
      // Fetch updated workstreams and only update state if it differs
      const fetches = await backendClient.fetchWorkstream(savedWorkstream.transaction_id, 0, 100);
      console.log("fetched workstreams", fetches);
      // Compare the fetched workstream with the existing one to prevent unnecessary state updates
      const fetched = fetches.filter((e) => e.id === savedWorkstream.id)[0];
      setworkstream(fetched);
      // if (JSON.stringify(fetchedWorkstream) !== JSON.stringify(savedWorkstream)) {
      //   setworkstream(fetchedWorkstream);
      // }

      enqueueSnackbar("Question updated successfully!", { variant: "success" }); // Success notification
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to update the question!", { variant: "error" }); // Error notification
    }
  };

  const renderCard1 = (question, index) => (
    <Card key={index} sx={{ width: "100%", bgcolor: "#f5f5f5", borderRadius: "10px", padding: "20px", mb: 2 }}>
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Tooltip title={question.title}>
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#000", fontSize: "1rem" }}>
              <MaxChars text={question.title} />
            </Typography>
          </Grid>
        </Tooltip>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="Edit Question" arrow>
            <IconButton disabled={true} onClick={() => handleEditQuestionOpen(question)}>
              <Edit size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Question" arrow>
            <IconButton disabled={true} onClick={() => handleDeleteConfirmOpen(question)}>
              <Trash size={20} color="red" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Open this question in conversation" arrow>
            <Button variant="contained" fullWidth sx={{ bgcolor: "#000", fontWeight: "bold" }} onClick={() => handleNavigateToConversation(question.conversation_id, transaction.id)}>
              {loadingStates[question.conversation_id] ? (
                <>
                  <CircularProgress size={24} />
                  &nbsp; &nbsp;
                  {" Opening..."}
                </>
              ) : (
                "Open conversation"
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );

  const RenderStatusColumn = (title, items, itemSpacing = 4, marginTop = 0, marginBottom = 0, marginLeft = 0, cardPadding = "28px") => {
    const [user, setUser] = useState(false);

    useEffect(() => {
      if (title === "Red Flags") {
        setUser(true);
      }
    }, [title]);

    return (
      <Stack direction="column" spacing={itemSpacing} sx={{ mt: marginTop, mb: marginBottom, ml: marginLeft }}>
        <Typography variant="h7" sx={{ mt: 0, mb: 0, fontWeight: "bold", color: "#000", textAlign: "center" }}>
          {title}
        </Typography>
        <Card
          sx={{
            width: "100%",
            height: "90%",
            bgcolor: "#f5f5f5",
            borderRadius: "10px",
            padding: cardPadding,
            paddingTop: "20px",
            display: "flex", // Ensure the card takes the full height and uses flexbox
            flexDirection: "column", // Stack elements vertically
            justifyContent: "flex-start", // Align items to the top
          }}
        >
          <Stack spacing={user ? 7.5 : 7.5} alignItems="center" sx={{ mt: user ? 0.2 : 0.2, width: "100%" }}>
            {items.map((item, index) => (
              <div padding="0" mb={0} key={index}>
                {item}
              </div>
            ))}
          </Stack>
        </Card>
      </Stack>
    );
  };

  return (
    <Documentstart
      title={`${transaction.name} - ${savedWorkstream?.name}`}
      open={open}
      headerProps={{
        showSearchBar: false,
        showFilter: false,
        showView: false,
        backbutton: true,
        handleBackClick: handleBackClick,
        custombutton: true,
        custombuttontext: "Create Document",
        custombuttononclick: () => {},
        sx: { margin: "0" },
        // startIcon: <Plus size="22px" />,
        // hoverBackgroundColor: "",
        // variant: "contained",
      }}
    >
      <Stack
        spacing={1}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          // marginTop: 2,
          marginBottom: 4,
        }}
      >
        <Stack direction={"row"} spacing={4} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="h5"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              marginLeft: 2,
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Progress
          </Typography>{" "}
          <Box display="flex" alignItems="center">
            <Box sx={{ width: "100%", mr: 1, position: "relative" }}>
              <CustomProgressBar value={progressPercentage} borderColor="#bfbdbd" hasBorder={"true"} />
            </Box>
          </Box>
        </Stack>
        <Tooltip title="Adding new tasks is disabled for the pilot">
          <CustomButton
            backgroundColor="transparent"
            disabled={true}
            color="#000"
            hoverColor={colors.LGREEN}
            hoverBackgroundColor="transparent"
            height={{ xs: "20px", sm: "35px", md: "45px" }}
            // marginRight="10px" // Corrected marginRight
            onClick={handleAddTaskOpen}
          >
            ADD NEW TASK
          </CustomButton>
        </Tooltip>
      </Stack>

      {questions.length > 0 ? <DiligenceQuestionsComponent savedWorkstream={savedWorkstream} question={questions} onAddQuestion={handleAddQuestion} dynamicPercentage={dynamicPercentage} onUpdateCardCount={handleUpdateCardCount} /> : <Typography>No questions available</Typography>}
      <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
        <Grid item xs={12} md={6} lg={7}>
          <Stack spacing={2} mt={8}>
            {questions.map((question, index) => renderCard1(question, index))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <Stack direction="row" spacing={2}>
            {RenderStatusColumn(
              "Red Flags",
              questions.map((question) => (
                <Chip
                  label={`${question.red_flags} Red Flags`}
                  sx={{
                    bgcolor: question.red_flags > 5 ? "red" : question.red_flags > 2 ? "orange" : "green",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />
              )),
            )}
            {RenderStatusColumn(
              "Confidence",
              questions.map((question) => (
                <Card sx={{ width: "32px", height: "32px", bgcolor: question.confidence_score * 100 > 70 ? "green" : question.confidence_score * 100 > 30 ? "orange" : "red", borderRadius: "50%", display: "flex", justifyContent: "center", border: "2px solid transparent", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff", fontSize: "10px", fontWeight: "bold" }}>{question.confidence_score * 100}%</Typography>
                </Card>
              )),
            )}
            {RenderStatusColumn(
              "Status",
              questions.map((question) => (
                <Card
                  sx={{
                    width: "32px",
                    height: "32px",
                    bgcolor: question.status === "Success" ? "green" : question.status === "Pending" ? "orange" : "red",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid transparent",
                    animation: question.status === "Pending" ? "flashOrange 1.3s infinite" : "none", // Apply animation only for Pending status
                  }}
                  className={question.status === "Pending" ? "animate-flash" : ""}
                >
                  {question.status === "Success" && <Check size={24} color="#fff" />}
                </Card>
              )),
            )}
            {RenderStatusColumn(
              "User Input",
              questions.map((question, index) => (
                <Tooltip title="Click to validate the question">
                  <Card
                    onClick={() => handleCardClick(index, question.id)}
                    sx={{
                      width: "32px",
                      height: "32px",
                      bgcolor: selectedCards[index] ? "green" : "white",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: selectedCards[index] ? "2px solid green" : "2px solid black",
                      cursor: "pointer",
                    }}
                  >
                    {selectedCards[index] && <Check size={24} color="#fff" />}
                  </Card>
                </Tooltip>
              )),
            )}
          </Stack>
        </Grid>
      </Grid>

      {/* Add Task Dialog */}
      <AddTaskDialog open={openTaskDialog} handleClose={handleAddTaskClose} addTask={handleAddTask} />

      {/* Edit Question Dialog */}
      <EditQuestionDialog open={openEditDialog} handleClose={handleEditQuestionClose} question={selectedQuestion} />

      {/* Delete Confirm Modal */}
      <DeleteConfirmModal open={openDeleteConfirm} handleClose={handleDeleteConfirmClose} handleConfirm={handleDeleteQuestion} type="question" />
    </Documentstart>
  );
};

export default Diligence;
