import { Box, Divider, IconButton, List, Paper, Switch, TextField, ToggleButton, Typography, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DisabledSendIcon from "../../../assets/chat/disabledSend.png";
import SendIcon from "../../../assets/chat/send.png";
// import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { api_subscription_key, backendUrl } from "../../../config";
import useMessages from "../../../hooks/useMessage";
import { fetchConversation, setMessages_rd } from "../../../redux/actions/conversationAction";
import { store } from "../../../redux/store";
import { MESSAGE_STATUS } from "../../../utils/constants";
import { RenderConversations } from "./RenderConversations";
import { clearDocuments } from "../../../redux/actions/documentsAction";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };

const Chat = ({ chatMode, setChatMode, conversationId, is_show_pdf }) => {
  const documents = useSelector((state) => state.documents.documents);

  const navigate = useNavigate();
  // const { conversationId } = props;

  const dispatch = useDispatch();
  console.log("CONVERSATIONID", conversationId);
  const user_id = useSelector((state) => state.auth.user?.id);

  // const [selectedDocuments, setSelectedDocuments] = useState([]);
  let noting;
  const textFocusRef = useRef();
  const location = useLocation();
  const [userMessage, setUserMessage] = useState("");
  // const [chatMode, setChatMode] = useState("deep_answer"); // Initial state
  const [isMessagePending, setIsMessagePending] = useState(false);
  const { userSendMessage, systemSendMessage, messages, setMessages } = useMessages(conversationId || "");
  const [filtredMessages, setFiltredMessages] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isMessagesNotEmpty, setisMessagesNotEmpty] = useState(false);

  const handleTextChange = (event) => {
    setUserMessage(event.target.value);
  };

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
    const filtredMessages_ = messages.filter((x) => x.content.includes(event.target.value));
    setFiltredMessages(filtredMessages_);
  };

  const setSuggestedMessage = (text) => {
    setUserMessage(text);
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  };

  const eventSourceRef = useRef(null);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const isSubmittingRef = useRef(false);
  const submit = () => {
    console.log("submit");
    if (isSubmittingRef.current) {
      console.warn("Submit already in progress.");
      return;
    }
    console.log("submit2", conversationId);
    if (!userMessage || !conversationId) {
      return;
    }
    console.log("submit3");

    isSubmittingRef.current = true;
    setIsMessagePending(true);
    userSendMessage(userMessage);
    setUserMessage(""); // Assuming this function is handling local state update to clear the message input

    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message`;
    const url = `${messageEndpoint}?user_message=${encodeURIComponent(userMessage)}&user_id=${user_id}&chat_mode=${chatMode}`;
    console.log("submit4");

    const events = new EventSource(url, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.accessToken}`, // Adjust accordingly if your store or context provides the token differently
        "Ocp-Apim-Subscription-Key": api_subscription_key, // Include any other necessary headers
      },
    });

    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      console.log(parsedData);
      systemSendMessage(parsedData); // Dispatch or handle the incoming message data as needed
      if (parsedData) {
        dispatch(setMessages_rd(parsedData)); // Dispatch action to store data in redux
      }
      if (parsedData.status === MESSAGE_STATUS.SUCCESS || parsedData.status === MESSAGE_STATUS.ERROR) {
        events.close();
        setIsMessagePending(false);
        isSubmittingRef.current = false;
      }
    };

    events.onerror = (error) => {
      console.error("Error during SSE connection:", error);
      events.close();
      setIsMessagePending(false);
      isSubmittingRef.current = false;
    };
  };

  // ref to control mount/ unmount
  const isActive = useRef(true);

  const parentScreen = location.state?.parent_screen;

  const isFirstFetch = useRef(true);
  console.log("MATATA", documents);

  useEffect(() => {
    // Define the fetch function
    const fetchFn = async () => {
      // Only perform fetch if it's the first time
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false; // Set to false after the first fetch
        await dispatch(clearDocuments());
        const conversationData = await dispatch(fetchConversation(conversationId, user_id, true));
        // console.log("documents" , documents)
        if (conversationData?.messages?.length) {
          console.log("CONVERSDSSDSD", conversationData.messages);
          setMessages(conversationData.messages);
          setisMessagesNotEmpty(true);
        }
      }
    };

    // Call the fetch function
    fetchFn();

    // Cleanup function to handle component unmounting
    return () => {
      console.log("User left the chat screen, deleting conversation...", isActive.current);
      if (!isActive.current && conversationId && messages.length === 0 && !isMessagesNotEmpty) {
        console.log("Deleting conversationId:", conversationId);
        // dispatch(deleteConversations([conversationId], user_id));
      }
      isActive.current = false;
    };
  }, [conversationId]);

  useEffect(() => {
    return () => {
      if (eventSourceRef.current && typeof eventSourceRef.current?.close === "function") {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
      isActive.current = false;
    };
  }, []); // Ensure this runs only once upon mounting and unmounting  useEffect(() => {
  useEffect(() => {
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  }, []);
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  // const [isDeepAnswer, setIsDeepAnswer] = useState(false);

  // const handleToggleDeepAnswer = () => {
  //   setIsDeepAnswer(!isDeepAnswer);
  // };
  // const debouncedSubmit = useCallback(debounce(submit, 300), []);

  const handleChatModeChange = (event, newMode) => {
    console.log("newMode", newMode);
    if (newMode !== null) {
      setChatMode(newMode); // Avoid null to prevent no-selection state
    }
  };

  const [textInputRows, setTextInputRows] = useState(1);
  useEffect(() => {
    // count number of lines if userMessage
    if (userMessage.split("\n").length < 4) {
      setTextInputRows(userMessage.split("\n").length);
    }
  }, [userMessage]);
  return (
    <Paper elevation={0} className={`flex flex-grow flex-col w-full"} h-full p-2 `}>
      <List sx={{ overflowY: "auto", flexGrow: 1 }}>
        {filtredMessages?.length > 0 ? (
          <RenderConversations messages={filtredMessages} setUserMessage={setSuggestedMessage} />
        ) : (
          <>
            {searchText?.length > 0 ? (
              <Typography variant="subtitle1" gutterBottom component="div" sx={{ p: 2 }}>
                No message found.
              </Typography>
            ) : (
              <>{messages?.length >= 0 && <RenderConversations messages={messages} setUserMessage={setSuggestedMessage} />}</>
            )}
          </>
        )}
      </List>

      <Divider />
      <Box sx={{ display: "flex", alignItems: "center", padding: 0 }}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Type your message here..."
          multiline
          rows={textInputRows}
          maxRows={4} // Allows the text field to expand up to 4 lines
          value={userMessage}
          onChange={handleTextChange}
          onKeyDown={async (event) => {
            if (event.key === "Enter" && !event.altKey) {
              // event.preventDefault(); // Prevents the default action of inserting a new line
              if (!isMessagePending && userMessage.trim().length > 0) {
                event.preventDefault();
                submit();
              }
            }
            if (event.altKey && event.key === "Enter") {
              console.log("Alt key pressed");
              setUserMessage(userMessage + "\n");
            }
          }}
          ref={textFocusRef}
        />
        <IconButton onClick={submit} disabled={isMessagePending || userMessage.length === 0} color={"primary"} component="span">
          {isMessagePending || userMessage.length === 0 ? <img src={DisabledSendIcon} alt="SendIcon" className="h-8 w-8" /> : <img src={SendIcon} alt="SendIcon" className="h-8 w-8" />}
        </IconButton>
      </Box>
    </Paper>
  );
};

export default Chat;
