import React, { useState } from "react";
import { AppBar, Toolbar, CssBaseline, IconButton, Menu, Box, Button, MenuItem, useMediaQuery, useTheme, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu"; // For the hamburger icon

import logoImage from "../../../assets/landingpage/logo.png"; // Make sure the path to your logo is correct
import { colors } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { OpenCalendar } from "../../../utils/utils";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between", // Adjusted to space-between to match the new layout
  padding: theme.spacing(2),
  backgroundColor: "transparent", // Removed AppBar background color
}));

const StyledButton = styled(Button)({
  color: "#000", // Changed to black color for text
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    color: colors.LGREEN,
  },
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 24px", // Adjust padding to match the image
  textTransform: "none",
  margin: "0 10px",
  fontWeight: 700,
});

const Logo = styled("img")({
  height: "25px",
  marginRight: "10px",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "#000",
  border: "2px solid #000", // Black border
  borderRadius: "50%", // Rounded corners
  padding: theme.spacing(1), // Adjust padding for better alignment
  "&:hover": {
    border: "2px solid #000", // Ensure border remains black on hover
  },
}));

const Header = ({ onSectionOneClick, onSectionTwoClick, onSectionThreeClick }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSignin = () => {
    if (isAuthenticated) {
      navigate("/data-import");
    } else {
      navigate("/invitation");
    }
  };
  const flexGrowValue = isMobile ? 1 : 0;
  return (
    <>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: "transparent" }}>
        <CssBaseline />
        <StyledToolbar>
          <Box sx={{ display: "flex", flexGrow: flexGrowValue, alignItems: "center" }}>
            <Logo src={logoImage} alt="DueDeal" />
          </Box>
          {isMobile ? (
            <>
              {/* <StyledIconButton size="large" edge="start" aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
              </StyledIconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionOneClick();
                  }}
                >
                  Features
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionTwoClick();
                  }}
                >
                  Ai-Powered
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onSectionThreeClick();
                  }}
                >
                  Use Cases
                </MenuItem>
              </Menu> */}
            </>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "center" }}>
              <StyledButton onClick={onSectionOneClick}>Features</StyledButton>
              <StyledButton onClick={onSectionTwoClick}>AI-powered</StyledButton>
              <StyledButton onClick={onSectionThreeClick}>Use Cases</StyledButton>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!isMobile && (
              <StyledButton variant="contained" onClick={handleClickSignin} sx={{ minWidth: "120px", flex: "none" }}>
                {isAuthenticated ? "My account" : "Sign in"}
              </StyledButton>
            )}

            <StyledButton onClick={OpenCalendar} variant="contained" sx={{ backgroundColor: "#000", color: "#fff", marginLeft: 2, minWidth: "120px", flex: "none" }}>
              {isAuthenticated ? "Book a demo" : "Book a demo"}
            </StyledButton>
          </Box>
        </StyledToolbar>
        <Divider /> {/* Added Divider here */}
      </AppBar>
    </>
  );
};

export default Header;
