import React, { useEffect, useState } from "react";
import { Modal, Button, FormControl, Select, MenuItem, DialogActions, Typography, Divider, FormHelperText, TextareaAutosize, TextField, InputLabel, Box, Snackbar, Grid, DialogTitle } from "@mui/material";

// import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router-dom";
import { formatDate, formatDateWithTimezoneOffset, getCurrentDate, getCurrentDate_y } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearTransactions, setTransaction, setTransactions, updateTransactionProperty } from "../../../redux/actions/transactionAction";
import { clearDocuments } from "../../../redux/actions/documentsAction";
import { countries, industries } from "../../../api/utils/dataModel";
import MuiAlert from "@mui/material/Alert";
import { hideSnackbar } from "../../../redux/actions/snackBarAction";
import { useSnackbar } from "notistack";
import { backendClient } from "../../../api/backend";
import { clearExploredData } from "../../../redux/actions/exploreAction";
import { faq_upload_answer, faq_upload_question } from "../../../utils/constants";
import FAQModal from "../../../components/FAQModal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ImportFormModal = ({ isSubmitLoading, setIsSubmitLoading, transaction_, open, handleClose, uploadedFiles }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [errors, setErrors] = useState({});

  // const uploadedFiles_ = uploadedFiles ? uploadedFiles :
  const [uploadedFiles_, setUploadedFiles] = useState(uploadedFiles ? uploadedFiles : []);
  useEffect(() => {
    setUploadedFiles(uploadedFiles ? uploadedFiles : []);
  }, [uploadedFiles]);
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  const [formData, setFormData] = useState({
    name: transaction_ ? transaction_.name : "",
    industry: transaction_ ? transaction_.industry : "",
    description: transaction_ ? transaction_.description : "",
    country: transaction_ ? transaction_.country : "",
    date: transaction_ ? formatDate(transaction_.date) : new Date(),
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { enqueueSnackbar } = useSnackbar();
  const [controller, setController] = useState(null);

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = formData.name.length > 0 ? "" : "Name is required";
    tempErrors.industry = formData.industry.length > 0 ? "" : "Industry is required";
    tempErrors.description = formData.description.length > 0 ? "" : "Description is required";
    tempErrors.country = formData.country.length > 0 ? "" : "Country is required";
    tempErrors.date = formData.date.length > 0 ? "" : "Date is required";
    // Add more validations as needed
    setErrors(tempErrors);

    // Form is valid if there are no errors (all error messages are empty)
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      // If validation fails, prevent form submission
      return;
    }
    setIsSubmitLoading(true);
    await dispatch(clearExploredData());
    if (!transaction_) {
      enqueueSnackbar("Creating transaction...");
      // clean docs
      await dispatch(clearTransactions());
      await dispatch(clearDocuments());
      const metadata = {};
      const transactionData = {
        ...formData,
        date: formatDateWithTimezoneOffset(formData.date),
      };
      console.log("transactionData", transactionData);
      const transactionCreated = await backendClient.uploadDocuments(uploadedFiles_, metadata, transactionData);
      enqueueSnackbar("Transaction created!", { variant: "success" });
      await dispatch(setTransactions([transactionCreated]));
      enqueueSnackbar("Uploading documents...");
      navigate(`/deals`);
    } else {
      for (const property in formData) {
        // if date, format
        let transaction_field_value = transaction_[property];
        if (property === "date") {
          transaction_field_value = formatDate(transaction_field_value);
        }
        // Check if the property exists in transaction_ and is different
        if (transaction_ && formData[property] !== transaction_field_value) {
          // Dispatch the update action
          await dispatch(updateTransactionProperty(transaction_.id, property, [formData[property]], false, false));
        }
      }
      enqueueSnackbar("Transaction updated!", { variant: "success" });
      //
    }
    handleClose && handleClose(); // Close the modal after submit
    setIsSubmitLoading(false);
  };

  // useEffect(() => {
  //   console.log("Controller change...");
  //   return () => {
  //     if (controller) {
  //       console.log("Aborting SSE connection on component unmount");
  //       controller.abort();
  //     }
  //   };
  // }, [controller]); // This effect depends on the controller state

  useEffect(() => {
    // Update the formData.date with the current date when the component mounts
    if (!transaction_) {
      setFormData((prevData) => ({
        ...prevData,
        date: getCurrentDate(),
      }));
    }
  }, []);

  const formControlStyle = { marginBottom: "16px" }; // Adjust margin-bottom as needed
  const inputStyle = { "&::placeholder": { color: "rgba(0, 0, 0, 0.54)" } }; // Style for placeholder text

  const formElementStyle = {
    margin: "2px 0", // Reduced margin
    // padding: "5px 0"
    // height: "px"
    bgcolor: "#f7f7f7",
  };
  const formInputStyle = {
    height: "50px",
    bgcolor: "#f7f7f7",
  };

  const inputLabelStyle = {
    top: -4, // Adjust the top position to align the label
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const snackbar = useSelector((state) => state.snackbar);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbar());
  };

  const [openFAQModel, setOpenFAQModel] = useState(true);
  // Check local storage to see if the modal should be shown
  useEffect(() => {
    const showModal = localStorage.getItem("showFAQModal_upload") !== "false";
    setOpenFAQModel(showModal);
  }, []);

  return (
    <Modal
      onBackdropClick={() => {
        console.log("toto");
      }}
      open={open}
      onClose={handleClose}
      className="flex justify-center items-center"
    >
      <Box sx={style}>
        <DialogTitle>
          <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
            Deal Info.
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "gray" }}>
            Apply filters to refine your search results
          </Typography>
        </DialogTitle>
        <Divider sx={{ mb: "1" }} />
        <FAQModal type={"upload"} question={faq_upload_question} answer={faq_upload_answer} open={openFAQModel} setOpen={setOpenFAQModel} />

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <form>
          {/* Name */}
          <TextField
            sx={{
              "& .MuiInputBase-root": formInputStyle,
              "& .MuiInputLabel-root": {
                lineHeight: "17px",
              },
            }}
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.name)}
            helperText={errors.name}
            disabled={isSubmitLoading}
          />

          <Grid container spacing={2} mt={0.5}>
            {/* Industry */}
            <Grid item xs={12} md={6}>
              <FormControl sx={formElementStyle} fullWidth margin="normal">
                <InputLabel style={inputLabelStyle}>Industry</InputLabel>
                <Select disabled={isSubmitLoading} style={formInputStyle} label="Industry" id="Industry" name="industry" value={formData.industry} onChange={handleInputChange}>
                  <MenuItem value="" disabled>
                    <em>Select Industry</em>
                  </MenuItem>
                  {industries.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {errors.industry && <FormHelperText>{errors.industry}</FormHelperText>}
              </FormControl>
            </Grid>

            {/* Country */}
            <Grid item xs={12} md={6}>
              <FormControl sx={formElementStyle} fullWidth margin="normal">
                <InputLabel style={inputLabelStyle}>Country</InputLabel>
                <Select disabled={isSubmitLoading} style={formInputStyle} label="Country" id="country" name="country" value={formData.country} onChange={handleInputChange} fullWidth variant="outlined">
                  <MenuItem value="" disabled>
                    <em>Select Country</em>
                  </MenuItem>
                  {countries.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {errors.country && <FormHelperText>{errors.country}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          {/* Description */}
          {/* <TextField label="Description" placeholder="Description" id="description" name="description" value={formData.description} onChange={handleInputChange} fullWidth variant="outlined" multiline minRows={4} style={formControlStyle} InputProps={{ style: inputStyle }} /> */}
          <div>
            <Typography variant="subtitle1">Description</Typography>
            <TextareaAutosize
              disabled={isSubmitLoading}
              className="textarea-autosize w-80 border-gray-300 border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent"
              maxRows={3}
              placeholder="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              style={{ width: "100%", marginTop: "8px", backgroundColor: "#f7f7f7" }}
            />
            {errors.description && (
              <Typography variant="caption" style={{ color: "red", marginTop: "4px", display: "block" }}>
                {errors.description}
              </Typography>
            )}
          </div>

          {/* Date */}
          <TextField
            sx={{
              "& .MuiInputBase-root": formInputStyle,
              "& .MuiInputLabel-root": {
                lineHeight: "17px",
              },
            }}
            name="date"
            type="date"
            value={getCurrentDate_y()}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            style={formControlStyle}
            margin="normal"
            error={Boolean(errors.date)}
            helperText={errors.date}
            disabled={isSubmitLoading}
          />

          {/* {transaction_ && <FilesUpload uploadedFiles={uploadedFiles_} setUploadedFiles={setUploadedFiles} is_upload={false} />} */}

          <DialogActions>
            <Button
              style={{ marginTop: 10 }}
              disabled={isSubmitLoading}
              variant="text"
              sx={{
                color: "black",
                borderRadius: "6px",
                fontSize: 16,
                padding: "10px 20px",
                width: "160px",
                height: "50px",
              }}
              onClick={handleClose}
              fullWidth
            >
              {"Cancel"}
            </Button>
            <Button
              disabled={isSubmitLoading}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                bgcolor: "black",
                color: "white",
                borderRadius: "6px",
                "&:hover": {
                  bgcolor: "black",
                  color: "white",
                },
                width: "160px",
                height: "50px",
                fontSize: 16,
                padding: "10px 20px",
              }}
            >
              {isSubmitLoading ? "Loading..." : "Validate"}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Modal>
  );
};

export default ImportFormModal;

// let metadata = {
//   ticker: "Local",
//   label: `Custom File`,
//   docType: "Select Doc type",
//   country: formData.country,
//   metadataType: 'transaction_data'
// };
// const metadata = state.metadata[file.name] || {};
