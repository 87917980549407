import React, { useState, useEffect } from "react";
import { Card, Stack, Typography, Divider, TextField, Button, List, ListItem, ListItemText, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const DiligenceQuestionsComponent = ({ onAddQuestion, savedWorkstream, questions: original_q, onUpdateCardCount, dynamicPercentage }) => {
  const questionnaire = savedWorkstream.questionnaires[0];
  // console.log(original_q);
  const [questions, setQuestions] = useState(questionnaire.questions.filter((q) => q.status === "Suggested").map((q) => q.content));
  const [newQuestion, setNewQuestion] = useState("");

  // Notify the parent component whenever the question count changes
  useEffect(() => {
    onUpdateCardCount(questions.length);
  }, [questions, onUpdateCardCount]);

  const handleAddQuestion = () => {
    if (newQuestion.trim() !== "") {
      setQuestions([newQuestion, ...questions]);
      setNewQuestion("");
    }
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleAddToCards = (question) => {
    onAddQuestion(question);
  };
  return (
    <Stack direction="row" spacing={2} sx={{ width: "100%", marginY: "12px" }}>
      {/* Existing Card with Latest Two Questions */}
      <Card sx={{ width: "60%", height: "250px", bgcolor: "#f5f5f5", borderRadius: "10px", padding: "20px", marginBottom: 4 }}>
        <Stack spacing={2} direction={"row"} justifyContent={"space-between"} height={"100%"}>
          <Stack spacing={2} width={"45%"}>
            <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000" }}>
              Your due diligence optimization score
            </Typography>
            <Card
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#000",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h1" sx={{ fontWeight: "semibold", color: "#fff" }}>
                {dynamicPercentage.toFixed(0)}%
              </Typography>
            </Card>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack spacing={2} width={"100%"}>
            <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000" }}>
              Improve your score by following recommendations in the section below:
            </Typography>

            {questions.slice(0, 3).map((question, index) => (
              <Stack key={index} direction="row" alignItems="center" spacing={2} width="100%">
                {/* The Card containing the question */}
                <Card sx={{ flex: 1, bgcolor: "#fff", borderRadius: "10px", p: 1 }}>
                  <Typography variant="body2" sx={{ color: "#000" }}>
                    {question}
                  </Typography>
                </Card>

                {/* The Button with Rotated Icon */}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "black",
                    color: "#fff",
                    textTransform: "none",
                    borderRadius: "5px",
                    padding: "16px 16px",
                    minWidth: "auto",
                  }}
                  onClick={() => handleAddToCards(question)}
                >
                  {/* Rotate the icon 90 degrees to the right */}
                  <KeyboardArrowUpIcon sx={{ transform: "rotate(90deg)" }} />
                </Button>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default DiligenceQuestionsComponent;
