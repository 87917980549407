// keywordActions.js
import { backendClient } from "../../api/backend";
import { CREATE_KEYWORD, FETCH_KEYWORDS, FETCH_KEYWORD, UPDATE_KEYWORD, DELETE_KEYWORD, GET_SYNCHRONIZED, START_SYNCHRONIZE, SET_KEYWORD_SYNCHRONIZED } from "./types";

export const createKeyword = (keywordData) => {
  return async (dispatch) => {
    const keyword = await backendClient.createKeyword(keywordData);
    console.log("keyword backend", keyword);
    dispatch({
      type: CREATE_KEYWORD,
      payload: keyword,
    });
  };
};

export const fetchKeywords = (page, pageSize) => {
  return async (dispatch) => {
    const keywords = await backendClient.getKeywords(page, pageSize);
    dispatch({
      type: FETCH_KEYWORDS,
      payload: keywords,
    });
  };
};

export const getSynchronized = () => {
  return async (dispatch) => {
    const synchronized = await backendClient.getSynchronized();
    dispatch({
      type: GET_SYNCHRONIZED,
      payload: synchronized,
    });
    return synchronized;
  };
};
export const startSynchronize = () => {
  return async (dispatch) => {
    const synchronize = await backendClient.startSynchronize();
    dispatch({
      type: START_SYNCHRONIZE,
      payload: synchronize,
    });
    return synchronize;
  };
};

export const fetchKeyword = (id) => {
  return async (dispatch) => {
    const keyword = await backendClient.getKeyword(id);
    dispatch({
      type: FETCH_KEYWORD,
      payload: keyword,
    });
  };
};

export const updateKeyword = (id, keywordData) => {
  return async (dispatch) => {
    const updatedKeyword = await backendClient.updateKeyword(id, keywordData);
    dispatch({
      type: UPDATE_KEYWORD,
      payload: updatedKeyword,
    });
  };
};

export const updateKeywordSynchronized = (id, synchronized) => {
  return async (dispatch) => {
    dispatch({
      type: SET_KEYWORD_SYNCHRONIZED,
      payload: {id, synchronized},
    });
  };
};

export const deleteKeywords = (keywordIds) => {
  return async (dispatch) => {
    await backendClient.deleteKeywords(keywordIds);
    dispatch({
      type: DELETE_KEYWORD,
      payload: keywordIds,
    });
  };
};
