import React from "react";
import { Button } from "@mui/material";

// Reusable CustomButton Component
const CustomButton = ({
  children,
  variant = "contained",
  color = "#000", // Default text color
  backgroundColor = "transparent", // Default background color
  hoverColor,
  hoverBackgroundColor = "transparent",
  borderRadius = "5px",
  padding = "10px 24px",
  textTransform = "uppercase",
  fontWeight = "bold",
  width = { xs: "100%", sm: "300px", md: "350px" },
  height = { xs: "20px", sm: "35px", md: "45px" },
  startIcon,
  endIcon,
  onClick,
  sx = {},
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      sx={{
        color,
        backgroundColor,
        borderRadius,
        padding,
        textTransform,
        fontWeight,
        width,
        height,
        margin: "0 10px", // Default margin
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
          color: hoverColor,
        },
        ...sx, // Spread additional sx styles
      }}
      {...rest} // Spread other props
    >
      {children}
    </Button>
  );
};

export default CustomButton;
