import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    backgroundColor: "white",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: "white",
  fontWeight: "bold",
  fontSize: "16px",
}));

const StatusSelector = ({ initialStatus, onStatusChange, disabled }) => {
  const [status, setStatus] = useState(initialStatus);

  const handleChange = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    onStatusChange(newStatus);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "bidding":
        return "green";
      case "kill":
        return "red";
      case "pending":
        return "orange";
      default:
        return "grey";
    }
  };

  return (
    <StyledFormControl>
      <Select
        disabled={disabled}
        value={status}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          backgroundColor: `${getStatusColor(status)} !important`,
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          padding: "0px", // Set padding to 1px (or adjust as needed)

          "& .MuiSelect-icon": {
            color: "white",
          },
        }}
      >
        <StyledMenuItem value="Bidding">Bidding</StyledMenuItem>
        <StyledMenuItem value="Pending">Pending</StyledMenuItem>
        <StyledMenuItem value="Kill">Kill</StyledMenuItem>
      </Select>
    </StyledFormControl>
  );
};

export default StatusSelector;
