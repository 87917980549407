import React, { useState } from "react";
import { Box, Card, Chip, IconButton, Stack, Typography, Grid, CardActionArea, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@mui/material";
import { Check, Activity, FilePenIcon, ClockIcon, Edit, Trash, Save } from "lucide-react"; // Importing Edit and Trash icons
import { colors } from "../../../utils/colors";

const DueDiligenceCard = ({ id, title, progressPercentage, completedTasks, inProgressTasks, userInputTasks, notStartedTasks, templateTasks, type, onClick, isEditMode, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const cardBackgroundColor = type === "pair" ? "#1AB5B8" : "#F5F5F7";
  const textColor = type === "pair" ? "#fff" : "#000";
  const secondaryTextColor = type === "pair" ? "#ccc" : "#758694";

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.stopPropagation();
    onEdit(id, newTitle);
    setIsEditing(false);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setIsDeleteDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Grid item xs={12} sm={4} md={4} lg={2.4} xl={2.4}>
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          {isEditing ? (
            <>
              {/* Wrapping TextField in a div to apply ellipsis behavior */}
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%", // Adjust width as needed
                }}
              >
                <TextField
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  autoFocus
                  onBlur={handleSaveClick}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSaveClick(e);
                    }
                  }}
                  inputProps={{
                    style: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </div>
              <IconButton size="small" onClick={handleSaveClick}>
                <Save size={16} />
              </IconButton>
            </>
          ) : (
            <>
              {/* Typography to display title with ellipsis when text is too long */}
              <Tooltip title={title}>
                <Typography
                  variant="h7"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap", // No line breaks
                    overflow: "hidden", // Hide overflow
                    textOverflow: "ellipsis", // Display ellipsis when text overflows
                    maxWidth: "250px", // Adjust width to suit your layout
                  }}
                >
                  {title}
                </Typography>
              </Tooltip>

              {isEditMode && (
                <IconButton disabled={true} size="small" onClick={handleEditClick}>
                  <Edit size={16} />
                </IconButton>
              )}
            </>
          )}

          {isEditMode && !isEditing && (
            <IconButton disabled={true} size="small" onClick={handleDeleteClick}>
              <Trash size={16} />
            </IconButton>
          )}
        </Stack>

        {typeof progressPercentage !== "undefined" && <Chip label={`${progressPercentage}% Progress`} variant="text" size="small" style={{ backgroundColor: "#f3fda2", width: "170px", fontWeight: "bold", color: "#000" }} />}
        <Tooltip title="Click to access the questionnaire" arrow>
          <Card
            style={{
              display: "block",
              width: 250,
              borderRadius: "10px",
              transitionDuration: "0.3s",
              height: "auto",
              backgroundColor: cardBackgroundColor,
            }}
          >
            {/* Wrap the entire card in CardActionArea to make it clickable */}
            <CardActionArea onClick={onClick}>
              <Stack spacing={1} margin={2}>
                {/* Completed Tasks */}
                {typeof completedTasks !== "undefined" && (
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Check size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography variant={"body1"} sx={{ fontWeight: "bold", color: textColor }}>
                        Completed
                      </Typography>
                      <Typography variant="body2" sx={{ color: secondaryTextColor }}>
                        {completedTasks} tasks
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* In Progress Tasks */}
                {typeof inProgressTasks !== "undefined" && (
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <Activity size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography variant={"body1"} sx={{ fontWeight: "bold", color: textColor }}>
                        In Progress
                      </Typography>
                      <Typography variant="body2" sx={{ color: secondaryTextColor }}>
                        {inProgressTasks} tasks
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* User Input Tasks */}
                {typeof userInputTasks !== "undefined" && (
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <FilePenIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography variant={"body1"} sx={{ fontWeight: "bold", color: textColor }}>
                        User Input
                      </Typography>
                      <Typography variant="body2" sx={{ color: secondaryTextColor }}>
                        {userInputTasks} tasks
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {/* Not Started Yet */}
                {typeof notStartedTasks !== "undefined" && (
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <ClockIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography variant={"body1"} sx={{ fontWeight: "bold", color: textColor }}>
                        Not Started Yet
                      </Typography>
                      <Typography variant="body2" sx={{ color: secondaryTextColor }}>
                        {notStartedTasks} tasks
                      </Typography>
                    </Stack>
                  </Stack>
                )}
                {/* Template tasks */}
                {typeof templateTasks !== "undefined" && (
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#000",
                        borderRadius: 2,
                      }}
                    >
                      <ClockIcon size={20} color={"#fff"} />
                    </Box>
                    <Stack spacing={0} alignItems={"flex-start"}>
                      <Typography variant={"body1"} sx={{ fontWeight: "bold", color: textColor }}>
                        Template queries
                      </Typography>
                      <Typography variant="body2" sx={{ color: secondaryTextColor }}>
                        {templateTasks} tasks
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Stack>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "md",
            width: "100%",
            padding: 1,
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete the "{title}" workstream?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancelDelete}
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: 2,
              color: "black",
              borderColor: "black",
              "&:hover": {
                bgcolor: "white",
                color: "black",
                borderColor: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleConfirmDelete}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: colors.BLACK,
              color: colors.WHITE,
              boxShadow: "none",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "black",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DueDiligenceCard;
