import React from "react";
import { Box, Typography, Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import myImage from "../../../assets/landingpage/s51.png"; // Ensure this path is correct
import { colors } from "../../../utils/colors";
import { styled } from "@mui/material/styles";
import { OpenCalendar, isMobile, windowHeight } from "../../../utils/utils";

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: colors.LGREEN, // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});

const SectionFive = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box ref={ref} sx={{ flexGrow: 1, overflow: "hidden", px: 3, py: 0, bgcolor: "white", display: "flex", alignItems: "center", maxWidth: "1400px", justifyContent: "center", margin: "0 auto" }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          {/* Decorative line above title - Adjust the width and color as needed */}
          <Box sx={{ width: "40px", height: "4px", bgcolor: "#000", mb: 2 }}></Box>
          <Typography variant="h3" component="h3" sx={{ fontWeight: "bold", mb: 3 }}>
            Harness the Power of Generative AI for Your M&A Process
          </Typography>
          <Typography style={{ color: colors.PGRAY }} variant="body1" sx={{ mb: 2 }}>
            Worried about the heavy lifting in legal and finance work? Our Generative AI streamlines the creation of transition documentation, making it easier for your legal and financial advisors to onboard smoothly. This not only reduces their fees but also ensures they can focus on delivering a
            thorough and effective job
          </Typography>
          <StyledButton onClick={OpenCalendar} variant="contained" sx={{ backgroundColor: "#000", color: "white", textTransform: "none", marginTop: isMobile() ? windowHeight / 320 : windowHeight / 700 }}>
            Book a demo
          </StyledButton>
          {/* <Typography sx={{ cursor: "pointer", color: colors.LGREEN, display: "inline-flex", alignItems: "center", mt: 2 }}>Discover all use cases</Typography> */}
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Display image only on medium screens and up */}
          {matchesMD && <img src={myImage} alt="Landing Page" style={{ width: "100%", height: "auto" }} />}
        </Grid>
      </Grid>
    </Box>
  );
});

export default SectionFive;
