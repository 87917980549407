import {
  ADD_DOCUMENT_TO_DOCUMENTS,
  ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS,
  CLEAR_DOCUMENTS,
  DELETE_DOCUMENT_FROM_TRANSACTION,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  REMOVE_DOCUMENT_FROM_DOCUMENTS,
  SET_DOCUMENTS,
  SET_DOCUMENTS_TRANSACTION_ID,
  UPDATE_DOCUMENT_PROPERTY,
  UPLOAD_DOCUMENTS,
  SET_ACTIVE_PDF,
  SET_CITATION_HIGHLIGHT,
  SET_PDF_LOADING_TASK
} from "../actions/types";

const initialState = {
  document: null,
  documents: [], // Store the list of transactions here
  activePdfId: null,
  citation: null,
  loadingTask: null,
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };

    case SET_ACTIVE_PDF:
      return {
        ...state,
        activePdf: action.payload,
      };
    case SET_PDF_LOADING_TASK:
      return {
        ...state,
        loadingTask: action.payload,
      };

    case SET_CITATION_HIGHLIGHT:
      return {
        ...state,
        citation: action.payload,
      };
    case FETCH_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, ...[action.payload]],
        // documents: action.payload,
      };
    case SET_DOCUMENTS_TRANSACTION_ID:
      return {
        ...state,
        documents: [action.payload],
        // documents: action.payload,
      };

    case ADD_DOCUMENT_TO_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };

    // case ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS:
    //   return {
    //     ...state,
    //     documents: [
    //       {
    //         ...state.documents[0],
    //         documents: [...(state.documents[0]?.documents || []), action.payload.updatedDocument],
    //         transactionId: action.payload.transactionId,
    //       },
    //       // ...state.documents.slice(1)
    //     ],
    //   };

    case ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS:
      // Check if the transactionId already exists in the documents array
      const index = state.documents.findIndex((doc) => doc.transactionId === action.payload.transactionId);
      console.log("documentsREDUCER", state.documents);
      console.log("index", index);

      if (index !== -1) {
        // If found, create a new array where the specific element is updated
        console.log(
          "RESOOO",
          state.documents.map((doc, idx) => (idx === index ? { ...doc, documents: [...doc.documents, action.payload.updatedDocument] } : doc))
        );
        return {
          ...state,
          documents: state.documents.map((doc, idx) => (idx === index ? { ...doc, documents: [...doc.documents, action.payload.updatedDocument] } : doc)),
        };
      } else {
        console.log("REAAAA", [...state.documents, { transactionId: action.payload.transactionId, documents: [action.payload.updatedDocument] }]);
        // If not found, add the new element to the documents array
        return {
          ...state,
          documents: [...state.documents, { transactionId: action.payload.transactionId, documents: [action.payload.updatedDocument] }],
        };
      }

    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case UPDATE_DOCUMENT_PROPERTY:
      const updatedDocuments = state.documents.map((document) => ({
        ...document,
        documents: document.documents.map((doc) => (doc.id === action.payload.id ? action.payload : doc)),
      }));
      return {
        ...state,
        documents: updatedDocuments,
      };

    case DELETE_DOCUMENT_FROM_TRANSACTION:
      const updatedDocuments_ = state.documents.map((document) => ({
        ...document,
        documents: document.documents.filter((doc) => !action.payload.includes(doc.id)),
      }));
      console.log('updatedDocuments_XXX,', updatedDocuments_)
      return {
        ...state,
        documents: updatedDocuments_,
      };

    case REMOVE_DOCUMENT_FROM_DOCUMENTS:
      return {
        ...state,
        documents: state.documents.map((documents_) => ({
          ...documents_,
          documents: documents_.documents.filter((doc) => doc.id !== action.payload),
        })),
      };

    case FETCH_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case CLEAR_DOCUMENTS:
      return {
        ...state,
        documents: [],
      };

    default:
      return state;
  }
};

export default documentsReducer;
