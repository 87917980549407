import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { Document, Page } from "react-pdf";
import { PdfFocusProvider } from "./pdf-viewer/pdfContext";
import ViewPdf from "./pdf-viewer/ViewPdf";
import { windowWidth } from "../utils/utils";
import ViewPdfUrl from "./pdf-viewer/ViewPdfUrl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
  height: "90vh", // Changed from 90% to 90vh to ensure it does not exceed the viewport height
  maxHeight: "90vh", // Prevent exceeding the viewport height
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Explicitly allowing vertical scrolling
};
const ModalPdfView = ({ open, handleClose, url }) => {
  console.log(url);
  return (
    <PdfFocusProvider>
      <Modal open={open} onBackdropClick={handleClose} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ViewPdf file={{ url: url }} pdfWidth={75} pdfHeight={140} />
        </Box>
      </Modal>
    </PdfFocusProvider>
  );
};

export default ModalPdfView;
