import React from "react";
import { Button, Box, Typography, Tooltip } from "@mui/material";

const InfoCardButton = ({ icon, title, subtitle, backgroundColor, onClick, tooltip, disabled }) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        disabled={disabled}
        variant="text"
        onClick={onClick}
        sx={{
          textTransform: "none",
          color: "black",
          padding: 2,
          display: "flex",
          alignItems: "start",
          justifyContent: "flex-start",
          gap: 1,
          backgroundColor: "transparent", // You can adjust this for a hover effect
          borderRadius: "10px",
          width: "90%",
          // transition: "box-shadow 0.3s ease", // Smooth transition for hover effect
          // "&:hover": {
          //   boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Hover effect for box shadow
          //   backgroundColor: "transparent", // Optionally keep the background transparent
          // },
        }}
      >
        {/* Icon Section */}
        <Box
          sx={{
            width: "50px",
            height: "50px",
            display: "flex",
            // flexGrow: "1",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: backgroundColor || "#4386f9", // Default background color
            borderRadius: "10px",
          }}
        >
          {icon}
        </Box>

        {/* Text Section */}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "#757575", fontSize: "12px" }}>
            {subtitle}
          </Typography>
        </Box>
      </Button>
    </Tooltip>
  );
};

export default InfoCardButton;
