import { ADD_TO_NEWSLETTER, AUTH_SUCCESS, GET_USER, LOGOUT_SUCCESS, SET_ACCESS_TOKEN, SET_IS_LOADING } from "../actions/types";

const initialState = {
  isLoading: true,
  isAuthentificated: null,
  accountData: null,
  accessToken: null,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case ADD_TO_NEWSLETTER:
      return state;

    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthentificated: action.payload.isAuthentificated,
        user: action.payload.user,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthentificated: null,
        accountData: null,
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
