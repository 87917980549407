export const MESSAGE_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const ROLE = {
  USER: "user",
  ASSISTANT: "assistant",
};

export const MAX_FILES_UPLOAD = 5;

export const DISCLAIMER_URL = "https://duedilteststorageacc.blob.core.windows.net/test-storage-container-name/disclaimer.pdf?sp=r&st=2024-05-04T19:08:22Z&se=2026-06-25T03:08:22Z&spr=https&sv=2022-11-02&sr=b&sig=mHW4p8BGNWnZy6PJ4TZxSkfNvDPr3rgsWsWC6fzsmj0%3D";

export const faq_upload_question = "Welcome to the upload screen ";
export const faq_upload_answer = "The upload process utilizes our machine learning algorithm to deconstruct documents into manageable building blocks before uploading them to a graph database. This procedure is complex. Feel free to drag and drop a document to see it uploading in real time.";

export const faq_explore_question = "Welcome to the explore screen  ";
export const faq_explore_answer =
  "Explore is the process throughout which a user can explore a dataroom with ease. Each request can take up to a few seconds to run. We offer a range of filters on the left hand side to filter the results, as well as a preview screen to be able to visualize the results in real time.";

export const faq_chat_question = "Welcome to our novel conversation agent, DAN";
export const faq_chat_answer =
  "Every query to Dan involves a comprehensive search that includes both breadth and depth exploration, along with follow-up questions to the tool and document, making the process quite intricate and time-consuming. We have perfected Dan's workload to ensure each request takes the right amount of time to give a high quality response.";
