import React, { useEffect, useState } from "react";
import Connector from "./components/Connector";
import FilesUpload from "../../components/FilesUpload";
import { useDispatch, useSelector } from "react-redux";
import { connectors } from "../../api/utils/dataModel";
import { Grid, Box, Typography, TableCell, IconButton, TableContainer, Table, TableHead, TableRow, TableBody, Tooltip, Stack, LinearProgress, Paper } from "@mui/material";
import { colors } from "../../utils/colors";
import { useSnackbar } from "notistack";
import { getUser } from "../../redux/actions/authAction";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { backendUrl } from "../../config";
import HeaderComponent from "../../components/HeaderComponent";
import { Trash } from "lucide-react";
import Documentstart from "../../components/Documentstart";


function convertTimestampToDateTime(timestamp) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Extract time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Construct the formatted string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const DataScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  console.log(uploadedFiles);
  const [filesToRemove, setFilesToRemove] = useState([]);
  // snackbar useSnackbar
  

  const handleRemoveFile = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    const removedFile = newUploadedFiles.splice(index, 1)[0];
    setUploadedFiles(newUploadedFiles);

    if (removedFile) {
      setFilesToRemove([...filesToRemove, removedFile]);
    }
    enqueueSnackbar("File removed", { variant: "success" });
  };

  return (
    <Documentstart title={"Data Import"} open={open} headerProps={{ showFilter: false, showView: false, showSearchBar: false }}>
      {/* <Grid sx={{ p: 2, bgcolor: colors.WHITE, borderRadius: 2, width: "100%", height: "100%" }}> */}
      {/* <HeaderComponent title="Data Import" /> */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h6" component="h2" sx={{ ml: 2 }}>
          Import files
        </Typography>
        <FilesUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} is_upload={true} />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" sx={{ ml: 2 }} gutterBottom>
          Connect an existing database
        </Typography>
        <Grid container spacing={2}>
          {connectors.map((connector, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Connector item={connector} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map((file, index) => (
                <TableRow key={index}>
                  <TableCell>{convertTimestampToDateTime(file.lastModified)}</TableCell>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{formatBytes(file.size)}</TableCell>
                  <TableCell>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={2}>
                      <LinearProgress
                        variant="determinate"
                        value={file.progress}
                        sx={{
                          width: "100%",
                          height: 9,
                          borderRadius: "8px",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#000",
                          },
                          bgcolor: "transparent",
                          border: "1px solid gray",
                        }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {file.progress}%
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{file?.status}</TableCell>
                  <TableCell>
                  <Tooltip title="Remove file" arrow>
                    <IconButton onClick={() => handleRemoveFile(index)} color="error">
                      <Trash />
                    </IconButton>
                  </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* </Grid> */}
    </Documentstart>

    // <Grid container sx={{ height: "100vh" }}>
    //   <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
    //     <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
    //       <Grid sx={{ p: 2, bgcolor: colors.WHITE, borderRadius: 2, width: "100%", height: "100%" }}>
    //         <HeaderComponent title="Data Import" />
    //         <Box sx={{ mb: 8 }}>
    //           <Typography variant="h6" component="h2" sx={{ ml: 2 }}>
    //             Import files
    //           </Typography>
    //           <FilesUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} is_upload={true} />
    //         </Box>

    //         <Box sx={{ mb: 4 }}>
    //           <Typography variant="h6" component="h2" sx={{ ml: 2 }} gutterBottom>
    //             Connect an existing database
    //           </Typography>
    //           <Grid container spacing={2}>
    //             {connectors.map((connector, index) => (
    //               <Grid item xs={12} md={6} lg={4} key={index}>
    //                 <Connector item={connector} />
    //               </Grid>
    //             ))}
    //           </Grid>
    //         </Box>

    //         <Box sx={{ mb: 4 }}>
    //           <TableContainer component={Paper} elevation={2}>
    //             <Table>
    //               <TableHead>
    //                 <TableRow>
    //                   <TableCell>Date</TableCell>
    //                   <TableCell>Name</TableCell>
    //                   <TableCell>Size</TableCell>
    //                   <TableCell>Progress</TableCell>
    //                   <TableCell>Status</TableCell>
    //                   <TableCell>Actions</TableCell>
    //                 </TableRow>
    //               </TableHead>
    //               <TableBody>
    //                 {uploadedFiles.map((file, index) => (
    //                   <TableRow key={index}>
    //                     <TableCell>{convertTimestampToDateTime(file.lastModified)}</TableCell>
    //                     <TableCell>{file.name}</TableCell>
    //                     <TableCell>{formatBytes(file.size)}</TableCell>
    //                     <TableCell>
    //                       <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={2}>
    //                         <LinearProgress
    //                           variant="determinate"
    //                           value={file.progress}
    //                           sx={{
    //                             width: "100%",
    //                             height: 9,
    //                             borderRadius: "8px",
    //                             "& .MuiLinearProgress-bar": {
    //                               backgroundColor: "#000",
    //                             },
    //                             bgcolor: "transparent",
    //                             border: "1px solid gray",
    //                           }}
    //                         />
    //                         <Typography variant="body2" color="textSecondary">
    //                           {file.progress}%
    //                         </Typography>
    //                       </Stack>
    //                     </TableCell>
    //                     <TableCell>{file.status}</TableCell>
    //                     <TableCell>
    //                       <IconButton onClick={() => handleRemoveFile(index)} color="error">
    //                         <Trash />
    //                       </IconButton>
    //                     </TableCell>
    //                   </TableRow>
    //                 ))}
    //               </TableBody>
    //             </Table>
    //           </TableContainer>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default DataScreen;
