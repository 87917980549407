import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function FAQModal({ type, question, answer, open, setOpen }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDontShowAgain = () => {
    localStorage.setItem("showFAQModal_" + type, "false"); // Set local storage to prevent modal from showing again
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, bgcolor: "background.paper", border: "2px solid #000", boxShadow: 24, p: 4 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {question}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {answer}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleDontShowAgain} color="primary">
            Don't Show Again
          </Button>
          <Button onClick={handleClose} color="primary">
            Alright!
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default FAQModal;
