import { backendClient } from "../../api/backend";
import { clearDocuments, fetchDocuments } from "./documentsAction";
import { CREATE_CONVERSATION, DELETE_CONVERSATIONS, FETCH_CONVERSATION, FETCH_CONVERSATIONS, SET_MESSAGES, UPDATE_MESSAGE_LIKE } from "./types";

export const createConversation = (transactionId_documentsIds, user_id) => {
  return async (dispatch, getState) => {
    const conversationId = await backendClient.createConversation(transactionId_documentsIds, user_id);
    dispatch({
      type: CREATE_CONVERSATION,
      payload: conversationId,
    });
    return conversationId;
  };
};

export const updateMessageLike = (message_id, value, text) => {
  return async (dispatch, getState) => {
    try {
      // Call the backend to update the like field
      console.log("updating like", message_id, value, text);
      await backendClient.updateMessageLike(message_id, value, text);

      // Dispatch the update to Redux store
      dispatch({
        type: UPDATE_MESSAGE_LIKE,
        payload: { message_id, value, text },
      });
    } catch (error) {
      console.error("Failed to update message like:", error);
      // Optionally handle errors, e.g., show an error message to the user
    }
  };
};

export const deleteConversations = (conversationIds, user_id) => {
  return async (dispatch, getState) => {
    await backendClient.deleteConversations(conversationIds, user_id);
    dispatch({
      type: DELETE_CONVERSATIONS,
      payload: conversationIds,
    });
  };
};

export const setMessages_rd = (messages) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_MESSAGES,
      payload: messages,
    });
  };
};

export const fetchConversation = (conversationId, user_id, fetch_documents) => {
  return async (dispatch, getState) => {
    console.log("totootot", conversationId, user_id, fetch_documents);
    // clean documents
    // await dispatch(clearDocuments());
    const conversation = await backendClient.fetchConversation(conversationId, user_id);
    console.log("----XXconversationXX", conversation);

    conversation.messages.forEach((message) => {
      if (message.chat_mode !== null && message.sub_processes.length > 0) {
        message.updated_at = message.sub_processes[0].updated_at;
        message.created_at = message.sub_processes[0].created_at;
      }
    });

    // Reorder the messages by updated_at
    conversation.messages.sort((a, b) => {
      const maxDateA = new Date(Math.max(new Date(a.updated_at), new Date(a.created_at)));
      const maxDateB = new Date(Math.max(new Date(b.updated_at), new Date(b.created_at)));
      return maxDateA - maxDateB;
    });
    // fetch documents
    let documentsIds = conversation.transactions.flatMap((transaction) => transaction.documents_ids);
    // for ech transactionid in conversation.transactions, fetch documents
    if (fetch_documents) {
      for (const transaction of conversation.transactions) {
        const documents = await dispatch(fetchDocuments(transaction.id, dispatch));
        console.log("godocumentsoftransctionXXX", documents);
      }
    }

    const conversationData = { messages: conversation.messages, documentsIds: documentsIds.flat() };
    dispatch({
      type: FETCH_CONVERSATION,
      payload: conversationData,
    });
    return conversationData;
  };
};

export const fetchConversations = (user_id, page, pageSize) => {
  return async (dispatch, getState) => {
    const conversations = await backendClient.fetchConversations(user_id, page, pageSize);
    dispatch({
      type: FETCH_CONVERSATIONS,
      payload: conversations,
    });
    return conversations;
  };
};
