import {
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  VALIDATE_QUESTION_REQUEST,
  VALIDATE_QUESTION_SUCCESS,
  VALIDATE_QUESTION_FAILURE,
  VALIDATE_QUESTION,
} from "./types";
import { backendClient } from "../../api/backend";

// Fetch Questions
export const fetchQuestions = (questionnaireId) => async (dispatch) => {
  dispatch({ type: FETCH_QUESTIONS_REQUEST });
  try {
    const response = await backendClient.getQuestions(questionnaireId);
    dispatch({ type: FETCH_QUESTIONS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FETCH_QUESTIONS_FAILURE, payload: error.message });
  }
};

// Create Question
export const createQuestion = (questionData) => async (dispatch) => {
  dispatch({ type: CREATE_QUESTION_REQUEST });
  try {
    const newQuestion = await backendClient.createQuestion(questionData);
    dispatch({ type: CREATE_QUESTION_SUCCESS, payload: newQuestion });
  } catch (error) {
    dispatch({ type: CREATE_QUESTION_FAILURE, payload: error.message });
  }
};

// Update Question
export const updateQuestion = (questionId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_QUESTION_REQUEST });
  try {
    const updatedQuestion = await backendClient.updateQuestion(questionId, updatedData);
    dispatch({ type: UPDATE_QUESTION_SUCCESS, payload: updatedQuestion });
  } catch (error) {
    dispatch({ type: UPDATE_QUESTION_FAILURE, payload: error.message });
  }
};
export const followupquestions = (questionId, updatedData) => async (dispatch) => {
  const updatedQuestion = await backendClient.followupquestions(questionId, updatedData);
  dispatch({ type: VALIDATE_QUESTION, payload: updatedQuestion });
};

// Delete Question
export const deleteQuestion = (questionId) => async (dispatch) => {
  dispatch({ type: DELETE_QUESTION_REQUEST });
  try {
    await backendClient.deleteQuestion(questionId);
    dispatch({ type: DELETE_QUESTION_SUCCESS, payload: questionId });
  } catch (error) {
    dispatch({ type: DELETE_QUESTION_FAILURE, payload: error.message });
  }
};
