import { ADD_TO_NEWSLETTER, AUTH_SUCCESS, GET_USER, LOGOUT_SUCCESS, SET_ACCESS_TOKEN, SET_IS_LOADING } from "./types";
import { loginRequest } from "../../authConfig";
import { backendClient } from "../../api/backend";

export const microsoftSignin = (instance) => {
  return async (dispatch, getState) => {
    const response = await instance.loginPopup({
      scopes: ["user.read"], // Modify scopes as needed
    });

    // Save the token or a flag indicating successful login to local storage
    localStorage.setItem("userLoggedIn", "true");
    localStorage.setItem("authResponse", JSON.stringify(response));
    await dispatch(setAccessToken(response.accessToken));
    // If you need to store the token, use response.accessToken

    let user = await dispatch(getUser(response.uniqueId));
    console.log("ISOOSMOO", user);
    if (user?.message === "User not found.") {
      console.log("creating user..", response);
      const userData = {
        id: response.uniqueId,
        name: response.account.name,
        email: response.account.username,
        tenantId: response.tenantId,
        organization_id: response.account.username.split("@")[1].split(".")[0],
      };
      // Create or update the user in your backend
      user = await backendClient.createUser(userData);
    }
    // Prepare user data for backend

    dispatch({
      type: AUTH_SUCCESS,
      payload: { isAuthentificated: true, user: user },
    });
  };
};

export const addToNewsletter = (email) => {
  return async (dispatch, getState) => {
    // Create or update the user in your backend
    const response = await backendClient.addToNewsletter(email);
    dispatch({
      type: ADD_TO_NEWSLETTER,
      payload: response,
    });
    return response;
  };
};

export const getUser = (user_id) => {
  return async (dispatch, getState) => {
    // Create or update the user in your backend
    const user = await backendClient.getUser(user_id);
    dispatch({
      type: GET_USER,
      payload: user,
    });
    return user;
  };
};

export const setIsLoading = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: value,
    });
  };
};

export const setAccessToken = (token) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: token,
    });
  };
};

export const logOut = () => {
  return async (dispatch, getState) => {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("authResponse");
    // Dispatch an action to update your app's state
    dispatch({
      type: LOGOUT_SUCCESS,
      // other payload if necessary
    });
  };
};

const logout = () => {
  // Clear local storage or session storage
};
