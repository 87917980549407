import React from "react";
import { useNavigate } from "react-router-dom";

const Connector = ({ item }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    switch (item.title) {
      case "Select from existing":
        return navigate("/deals");
      default:
        return;
    }
  };
  return (
    <div onClick={handleOnClick} className="flex items-center p-4 shadow-md hover:shadow-lg transition-shadow duration-200 rounded-md bg-[#F5F5F7] cursor-pointer">
      <img src={item.image} alt={item.title} className="h-12 mr-6" />
      <div className="flex flex-col justify-center items-center">
        <div className="text-base font-semibold text-center p-1" style={{ fontFamily: "Nunito Sans, sans-serif" }}>
          {item.title}
        </div>
        <div className="text-xs text-gray-600 line-clamp-2" style={{ fontFamily: "Nunito Sans, sans-serif" }}>
          {item.description}
        </div>
      </div>
    </div>
  );
};

export default Connector;
