import React, { useState } from "react";
import { Modal, Typography, Button, Box } from "@mui/material";

function TermsModal({ open, handleOpen, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          DueDeal.ai: Legal Disclaimer for Pilot Users
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <strong>Background</strong>
          <br />
          By accessing this web address, you (the “User”) are requesting access to DueDeal.ai (“DueDeal” or the “Tool”) on behalf of the Represented Company. The Tool has been developed by K-Fin Ltd., with its registered address at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, United
          Kingdom...
          {/* Add the rest of your formatted text here */}
        </Typography>
      </Box>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default TermsModal;
