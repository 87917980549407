import { Box, Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { ChevronLeft, Search, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DisplayMultiplePdfs from "../../components/pdf-viewer/DisplayMultiplePdfs";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import CardSwitchToggle from "./ConversationModeToggle";
import Chat from "./component/Chat";
import Spinner from "../../components/Spinner";
import Documentstart from "../../components/Documentstart";
import SelectDocumentDialog from "./component/SelectDocumentModal";
import { fetchQuestionnaires } from "../../redux/actions/questionnaireAction";
import SummaryTable from "./component/SummaryTable";
import { fetchDocument, fetchDocuments } from "../../redux/actions/documentsAction";
const Questionnaire = () => {
  const { workstreamId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const open = useSelector((state) => state.navBar.is_open);
  const conversation = useSelector((state) => state.conversation.conversation);
  console.log("newest conversation", conversation);
  const questionnaires = useSelector((state) => state.questionnaire.questionnaires);
  const transaction = location.state?.transaction; // Access the passed transaction
  // const workstreams = useSelector((state) => state.workstreams)
  const documents = useSelector((state) => state.documents.documents);
  const isShowPdf = questionnaires?.length > 0;
  const parentScreen = location.state?.parent_screen;
  const dispatch = useDispatch();
  console.log("documents", documents);
  console.log("Obtained questionnaire", questionnaires);
  useEffect(() => {
    console.log("Running dispatch to fetch questionnaires");
    if (workstreamId) {
      dispatch(fetchQuestionnaires(workstreamId));
    }
  }, [dispatch, workstreamId]);

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  console.log("transaction is ", transaction);
  const handleBackNavigation = () => {
    if (parentScreen) {
      navigate(parentScreen);
    } else {
      navigate("/deals", { state: { view: "DUE", transaction: transaction } });
    }
  };
  useEffect(() => {
    const func = async () => {
      if (documents.length === 0) {
        await dispatch(fetchDocuments(transaction.id));
      }
    };
    func();
  }, [transaction]);
  return (
    <PdfFocusProvider>
      <Documentstart
        title={`Questionnaire for ${transaction.name}`}
        open={open}
        headerProps={{
          showSearchBar: false,
          showFilter: false,
          showView: false,
          backbutton: true,
          handleBackClick: handleBackNavigation,
        }}
      >
        {/* two cards rows and responsive */}
        <Grid
          container
          spacing={2}
          sx={{
            width: open ? "calc(100vw - 260px)" : "calc(100vw - 120px)",
          }}
        >
          <Grid item xs={12} md={5} style={{ height: "80vh", overflowY: "auto" }}>
            {/* <Chat conversationId={conversationId} is_show_pdf={isShowPdf} chatMode={chatMode} setChatMode={setChatMode} /> */}
            <SummaryTable questions={questionnaires[0]} transaction={transaction} />
          </Grid>
          {!questionnaires && questionnaires?.length > 0 ? (
            <Spinner />
          ) : (
            <Grid item xs={12} md={7} style={{ height: "80vh" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "95%",
                }}
              >
                <DisplayMultiplePdfs is_conversation={true} documentsIds={documents} pdfWidth={open ? 250 : 30} pdfHeight={75} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Documentstart>
    </PdfFocusProvider>
  );
};

export default Questionnaire;
