import React, { useRef } from "react";
import { Box, CssBaseline } from "@mui/material";

// Update these paths to the correct locations of your images
import backgroundImage from "../../assets/landingpage/background.png";
import Header from "./components/Header";
import SectionZero from "./components/SectionZero";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import SectionFour from "./components/SectionFour";
import SectionFive from "./components/SectionFive";
import Footer from "./components/Footer";
import SectionSix from "./components/SectionSix";

import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import Sectionstats from "./components/Sectionstats";
import SectionLogos from "./components/Sectionlogos";

const LandingPageScreen = () => {
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);

  const scrollToSectionOne = () => {
    sectionOneRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionTwo = () => {
    sectionTwoRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionThree = () => {
    sectionThreeRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        // backgroundImage: `url(${backgroundImage})`,
        // backgroundSize: { xs: "cover", md: "contain" },
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center top",
        width: "100%",
        // height: { xs: "180vh"},
        // minHeight: "100vh",
        height: { xs: windowWidth * 2.5 + "px" },
        // display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <Header onSectionOneClick={scrollToSectionOne} onSectionTwoClick={scrollToSectionTwo} onSectionThreeClick={scrollToSectionThree} />

      <SectionZero />

      {/* <div style={{ height: isMobile() ? "0px" : "50px" }}></div> */}
      {/* <Sectionstats />

      <div style={{ height: isMobile() ? windowHeight / 5 : "50px" }}></div> */}
      <div style={{ height: isMobile() ? "50px" : "50px" }}></div>
      <SectionOne ref={sectionOneRef} />
      <div style={{ height: isMobile() ? "100px" : "50px" }}></div>

      <SectionTwo />

      <div style={{ height: isMobile() ? "50px" : "50px" }}></div>

      <SectionThree ref={sectionTwoRef} />

      <div style={{ height: isMobile() ? "50px" : "50px" }}></div>

      <SectionFour />

      <div style={{ marginTop: isMobile() ? "50px" : "0px", height: isMobile() ? "0px" : windowHeight / 10 }}></div>

      <SectionFive ref={sectionThreeRef} />

      {/* <div style={{ height: isMobile() ? windowHeight / 100 : windowHeight / 10 }}></div> */}

      {/* <SectionSix /> */}
      {/* <div style={{ height: isMobile() ? windowHeight / 100 : windowHeight / 10 }}></div> */}

      <SectionLogos />
      <div style={{ height: isMobile() ? windowHeight / 100 : windowHeight / 10 }}></div>
      <Footer />
    </Box>
  );
};

export default LandingPageScreen;
