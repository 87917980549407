import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";

const Container = styled.div`
  width: 94%;
//   background: #f0f0f0;
  //   padding: 10px;
  border-radius: 8px;
  `;
  
  const Step = styled(animated.div)`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 8px 0;
  padding: 4px 8px;
  background: white;
  border-left: 3px solid ${(props) => (props.active ? "#4CAF50" : "#ccc")};
  color: ${(props) => (props.active ? "#4CAF50" : "#999")};
  transition: border-left 0.3s ease-in-out, color 0.3s ease-in-out;
`;



const AnimatedSteps = ({stepsArray}) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prev) => (prev + 1) % stepsArray.length);
    }, 5000 / stepsArray.length); // Dividing total duration by number of steps for even timing

    return () => clearInterval(interval);
  }, []);

  const animationProps = useSpring({
    from: { opacity: 1 },
    to: { opacity: 1 },
    reset: true,
    reverse: activeStep % 2 === 0,
    delay: 800,
  });

  return (
    <Container>
      {stepsArray.map((step, index) => (
        <Step key={step.step} style={animationProps} active={index === activeStep}>
          {step.text}
        </Step>
      ))}
    </Container>
  );
};

export default AnimatedSteps;
