import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const NotFoundScreen = () => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  // useEffect to handle the delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Delay of 1000 milliseconds (1 second)
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, textAlign: "center" }}>
      {isVisible && ( // Conditional rendering based on isVisible state
        <>
          <ErrorOutlineIcon sx={{ fontSize: 100, color: "secondary.main" }} />
          <Typography component="h1" variant="h4" gutterBottom>
            404 - Page Not Found
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Oops! The page you're looking for isn't here.
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            You might have the wrong address, or the page may have moved.
          </Typography>
          <Box mt={4}>
            <Button variant="contained" color="primary" onClick={() => navigate("/")}>
              Go to Home
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default NotFoundScreen;
