export const colors = {
  WHITE: "#ffffff",
  BLACK: "#000000",
  BLUE: "#1A75D3",
  GREEN: "#0B9550",
  RED: "#FF0000",
  YELLOW: "#FFD700",
  PURPLE_ONE: "#4F46E6",
  PURPLE_TWO: "#827AF3",
  GRAY_ONE: "#797E90",
  GRAY_TWO: "#F7F7F8",
  LGREEN: "#009AA3",
  PGRAY: "#62757f",
};
