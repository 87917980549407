import React from "react";
import { Box, Button, Card, CardContent, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, CircularProgress } from "@mui/material";
import styled from "styled-components";

import { colors } from "../../../utils/colors";

const InfoCardList = ({ title, color, list_info }) => {
  const titleStyles = {
    color: color || colors.PRIMARY,
    fontWeight: "bold",
  };

  const StyledDetailsBox = styled(Box)(({ theme }) => ({
    backgroundColor: ({ color }) => color,
    borderRadius: "24px",
    padding: "34px",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "12px",
    height: "100%",
  }));

  const InfoRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  });

  const Label = styled(Typography)({
    fontWeight: "bold",
    fontSize: "18px",
  });

  const Value = styled(Typography)({
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "right",
  });

  const cardBackgroundColor = color || "#F5F5F7";

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      sx={{
        mb: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Title */}
      <Typography variant="h6" gutterBottom sx={{ ...titleStyles, alignSelf: "normal" }}>
        {title}
      </Typography>

      {/* Card Container */}

      <Card
        sx={{
          height: 267,
          width: 300,
          bgcolor: `${cardBackgroundColor} !important`,
          borderRadius: "24px !important",
          "&:hover": { bgcolor: cardBackgroundColor },
          boxShadow: "none",
          "& .css-mnu21i-MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        {/* Card Content */}
        <CardContent
          sx={{
            padding: "12px",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: `0 !important`,
            flexDirection: "column",
            "& .MuiCardContent-root": {
              paddingBottom: 0,
              backgroundColor: cardBackgroundColor,
            },
          }}
        >
          {/* Rendering InfoCard Components */}
          {list_info.map((infoCard, index) => (
            <React.Fragment key={index}>{infoCard}</React.Fragment>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default InfoCardList;
