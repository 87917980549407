import { Box, Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { ChevronLeft, Search, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DisplayMultiplePdfs from "../../components/pdf-viewer/DisplayMultiplePdfs";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import CardSwitchToggle from "./ConversationModeToggle";
import Chat from "./component/Chat";
import Spinner from "../../components/Spinner";
import Documentstart from "../../components/Documentstart";
import SelectDocumentDialog from "./component/SelectDocumentModal";

const NewChatScreen = () => {
  const [conversationMode, setConversationMode] = useState("dan");
  const { conversationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [chatMode, setChatMode] = useState("deep_answer"); // Initial state
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [openFAQModal, setOpenFAQModal] = useState(true);
  const handleModeChange = (newMode) => {
    console.log("newMode", newMode);
    setConversationMode(newMode);
    if (newMode === "dan") {
      setChatMode("deep_answer");
    } else {
      setChatMode("automatic");
    }
  };

  const open = useSelector((state) => state.navBar.is_open);
  const conversation = useSelector((state) => state.conversation.conversation);
  console.log("newest conversation", conversation);

  const isShowPdf = conversation?.messages.length > 0;
  const parentScreen = location.state?.parent_screen;

  console.log("conversation ID ID", conversationId);

  // Check local storage to see if the modal should be shown
  useEffect(() => {
    const showModal = localStorage.getItem("showFAQModal_chat") !== "false";
    setOpenFAQModal(showModal);
  }, []);

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleBackNavigation = () => {
    if (parentScreen) {
      navigate(parentScreen);
    } else {
      navigate(-1);
    }
  };

  // Function to open the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <PdfFocusProvider>
      <Documentstart
        title={`Conversation -`}
        open={open}
        headerProps={{
          showSearchBar: false,
          showFilter: false,
          showView: false,
          // searchQuery: searchQuery,
          // setSearchQuery: setSearchQuery,
          backbutton: true,
          handleBackClick: handleBackNavigation,
          // conversationmgmt: (
          //   <Box display="flex" alignItems="center" width="100%" padding={0} sx={{ padding: 0 }}>
          //     <Grid
          //       container
          //       alignItems="center"
          //       justifyContent="flex-end" // Spread the elements across the width
          //       gap={3}
          //       sx={{
          //         // width: open ? "calc(100vw - 260px)" : "calc(100vw - 95px)",
          //         height: 70,
          //         pr: 2, // Horizontal padding for all elements
          //       }}
          //     >
          //       {/* Back Button */}

          //       {/* Search Bar */}

          //       {/* Toggle Switch */}
          //       <Grid item>
          //         <CardSwitchToggle
          //           mode={conversationMode}
          //           onModeChange={handleModeChange}
          //           sx={{
          //             minWidth: 180, // Ensure the toggle fits properly
          //           }}
          //         />
          //       </Grid>

          //       {/* Create Document Button */}
          //       <Tooltip title="Coming soon">
          //         <Grid item>
          //           <Button
          //             variant="outlined"
          //             onClick={handleOpenDialog}
          //             disabled={true}
          //             sx={{
          //               fontWeight: "bold",
          //               color: "black",
          //               borderColor: "black",
          //               backgroundColor: "white",
          //               borderRadius: 2,
          //               padding: "8px 16px", // Adjusting the padding to match the card height and overall size
          //               fontSize: "12px", // Aligning the font size to match the Typography variant 'caption'
          //               minWidth: 120, // Setting minWidth to 120px to match card width
          //               height: 40, // Aligning with the height of the StyledCard
          //               "&:hover": {
          //                 backgroundColor: "#F5F5F7",
          //                 borderColor: "black",
          //               },
          //             }}
          //           >
          //             Select Document
          //           </Button>
          //           {/* Render the SelectDocumentDialog modal */}
          //           <SelectDocumentDialog open={isDialogOpen} handleClose={handleCloseDialog} />
          //         </Grid>
          //       </Tooltip>
          //     </Grid>
          //   </Box>
          // ),
          // custombutton: true,
          // custombuttontext: "Add Template Question",
          // custombuttononclick: () => {},
          // sx: { margin: "0" },
          // startIcon: <Plus size="22px" />,
          // hoverBackgroundColor: "",
          // variant: "contained",
        }}
      >
        {/* two cards rows and responsive */}
        <Grid
          container
          spacing={2}
          sx={{
            width: open ? "calc(100vw - 260px)" : "calc(100vw - 120px)",
          }}
        >
          <Grid item xs={12} md={5} style={{ height: "80vh" }}>
            <Chat conversationId={conversationId} is_show_pdf={isShowPdf} chatMode={chatMode} setChatMode={setChatMode} />
          </Grid>
          {!conversation ? (
            <Spinner />
          ) : (
            <Grid item xs={12} md={7} style={{ height: "80vh" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "95%",
                }}
              >
                <DisplayMultiplePdfs is_conversation={true} documentsIds={conversation.documentsIds} pdfWidth={open ? 250 : 30} pdfHeight={75} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Documentstart>
    </PdfFocusProvider>
  );
};

export default NewChatScreen;
