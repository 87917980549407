import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Switch, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme, active }) => ({
  height: "40px", // Set a fixed height for smaller cards
  widght: "120px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  backgroundColor: active ? "black" : theme.palette.background.paper,
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: "16px !important", // Override default padding
  "&:last-child": {
    paddingBottom: "16px !important", // Override default padding
  },
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#000" : "#000",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CardSwitchToggle = ({ mode, onModeChange }) => {
  // Initialize mode as "dan" by default
  // const [mode, setMode] = useState("dan");

  const handleChange = (event) => {
    console.log("Change", event.target.checked);
    onModeChange(event.target.checked ? "dan" : "conversation");
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
      <Tooltip title="For simple query e.g. summarize, translate, etc.">
        <StyledCard
          active={mode === "conversation"}
          onClick={() => {
            onModeChange("conversation");
          }}
        >
          <StyledCardContent sx={{ width: "120px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Typography variant="caption">Conversation</Typography>
          </StyledCardContent>
        </StyledCard>
      </Tooltip>

      <StyledSwitch checked={mode === "dan"} onChange={handleChange} inputProps={{ "aria-label": "toggle conversation mode" }} />

      <Tooltip title="Best in-class proprietary agent">
        <StyledCard
          active={mode === "dan"}
          onClick={() => {
            onModeChange("dan");
          }}
        >
          <StyledCardContent sx={{ width: "120px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Typography variant="caption">DAN</Typography>
          </StyledCardContent>
        </StyledCard>
      </Tooltip>
    </Box>
  );
};

export default CardSwitchToggle;
