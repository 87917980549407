import moment from "moment";

export const getIndustryColor = (industry) => {
  const colors = {
    "WIND ONSHORE": "#ff7f50",
    "WIND OFFSHORE": "#8a2be2",
    SOLAR: "#ffd700",
    "HEALTH CARE": "#4caf50",
    "DATA CENTER": "#2196f3",
    OIL: "#f44336",
    FIBRE: "#9c27b0",
  };
  return colors[industry.toUpperCase()] || "#757575"; // default color
};
export const getRiskColor = (risk) => {
  const colors = {
    MEDIUM: "#FFD700",
    LOW: "#0B9550",
    HIGH: "#FF0000",
  };
  return colors[risk.toUpperCase()] || "#757575"; // default color
};

export function getDocumentById(documents, documentId) {
  for (const document_tr_id of documents) {
    for (const document of document_tr_id.documents) {
      if (document.id === documentId) {
        return document;
      }
    }
  }
  return null; // return null if no matching document is found
}

export const findDocument = (documents_, documentId) => {
  let foundDoc = null;
  documents_.forEach((item) => {
    const documents = item.documents;
    const doc = documents.find((doc) => doc.id === documentId);
    if (doc) {
      foundDoc = doc;
      return; // Exit loop if document is found
    }
  });
  return foundDoc;
};

export function deepEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function OpenCalendar() {
  if (window.gtag) {
    window.gtag("event", "click", {
      event_category: "engagement",
      event_label: "open_calendar",
      value: "Open Calendar Link Click",
    });
  }
  window.open("https://calendly.com/othman-kabbaj--/intro-duedeal", "_blank");
}

export function parseSingleQuoteJSON(originalString) {
  // Step 2: Replace the escaped single quotes that are meant to be double quotes in JSON keys and values
  originalString = originalString.replace(/\\'/g, "'");

  // Step 3: Replace all single quotes around keys and values with double quotes
  originalString = originalString.replace(/'\b/g, '"').replace(/\b'/g, '"');

  // Step 4: Escape any unescaped double quotes within the string values
  originalString = originalString.replace(/"IEC"/g, '\\"IEC\\"');
  return originalString;
}
export function handleDownloadDocument(url, filename) {
  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href to the URL of the file you want to download
  anchor.href = url;

  // Use the 'download' attribute to set a filename
  anchor.download = filename || "download";

  // Append the anchor to the body
  document.body.appendChild(anchor);

  // Trigger the download by simulating a click on the anchor
  anchor.click();

  // Remove the anchor from the body
  document.body.removeChild(anchor);
}

export const getAllDocumentTypes = (document_types_categories) => {
  let allValues = [];

  Object.keys(document_types_categories).forEach((category) => {
    const categories = document_types_categories[category].map((x) => x.name);
    allValues = allValues.concat(categories);
  });

  return allValues;
};

export function convertToSectorPerformanceData(inputData) {
  // Create an array from the input object's entries and map each key-value pair to the desired object format
  const sectorPerformanceData = Object.entries(inputData).map(([key, value]) => ({
    sector: capitalizeFirstLetter(key),
    Transactions: value,
  }));

  return sectorPerformanceData;
}

// Helper function to capitalize the first letter of a string
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertCountryFormat(data) {
  // Define a base HSL color
  const baseHue = 300; // Starting hue
  const hueIncrement = 20; // Increment hue for each country to have different colors
  let currentHue = baseHue;

  const countryData = Object.entries(data).map(([id, value], index) => {
    const color = `hsl(${currentHue}, 70%, 50%)`;
    currentHue += hueIncrement; // Increment hue for the next country

    return {
      id,
      label: id,
      value: value,
      color: color,
    };
  });

  return countryData;
}

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getCurrentDate_y = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDate = (isoDateTime) => {
  // Parse the ISO date-time string and convert to a Date object
  const date = new Date(isoDateTime);

  // Convert the Date object to a date string
  const dateString = date.toISOString().split("T")[0];

  return dateString;
};

export const formatDateWithTimezoneOffset = (dateString) => {
  // Create a moment object from the date string
  const date = moment(dateString);

  // Format the date including the timezone offset
  // 'Z' in the format string includes the offset from UTC in +hh:mm or -hh:mm format
  return date.format("YYYY-MM-DD HH:mm:ss");
};

export function markdownToHtmlWithParagraphs(markdownText) {
  const parts = markdownText.trim().split("\n\n");

  let htmlOutput = "";

  parts.forEach((part) => {
    if (part.split("\n")[0].includes("|")) {
      const lines = part.split("\n");
      if (lines.length > 2) {
        const header = lines[0];
        const rows = lines.slice(2);
        let htmlTable = '<table class="min-w-full divide-y divide-gray-200 mt-2 mb-2">\n<thead class="bg-gray-50">\n<tr>\n';
        header
          .split("|")
          .slice(1, -1)
          .forEach((headerItem) => {
            htmlTable += `    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">${headerItem.trim()}</th>\n`;
          });
        htmlTable += '</tr>\n</thead>\n<tbody class="bg-white divide-y divide-gray-200">\n';
        rows.forEach((row) => {
          htmlTable += "<tr>\n";
          row
            .split("|")
            .slice(1, -1)
            .forEach((cell) => {
              htmlTable += `    <td class="px-6 py-4 whitespace-nowrap">${cell.trim()}</td>\n`;
            });
          htmlTable += "</tr>\n";
        });
        htmlTable += "</tbody>\n</table>\n";
        htmlOutput += htmlTable;
      }
    } else {
      htmlOutput += `<p class="mt-4 mb-4">${part.trim()}</p>\n`;
    }
  });

  return htmlOutput;
}
