import { CardContent, Paper } from "@material-ui/core";
import { Box, Button, Card, Checkbox, Chip, Divider, Menu, MenuItem, Grid, IconButton, InputAdornment, List, Stack, TextField, Typography, ButtonBase } from "@mui/material";
import { ChevronLeft, Delete, MessageCircle, Repeat, Search, Trash, X, ChevronDown } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backendClient } from "../../api/backend";
import SearchBar from "../../components/SearchBar";
import Spinner from "../../components/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { addKeyHypothesis, clearHypothesis, deleteHypothesis, extractHypothesis, fetchHypothesis, reExtractOneHypothesis, updateHypothesis } from "../../redux/actions/hypothesisAction";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api_subscription_key, backendUrl } from "../../config";
import { store } from "../../redux/store";
import Documentstart from "../../components/Documentstart";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
// Define the peersCards data
const peersCards = [
  { conv_id: 1, labels: ["Project Fly"], values: ["£16m"] },
  { conv_id: 2, labels: ["Project Atlantis"], values: ["£30m"] },
  { conv_id: 3, labels: ["Project Phoenix"], values: ["$13m"] },
  { conv_id: 4, labels: ["Project Fly Tranche 2"], values: ["$18m"] },
];

// Component for individual cards in the Peers section
const CardComponent = ({ label, risk, setPeers, conv_id, handleNavigateToConversation, source }) => {
  console.log("risk wrisk", risk);

  return (
    <Card
      sx={{ height: "80px", width: "200px", bgcolor: "#FFE4E1", position: "relative" }} // Set position to relative for absolute positioning inside
      style={{
        boxShadow: "none",
        backgroundColor: "#f7f7f7",
        borderRadius: "22px",
        color: "black",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            textAlign: "center",
            whiteSpace: "nowrap", // Prevent text from wrapping
            overflow: "hidden", // Hide the overflow text
            textOverflow: "ellipsis", // Add ellipsis for overflowed text
          }}
        >
          {label}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {risk}
        </Typography>
      </CardContent>

      {/* Icons positioned in the bottom right */}
      <Box
        sx={{
          position: "absolute",
          bottom: 8, // Slightly above the bottom edge
          right: 8, // Slightly to the left of the right edge
          display: "flex",
          gap: 0.5, // Space between the icons
        }}
      >
        <IconButton onClick={(event) => handleNavigateToConversation(conv_id, source)} sx={{ color: "gray" }} size="small">
          <MessageCircle size={12} /> {/* Start Conversation icon */}
        </IconButton>
        <IconButton
          onClick={() => {
            setPeers((state) => {
              if (state[label]) {
                // Check if the key exists
                return {
                  ...state,
                  [label]: state[label].filter((e) => e.conv_id !== conv_id), // Correct usage of dynamic key
                };
              }
              return state; // Fallback to the original state if the key doesn't exist
            });
          }}
          sx={{ color: "gray" }}
          size="small"
        >
          <Trash size={12} /> {/* Delete icon */}
        </IconButton>
      </Box>
    </Card>
  );
};

// Component for rendering the "Peers" section with cards
const PeersSection = ({ cards, setPeers }) => {
  return (
    <Grid container spacing={1}>
      {" "}
      {/* Reduce spacing between cards */}
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            marginBottom: 8,
          }}
        >
          {"Peers"}
        </Typography>
      </Grid>
      {/* Map through the cards array to display more cards */}
      <Grid container spacing={1} alignItems="flex-start">
        {" "}
        {/* Reduce spacing and align with value card */}
        {cards.slice(0, 8).map(
          (
            card,
            index, // Now display 8 cards
          ) => (
            <Grid
              item
              xs={6} // Display two cards in each row on medium screens
              sm={4} // Display three cards per row on small screens
              md={3} // Display four cards per row on larger screens
              key={index}
              sx={{ padding: 1 }} // Reduced padding to make the cards closer
            >
              <CardComponent label={card.labels[0]} risk={card.values.join(", ")} conv_id={card.conversation_id} setPeers={setPeers} source={card.source[0]} />
            </Grid>
          ),
        )}
      </Grid>
    </Grid>
  );
};

// CardsHypothesis Component
const CardsHypothesis = ({ card, colorIndex, isSelected, onSelectCard, onDelete, peer, setPeers, handleReExtractHypothesis }) => {
  const detailColors = ["#1AB5B8", "#B81A80"];
  const cardBackgroundColor = detailColors[colorIndex % 2];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  // State for the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null); // Used to track menu state
  const [selectedOption, setSelectedOption] = useState(card.hyp_type); //

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Open menu
    // console.log(event.currentTarget.textContent);
    // handleUpdateType(event.currentTarget.textContent);
  };
  const handleUpdateType = (selectedOption) => {
    console.log(selectedOption, "selectedOptionselectedOption");
    if (!selectedOption || selectedOption === "") return;
    dispatch(updateHypothesis(card.id, { hyp_type: selectedOption }));
    enqueueSnackbar("Status updated successfully", { variant: "success" });
  };
  const handleMenuClose = (option) => {
    setSelectedOption(option); // Set selected option
    console.log(option.textContent);

    handleUpdateType(option);
    setAnchorEl(null); // Close menu
  };

  const handleNavigateToConversation = async (conversation_id, transaction_id) => {
    await dispatch(clearDocuments());
    // for (const transactionId_documentsIds of transactionsIds_documentsIds) {
    await dispatch(fetchDocuments(transaction_id, 0, 10));

    navigate(`/chat/${conversation_id}`);
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };
  return (
    <Box spacing={4} my={1} sx={{ position: "relative", mt: "1" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* Wrapping both Checkbox and Typography in ButtonBase for a unified clickable area */}
        <ButtonBase
          sx={{
            justifyContent: "flex-start",
            width: "50%", // Let the width adjust to content
          }}
          onClick={() => onSelectCard(card.id)} // Handle both Typography and Checkbox clicks
        >
          {/* Checkbox */}
          <Checkbox
            checked={isSelected} // Control the checkbox state with isSelected
            sx={{ mr: 2 }} // Margin-right of 2
          />

          {/* Clickable Typography */}
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: cardBackgroundColor,
              fontWeight: "bold",
              cursor: "pointer", // Ensure cursor shows pointer to indicate clickability
            }}
          >
            {card.labels[0]}
          </Typography>
        </ButtonBase>

        {/* Right-aligned Buttons */}
        <Box sx={{ display: "flex", gap: 2, alignItems: "end", ml: "auto" }}>
          {/* Dropdown Button */}
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "white", // Text color
              padding: 1,
              fontSize: "16px",
              width: "150px",
              backgroundColor: "black", // Set background color to black
              "&:hover": {
                backgroundColor: "#333", // Slightly lighter on hover
              },
            }}
            endIcon={<ChevronDown />} // Add ChevronDown icon at the end
          >
            {selectedOption}
          </Button>

          <Menu
            id="simple-menu"
            PaperProps={{
              sx: {
                width: "150px", // Set the specific size for the menu
              },
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose(selectedOption)}
          >
            <MenuItem onClick={() => handleMenuClose("Overview")}>Overview</MenuItem>
            <MenuItem onClick={() => handleMenuClose("Details")}>Details</MenuItem>
            <MenuItem onClick={() => handleMenuClose("KPI")}>KPI</MenuItem>
            <MenuItem onClick={() => handleMenuClose("Other")}>Other</MenuItem>
          </Menu>

          {/* Start Conversation Button */}
          <Button onClick={(event) => handleNavigateToConversation(card.conversation_id, card.source[0])} startIcon={<MessageCircle />} variant="text" width="auto" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2">Check source</Typography>
            </Box>
          </Button>
          <Button onClick={async (event) => await handleReExtractHypothesis(event, card)} startIcon={<Repeat />} variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2">Reprompt</Typography>
            </Box>
          </Button>
          <Button disabled={true} startIcon={<Trash />} variant="text" sx={{ textTransform: "none", color: "black", padding: 2, fontSize: "16px" }} onClick={() => onDelete(card.id)}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2">Delete</Typography>
            </Box>
          </Button>
        </Box>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              marginBottom: 1,
            }}
          >
            {"Value"}
          </Typography>
          {/* TODO ADD Confidence first */}
          {/* <Chip
            label={"75% Confidence rate"}
            style={{ backgroundColor: "#fef08a" }}
            sx={{
              marginBottom: 2,
            }}
          /> */}
          <Card
            sx={{ height: "200px", width: "250px", bgcolor: cardBackgroundColor }}
            style={{
              boxShadow: "none",
              backgroundColor: cardBackgroundColor,
              borderRadius: "22px",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column", // Align items vertically
                  alignItems: "center", // Center horizontally
                  justifyContent: "center", // Center vertically
                  height: "100%", // Ensure the box takes full height
                }}
              >
                <Typography variant="h8" component="div" sx={{ color: "white", marginTop: "5px", fontWeight: "bold", textAlign: "center" }}>
                  {card.labels[0]}
                </Typography>

                <Divider
                  style={{ color: "#FFF", padding: "1px" }}
                  sx={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "30px",
                    backgroundColor: "white",
                  }}
                />

                <Typography variant="h4" sx={{ color: "white", textAlign: "center" }}>
                  {card.values.join(", ")}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={9}>
          {peer.length > 0 ? (
            // <PeersSection cards={peer} /> // Render the PeersSection when peers exist
            <PeersSection cards={peersCards} setPeers={setPeers} /> // Render the PeersSection when peers exist
          ) : (
            <Grid container spacing={1}>
              {" "}
              {/* Reduce spacing between cards */}
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    marginBottom: 8,
                  }}
                >
                  {"No Peers data to display"}
                </Typography>
              </Grid>
              {/* Map through the cards array to display more cards */}
            </Grid> // Display this message if no peers are available
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

// Main Component
const DealKpi = ({ setView, transaction }) => {
  console.log(transaction);
  const open = useSelector((state) => state.navBar.is_open);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hypothesisCards, setHypothesisCards] = useState([]);
  const [peers, setPeers] = useState(null); // State to store fetched peers
  const [loadingPeers, setLoadingPeers] = useState(false); // State for peer loading
  const [isExtracting, setIsExtracting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user?.id);
  const [loadingHypotheses, setLoadingHypotheses] = useState({});
  const eventSourceRef = useRef(null);
  const [reset, setReset] = useState(false);
  const [isLoadingExtractHypothesis, setIsLoadingExtractHypothesis] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Modal state
  const [isLoadingDelete, setIsLoadingDelete] = useState(false); // Loading state for deletion

  const fetchPeers = async (hypotheses) => {
    setLoadingPeers(true);
    try {
      const response = await backendClient.getPeers(hypotheses);
      setPeers(response);
    } catch (err) {
      console.error("Error fetching peers:", err);
      setError("Failed to fetch peers. Please try again.");
    } finally {
      setLoadingPeers(false);
    }
  };
  useEffect(() => {
    const fetchHypothesisData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await backendClient.fetchHypothesis(transaction.id);
        console.log(response);
        setHypothesisCards(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHypothesisData();
  }, [transaction.id]);

  useEffect(() => {
    if (hypothesisCards.length === 0) return;

    // Prepare payload for fetching peers
    const hypothesesPayload = hypothesisCards.map((hypothesis) => ({
      name: hypothesis.name,
      source: hypothesis.source,
      labels: hypothesis.labels, // Assuming `labels` is an array in hypothesis
      organization_id: hypothesis.organization_id,
    }));

    // Fetch peers based on hypotheses
    fetchPeers(hypothesesPayload);
  }, [hypothesisCards]);

  // console.log("peers data", peers);

  const handleReExtractHypothesis = async (event, hypothesis) => {
    event.preventDefault();

    const { id: hypothesisId } = hypothesis;
    setLoadingHypotheses((prevState) => ({ ...prevState, [hypothesisId]: true }));

    try {
      const transaction_id = transaction.id;
      console.log("Re-extracting hypothesis for", hypothesisId, transaction_id, user_id);

      // Assume dispatching an action to re-extract hypothesis
      await dispatch(reExtractOneHypothesis(transaction_id, user_id, hypothesisId));
      enqueueSnackbar("Hypothesis re-extracted successfully!", { variant: "success" });

      // Show notification or handle success
    } catch (error) {
      console.error("Failed to re-extract hypothesis:", hypothesisId, error);
    } finally {
      setLoadingHypotheses((prevState) => ({ ...prevState, [hypothesisId]: false }));
    }
  };

  const handleBackClick = () => {
    setView("DEALS");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const handleSelectCard = (cardId) => {
    setSelectedCards((prevSelected) => (prevSelected.includes(cardId) ? prevSelected.filter((id) => id !== cardId) : [...prevSelected, cardId]));
  };

  const handleDeleteSelected = async () => {
    setIsLoadingDelete(true);
    try {
      await backendClient.deleteHypothesis(selectedCards);
      setHypothesisCards((prevCards) => prevCards.filter((card) => !selectedCards.includes(card.id)));
      setSelectedCards([]);
      enqueueSnackbar("Selected KPIs deleted successfully!", { variant: "success" });
    } catch (err) {
      setError("Failed to delete selected KPIs. Please try again.");
    } finally {
      setIsLoadingDelete(false);
      setIsDeleteModalOpen(false); // Close the modal
    }
  };

  const handleDeleteSingleHypothesis = async (cardId) => {
    try {
      // Delete single hypothesis
      console.log("Deleting single card:", cardId);
      await backendClient.deleteHypothesis([cardId]);

      // Update local state
      setHypothesisCards((prevCards) => prevCards.filter((card) => card.id !== cardId));
    } catch (err) {
      console.error("Error deleting hypothesis:", err);
      setError("Failed to delete hypothesis. Please try again.");
    }
  };

  const filteredCards = hypothesisCards && hypothesisCards.length > 0 ? hypothesisCards.filter((card) => card.labels[0].toLowerCase().includes(searchQuery)) : [];

  // EVENT SOURCE

  useEffect(() => {
    console.log(transaction);
    if ((transaction.is_extracted === "False" || transaction.is_extracted === "Pending") && isExtracting) {
      console.log("Transaction not extracted", transaction);
      const url = `${backendUrl}api/hypothesis/events_extract_hypothesis/?session_id=${transaction.id}`;
      console.log("event source", eventSourceRef.current);
      if (!eventSourceRef.current) {
        setIsExtracting(true);
        console.log("got up to here 1");
        eventSourceRef.current = new EventSource(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.accessToken}`,
            "Ocp-Apim-Subscription-Key": api_subscription_key,
          },
        });

        console.log("got up to here 2");
        console.log("got up to here 3");
        console.log("got up to here 4");
        console.log("got up to here 5");
        console.log("got up to here 6");
        console.log("got up to here 7");
        console.log("got up to here 8");
        eventSourceRef.current.onmessage = (event) => {
          console.log("EVENT.DATA", event.data);
          const parsedData = JSON.parse(event.data);
          if ([201, 202].includes(parsedData.status)) {
            dispatch(addKeyHypothesis(parsedData.data));
          } else if ([300].includes(parsedData.status)) {
            eventSourceRef.current.close();
            console.log("All hypothesis has been extracted successfully!");
            setIsExtracting(false);
            enqueueSnackbar("All hypotheses have been extracted successfully!", { variant: "success" });
          } else if ([301].includes(parsedData.status)) {
            dispatch(addKeyHypothesis(parsedData.data));
            eventSourceRef.current.close();
            console.log("All hypothesis has been extracted successfully!");
            enqueueSnackbar("All hypotheses have been extracted successfully!", { variant: "success" });
            setIsExtracting(false);
          }
        };

        eventSourceRef.current.onopen = () => {
          console.log("Connection to server opened.");
        };

        eventSourceRef.current.onerror = (error) => {
          console.error("EventSource failed:", error);
          eventSourceRef.current.close();
          // eventSourceRef.current = null;
        };
      }
    }

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        // eventSourceRef.current = null;
        console.log("Cleaning up EventSource connection");
      }
      setIsExtracting(false);
    };
  }, [transaction, isLoadingExtractHypothesis, dispatch]);

  useEffect(() => {
    if (isExtracting) {
      console.log("Extraction is already in progress.");
      return;
    }

    if (reset) {
      transaction.is_extracted = "False";
    }
    // console.log(isExtracting);
    console.log(transaction);

    if (transaction.is_extracted === "False" || transaction.is_extracted === "Pending" || isLoadingExtractHypothesis || reset) {
      // if (false) {
      // Replace this condition with your actual condition check

      setIsExtracting(true);
      console.log("Transaction not extracted", transaction);

      const url = `${backendUrl}api/hypothesis/events_extract_hypothesis/?session_id=${transaction.id}`;
      console.log("Request URL:", url);
      const NewController = new AbortController();
      const { signal } = NewController;
      fetchEventSource(url, {
        method: "GET",
        signal: signal,
        headers: {
          Authorization: `Bearer ${store.getState().auth.accessToken}`,
          "Ocp-Apim-Subscription-Key": api_subscription_key,
        },
        onmessage(event) {
          console.log("EVENT.DATA", event.data);
          console.log("typo", typeof event.data);
          const parsedData = JSON.parse(event.data);
          console.log("paeedDataXX", parsedData);

          if (parsedData.status === 202 || parsedData.status === 201) {
            dispatch(addKeyHypothesis(parsedData.data));
          } else if (parsedData.status === 300) {
            NewController.abort();
            enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
            setIsExtracting(false);
          } else if (parsedData.status === 301) {
            dispatch(addKeyHypothesis(parsedData.data));
            NewController.abort();
            enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
            setIsExtracting(false);
          }
        },
        onopen() {
          console.log("Connection to server opened.");
        },
        onerror(error) {
          console.error("EventSource failed:", error);
          NewController.abort();
          setIsExtracting(false);
        },
      }).then((controller) => {
        // This will return a controller that can be used to close the connection
        eventSourceRef.current = controller;
      });

      return () => {
        console.log("Cleaning up EventSource connection");
        if (NewController) {
          NewController.abort();
        }
        setIsExtracting(false);
      };
    } else {
      console.log("Transaction already extracted or no need to extract:", transaction);

      // fetch extracted hypothesis
      // dispatch(fetchHypothesis(transaction.id));
    }

    setIsExtracting(false);
  }, [transaction, isLoadingExtractHypothesis, dispatch]);

  // EVENT SOURCE

  return (
    <Documentstart
      title={"KPI"}
      open={open}
      headerProps={{ searchQuery: searchQuery, setSearchQuery: setSearchQuery, showFilter: false, showView: false, backbutton: true, handleBackClick: handleBackClick, showDeleteButton: selectedCards.length > 0, handleDeleteSelected: () => setIsDeleteModalOpen(true) }}
    >
      {/* Fixed Header (Stack + Divider) */}

      {/* Scrollable content */}
      {/* <List> */}
      {isLoading || isLoadingExtractHypothesis || isExtracting ? (
        <Spinner />
      ) : (
        <List>
          {filteredCards.map((card, index) => (
            <CardsHypothesis
              key={card.id || index}
              card={card}
              colorIndex={index}
              isSelected={selectedCards.includes(card.id)}
              onSelectCard={handleSelectCard}
              onDelete={() => handleDeleteSingleHypothesis(card.id)}
              peer={peers ? peers[card.labels[0]] : []}
              setPeers={setPeers}
              handleReExtractHypothesis={handleReExtractHypothesis}
            />
          ))}
          {/* </List> */}
        </List>
      )}
      <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type="KPIs" open={isDeleteModalOpen} handleConfirm={handleDeleteSelected} handleClose={() => setIsDeleteModalOpen(false)} />
    </Documentstart>
  );
};

export default DealKpi;
