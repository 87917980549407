import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Typography, Box, FormControlLabel, Checkbox, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../assets/logo/logo.png"; // Assuming the logo is in the public/assets/logo directory
import MicrosoftLogo from "../../assets/signin/microsoft.png"; // Path to the Microsoft logo
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { microsoftSignin } from "../../redux/actions/authAction";
import { colors } from "../../utils/colors"; // Assuming colors are usable here directly or adapted for MUI theming
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import TermsModal from "./components/TermsModal";
import { DISCLAIMER_URL } from "../../utils/constants";
import ModalPdfView from "../../components/ModalPdfView";

const SignInContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiTextField-root": {
    marginBottom: theme.spacing(2),
  },
}));

const SignInScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { instance } = useMsal();
  const [checked, setChecked] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle sign in logic here
    // navigate("data-import");
  };

  const handleMicrosoftLogin = async () => {
    await dispatch(microsoftSignin(instance));
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SignInContainer>
      <Grid container sx={{ height: windowHeight / 1.2, width: isMobile() ? windowWidth / 1.1 : windowWidth / 1.5, borderRadius: 2, boxShadow: 3 }}>
        {/* Left Column with Logo */}
        <Grid item xs={12} sm={5} sx={{ bgcolor: colors.BLUE, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <img src={Logo} alt="Logo" style={{ maxHeight: "96px", maxWidth: "96px" }} />
          <Box sx={{ width: "100%", height: "1px", bgcolor: colors.PURPLE_TWO }}></Box>
        </Grid>

        <ModalPdfView open={open} setOpen={handleOpen} handleClose={handleClose} url={DISCLAIMER_URL} />

        {/* Right Column with Sign In Form */}
        <Grid item xs={12} sm={7} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ width: "100%", paddingInline: isMobile() ? windowWidth / 200 : windowWidth / 130 }}>
            <Button
              disabled={!checked}
              fullWidth
              variant="contained"
              startIcon={<img src={MicrosoftLogo} alt="Microsoft" style={{ maxHeight: "24px" }} />}
              onClick={handleMicrosoftLogin}
              sx={{ width: "100%", py: 2, px: 4, borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center", mb: 4 }}
            >
              Sign in with Microsoft
            </Button>

            <form noValidate onSubmit={handleSubmit}>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} color="primary" />}
                label={
                  <Typography variant="body2">
                    I agree to the <Link onClick={handleOpen}>Terms and Conditions</Link>
                  </Typography>
                }
              />
            </form>
          </Box>
        </Grid>
      </Grid>
    </SignInContainer>
  );
};

export default SignInScreen;
