import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ImportFormModal from "../screens/DataScreen/components/ImportFormModal";
import { useSelector } from "react-redux";
import NoUploadModal from "./NoUploadModal";
import { backendClient } from "../api/backend";
import { MAX_FILES_UPLOAD } from "../utils/constants";
import { Trash, UploadIcon } from "lucide-react";
import { useSnackbar } from "notistack";

const Input = styled("input")({
  display: "none",
});

const FilesUpload = ({ uploadedFiles, setUploadedFiles, is_upload }) => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isModalFormVisible, setIsModalFormVisible] = useState(false);
  const [isVisibleModalNoUpload, setIsVisibleModalNoUpload] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [totalDeals, setTotalDeals] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetch_fn = async () => {
      const totalDeals_ = await backendClient.countTransactions();
      setTotalDeals(totalDeals_["count"]);
      setIsLoadingDeals(false);
    };
    fetch_fn();
  }, []);

  const handleFileSelect = (event, isDrag = false) => {
    console.log("Selected files", event);
    const files = isDrag ? event : event.target.files;
    const totalFiles = uploadedFiles.length + files.length;
  
    // Ensure file limit is not exceeded
    if (totalFiles > MAX_FILES_UPLOAD) {
      alert(`You cannot upload more than ${MAX_FILES_UPLOAD} files in this environment.`);
      return;
    }
  
    // Filter only PDFs and prevent duplicates
    const newFiles = Array.from(files).filter((file) => {
      const isDuplicate = uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name);
      
      if (isDuplicate) {
        // Show a warning Snackbar if a duplicate is detected
        enqueueSnackbar(`You cannot upload the file "${file.name}" more than one time!`, { variant: "warning" });
        return false; // Skip the duplicate file
      }
      
      return file.type === "application/pdf"; // Only allow PDFs
    });
  
    if (newFiles.length > 0) {
      setUploadedFiles((prev) => [...prev, ...newFiles]);
      enqueueSnackbar(`${newFiles.length} file(s) added successfully`, { variant: "success" });
    }
  };
  

  const handleNoUploadPossible = () => {
    setIsVisibleModalNoUpload(true);
  };

  const handleRemoveAllFiles = () => {
    setUploadedFiles([]);
    enqueueSnackbar("All files removed", { variant: "success" });
  };

  const handleUpload = async () => {
    const totalFiles = uploadedFiles.length;
    if (totalFiles > MAX_FILES_UPLOAD && user.organization_id !== "k-fin") {
      alert(`You cannot upload more than ${MAX_FILES_UPLOAD} files in this test environment.`);
      return;
    }
    setIsModalFormVisible(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    console.log("Dropped files", event.dataTransfer.files);
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    handleFileSelect(files, true);
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleCloseImportModal = () => {
    if (!isSubmitLoading) {
      setIsModalFormVisible(false);
    }
  };

  return (
    <div>
      {/* Drag and Drop Area */}
      <section className="flex flex-col items-center justify-center p-6 h-64 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer transition-all duration-300 ease-in-out hover:border-blue-500 hover:bg-blue-50 group" onDragOver={handleDragOver} onDrop={handleDrop} 
      style={{ transition: 'all 0.3s ease-in-out' }}
      >
        <NoUploadModal open={isVisibleModalNoUpload} handleClose={() => setIsVisibleModalNoUpload(false)} />
        <ImportFormModal isSubmitLoading={isSubmitLoading} setIsSubmitLoading={setIsSubmitLoading} uploadedFiles={uploadedFiles} open={isModalFormVisible} handleClose={handleCloseImportModal} />

        {uploading ? (
          <CircularProgress size={48} thickness={4} />
        ) : (
          <label htmlFor="file-upload-button" className="flex flex-col items-center justify-center h-full w-full cursor-pointer">
            <input accept="application/pdf" multiple id="file-upload-button" type="file" onChange={handleFileSelect} className="hidden" />
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <div className="rounded-full border border-dashed p-3 transition-all duration-300 ease-in-out hover:border-blue-500 hover:bg-blue-50">
                <UploadIcon className="size-7 text-muted-foreground transition-all duration-300 ease-in-out hover:text-blue-500" aria-hidden="true" />
              </div>
              <div className="space-y-2">
                <p className="font-medium text-sm text-gray-500 transition-all duration-300 ease-in-out hover:text-blue-500">
                  Drag and drop files here or <span className="text-blue-500 underline cursor-pointer hover:text-blue-700">click to select files</span>
                </p>
              </div>
              {user.organization_id !== "k-fin" && parseInt(totalDeals) > 3 && <p className="text-xs text-gray-400 mt-2">Upload limit reached. Contact support for more information.</p>}
            </div>
          </label>
        )}
      </section>

      <div style={{ marginTop: "20px" }}></div>

      {/* Add Files and Upload Buttons */}
      <div className="flex justify-end items-center space-x-2">
        {uploadedFiles?.length > 0 && (
          <Button
            variant="outlined"
            onClick={handleRemoveAllFiles}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: "white",
              color: "black",
              boxShadow: "none",
              borderRadius: "8px",
              cursor: "pointer",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                bgcolor: "black", // or "white" for the Upload button
                color: "white", // or "black" for the Upload button
                transform: "translateY(-2px)",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Trash className="mr-2" /> Cancel Selection
          </Button>
        )}

        {uploadedFiles?.length > 0 && is_upload && (
          <Button
            variant="outlined"
            onClick={handleUpload}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: "black",
              color: "white",
              boxShadow: "none",
              borderRadius: "8px",
              "&:hover": { bgcolor: "black", color: "white" },
            }}
          >
            Upload
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilesUpload;
