// we have a system where :
// . We import files. Each files are linkedin to a "transaction".
// . A transaction contains : id, title, industry, country, description and date.
// . When we upload files, user is asked to fill form with title, industry, description and date in order to create a transaction.
// . Transaction will be stored in PGSQL - AWS
// . Transaction can also be loaded from PGSQL - AWS.
// create transactionAction and transactionReducer

import { backendClient } from "../../api/backend";
import { CLEAR_EXPLORED_DATA, EXPLORE, SET_FILTRED_EXPLORED_DATA } from "./types";

export const exploreTransactions = (exploreData, cancelToken) => {
  return async (dispatch, getState) => {
    try {
      let nodes = await backendClient.explore(exploreData, cancelToken);
      if (cancelToken.isCancelled()) {
        throw new Error('Cancelled by the user');
      }
      nodes.forEach((node) => {
        if (node.metadata && node.metadata.text) {
          node.text = node.metadata.text;
        }
      });
      dispatch({
        type: EXPLORE,
        payload: nodes,
      });
      dispatch({
        type: SET_FILTRED_EXPLORED_DATA,
        payload: nodes,
      });
      return nodes;
    } catch (error) {
      if (!cancelToken.isCancelled()) {
        console.error("An error occurred:", error);
      }
    }
  };
};


export const setFiltredExploredData = (filtredExploreData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_FILTRED_EXPLORED_DATA,
      payload: filtredExploreData,
    });
  };
};

export const clearExploredData = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_EXPLORED_DATA,
    });
  };
};
