
const initialState = {
  is_open: false,
};
const navBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OPEN": {
      return {
        ...state,
        is_open: action.payload,
      };
    }
    default:
      return state;
  }
};

export default navBarReducer;
