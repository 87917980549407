import { Card, CardContent, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography, Button, Box, Checkbox } from "@mui/material";
import { SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../utils/colors";
import { Paper } from "@material-ui/core";
import KeyIcon from "@mui/icons-material/Key"; // Icon representing empty state
import { Delete, Search } from "@material-ui/icons";
import { X } from "@mui/icons-material";
import AddKeywordPairsFormModal from "./components/AddKeywordPairsFormModal";
import { fetchKeywords } from "../../redux/actions/keywordAction";
import { backendClient } from "../../api/backend";
import SearchBar from "../../components/SearchBar";
import Header from "../../components/Header";
import Spinner from "../../components/Spinner";
import CustomButton from "../../components/CustomButton";
import Documentstart from "../../components/Documentstart";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

const KeywordPairCard = ({ title, keyword, color }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ mb: 2, width: "200px" }}>
      <Card
        sx={{
          height: { xs: 120, sm: 250 },
          margin: 2,
          borderRadius: 5,
          boxShadow: 0,
          bgcolor: color,
        }}
      >
        <CardContent
          sx={{
            padding: "4px",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "& .MuiCardContent-root": {
              paddingBottom: 0,
            },
          }}
        >
          {title === "Industry" && (
            <Typography
              variant="h4"
              sx={{
                color: colors.BLACK,
                fontWeight: "bold",
              }}
            >
              {keyword.industry}
            </Typography>
          )}
          {title === "Is Key" && (
            <Typography
              variant="h4"
              sx={{
                color: colors.BLACK,
                fontWeight: "bold",
              }}
            >
              {keyword.is_key ? "Yes" : "No"}
            </Typography>
          )}
          {title === "Example" && (
            <Typography
              variant="h4"
              sx={{
                color: colors.BLACK,
                fontWeight: "bold",
              }}
            >
              {keyword.example}
            </Typography>
          )}
          {title === "Synchronized" && (
            <Typography
              variant="h4"
              sx={{
                color: colors.BLACK,
                fontWeight: "bold",
              }}
            >
              {keyword.synchronized ? "✅" : "❌"}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const KeywordSection = ({ keyword, open, colorIndex, isSelected, onSelect }) => {
  const detailColors = ["#1AB5B8", "#B81A80"];
  const cardBackgroundColor = detailColors[colorIndex % 2];

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        p: 2,
        mb: 2,
        bgcolor: colors.WHITE,
        borderRadius: 2,
        width: "100%",
        boxShadow: "none",
        "& .MuiPaper-root": {
          borderRadius: 4,
        },
      }}
    >
      {/* Checkbox only before the title */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <Checkbox checked={isSelected} onChange={() => onSelect(keyword.id)} sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
        <Typography variant="h5" gutterBottom sx={{ color: `${cardBackgroundColor} !important`, mb: 2, ml: 2, fontWeight: "bold" }}>
          {keyword.name}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <KeywordPairCard title={"Industry"} keyword={keyword} color={colors.GRAY_TWO} />
        <KeywordPairCard title={"Is Key"} keyword={keyword} color={`${cardBackgroundColor} !important`} />
        <KeywordPairCard title={"Example"} keyword={keyword} color={colors.GRAY_TWO} />
        <KeywordPairCard title={"Synchronized"} keyword={keyword} color={`${cardBackgroundColor} !important`} />
      </Grid>
    </Grid>
  );
};

const NewKeywordPairs = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const keywords = useSelector((state) => state.keywords.keywords);
  const dispatch = useDispatch();
  const [isLoadingKeywords, setIsLoadingKeywords] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isModalAddKeywordPairVisible, setIsModalAddKeywordPairVisible] = useState(false);
  const [synchronize, setSynchronize] = useState({ synchronized: true });
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); // Modal visibility state

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value.toLowerCase());
  };

  const handleSelectKeyword = (keywordId) => {
    setSelectedKeywords((prevSelected) => (prevSelected.includes(keywordId) ? prevSelected.filter((id) => id !== keywordId) : [...prevSelected, keywordId]));
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const handleAddKeywordPair = async () => {
    if (isLoadingKeywords || isLoadingDelete) {
      return;
    }
    setIsModalAddKeywordPairVisible(true);
  };

  const handleDeleteSelected = () => {
    setIsDeleteModalVisible(true); // Open the delete modal
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await backendClient.deleteKeywords(selectedKeywords);
      setSelectedKeywords([]);
      await dispatch(fetchKeywords(1, 5)); // Refresh the list after deletion
    } catch (error) {
      console.error("Failed to delete keywords:", error);
    } finally {
      setIsLoadingDelete(false);
      setIsDeleteModalVisible(false); // Close the modal after deletion
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false); // Close the modal without deleting
  };

  useEffect(() => {
    const loadKeywords = async () => {
      setIsLoadingKeywords(true);
      try {
        await dispatch(fetchKeywords(1, 5));
        if (totalKeywords === 0) {
          const totalKeywords_ = await backendClient.countKeywords();
          setTotalKeywords(totalKeywords_["count"]);
        }
      } catch (error) {
        console.error("Failed to fetch keywords:", error);
      } finally {
        setIsLoadingKeywords(false);
      }
    };

    loadKeywords();
  }, [dispatch]);

  const filteredKeywords = keywords.filter((keyword) => keyword.name.toLowerCase().includes(searchQuery));

  return (
    <Documentstart
      title={"Custom KPI"}
      open={open}
      headerProps={{
        showFilter: false,
        showView: false,
        showSearchBar: true,
        searchQuery: searchQuery,
        setSearchQuery: setSearchQuery,
        custombutton: "true",
        custombuttononclick: handleAddKeywordPair,
        showDeleteButton: selectedKeywords.length > 0,
        handleDeleteSelected: () => setIsDeleteModalVisible(true),
      }}
    >
      {isLoadingKeywords || isLoadingDelete ? (
        <Grid
          item
          xs={12}
          md={open ? 9 : 12}
          lg={open ? 12 : 12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "auto",
          }}
        >
          <Spinner />
        </Grid>
      ) : keywords.length > 0 ? (
        <Grid
          item
          xs={12}
          md={open ? 9 : 12}
          lg={open ? 12 : 12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "auto",
          }}
        >
          {filteredKeywords.map((keyword, index) => (
            <KeywordSection key={keyword.id} keyword={keyword} open={open} colorIndex={index} isSelected={selectedKeywords.includes(keyword.id)} onSelect={handleSelectKeyword} />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <div>
            <KeyIcon style={{ fontSize: 40, color: "lightgray" }} />
            <Box sx={{ marginBottom: 2 }}>No Custom KPIs created yet.</Box>
            {/* <Button
                variant="contained"
                onClick={handleAddKeywordPair}
                sx={{
                  width: "200px",
                  height: "50px",
                  bgcolor: "black",
                  color: "white",
                  marginTop: 2,
                  boxShadow: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: "black",
                  },
                }}
              >
                Create a KPI
              </Button> */}
            <CustomButton onClick={handleAddKeywordPair} backgroundColor="black" color="white" hoverBackgroundColor="black">
              Create a KPI
            </CustomButton>
          </div>
        </Grid>
      )}

      <Grid sx={{ p: 2, bgcolor: colors.WHITE, width: "100%", height: "100%" }}>
        {/* Pass setSynchronize to the modal */}
        <AddKeywordPairsFormModal setSynchronize={setSynchronize} open={isModalAddKeywordPairVisible} setOpen={setIsModalAddKeywordPairVisible} />
      </Grid>
      {/* MODALS */}
      <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Keyword Pairs"} open={isDeleteModalVisible} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />
    </Documentstart>
  );
};

export default NewKeywordPairs;
