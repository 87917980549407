import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography, Button, Grid, Rating, Avatar, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../utils/colors";
import { OpenCalendar, isMobile, windowHeight, windowWidth } from "../../../utils/utils";

const TestimonialContainer = styled(Container)(({ theme }) => ({
  flexDirection: "row", // Changed to row to place items side by side
  alignItems: "flex-start", // Align items at the start of the container
  justifyContent: "space-between",
  padding: theme.spacing(4),
  backgroundColor: "#fff",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column", // Stack items on top of each other on smaller screens
  },
}));

const Testimonial = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "300px",
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
}));

const UserInformation = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1rem 0",
  padding: "0 4rem",
});

const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: colors.LGREEN, // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});

// Sample data for the carousel
const testimonials = [
  {
    name: "Managing Director",
    title: "Mid-Cap Private Equity",
    testimonial: "It's a good product. The deal team was not able to answer a due-diligence question in the committee so we used DueDil to solve the issue instantly.",
    imageSrc: "/path-to-image.jpg",
  },
  {
    name: "Analyst",
    title: "Mid-Cap Private Equity",
    testimonial: "I used to spend hours looking up data in folders. With DueDil, I can now retrieve all the information instantly and reliably.",
    imageSrc: "/path-to-image.jpg",
  },
  {
    name: "Vice-President",
    title: "Mid-Cap Private Equity",
    testimonial: "We were really short staffed for a transaction so we decided to use DueDil. The speed and accuracy of the result were impressive.",
    imageSrc: "/path-to-image.jpg",
  },
  // Add more testimonial objects here
];

const SectionSix = React.forwardRef((props, ref) => {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...(isMobile() && { paddingBottom: 20 }), // Conditionally apply bottom margin if isMobile is true
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", pb: 20, pt: 13, px: isMobile() ? 3 : windowWidth / 40 }}>
      <Grid item spacing={4} xs={12} md={6} alignItems="center" sx={{ ...(isMobile() && { paddingTop: -windowHeight / 10 }) }}>
        {/* <Grid item xs={12} md={6} sx={{ ...(isMobile() && { paddingBottom: windowHeight / 120 }) }}>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <Box key={index} sx={{ textAlign: "center" }}>
                <Avatar alt={testimonial.name} sx={{ width: 90, height: 90, marginBottom: "1rem", borderRadius: "12px", margin: "auto" }} />
                <UserInformation>
                  <Typography variant="h6" component="h3" sx={{ fontWeight: "bold" }}>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: "bold" }}>
                    {testimonial.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {testimonial.testimonial}
                  </Typography>
                </UserInformation>
                <Rating name="read-only" value={5} readOnly size="large" />
              </Box>
            ))}
          </Slider>
        </Grid> */}
        {/* <Grid item xs={12} md={6}> */}
        <Box sx={{ width: "40px", height: "4px", bgcolor: colors.LGREEN, mb: 2 }}></Box>
        <Typography variant="h3" component="h3" sx={{ fontWeight: "bold", mb: 0 }}>
          See the Difference Yourself.
        </Typography>
        <Typography variant="h3" component="h3" sx={{ fontWeight: "bold", mb: 3, color: colors.LGREEN }}>
          Schedule a Free Demo
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {
            "Introducing the leading conversational platform in the financial sector, crafted to interact seamlessly with your data and deliver customized benchmarks tailored to your specific inquiries. Our solution empowers your investment team to focus on strategic decision-making by automating labor-intensive tasks."
          }
        </Typography>
        {/* <Typography variant="body1" color="textSecondary" paragraph>
            {"1) Reduce costs"}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {"2) Move Faster"}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {"3) Eliminate the need for redundant efforts"}
          </Typography> */}

        <StyledButton onClick={OpenCalendar} variant="contained" sx={{ backgroundColor: colors.LGREEN, color: "white", textTransform: "none", marginTop: isMobile() ? windowHeight / 320 : windowHeight / 500 }}>
          Book a demo
        </StyledButton>
        {/* </Grid> */}
      </Grid>
    </Box>
  );
});

export default SectionSix;
