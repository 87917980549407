import { Box, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Send } from "lucide-react";
import React, { useRef, useState } from "react";

const StyledInput = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    height: "40px",
    paddingTop: "12px",
    paddingBottom: "12px",
    fontSize: "19px",
    color: "#ccc",
    borderRadius: "21px",
  },
}));

const ChatInput = ({ setValue, value, inputRef }) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!value) return;

      console.log("value", value);
      setValue("");
    }
  };
  return (
    <StyledInput
      inputRef={inputRef}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onKeyDown={handleKeyPress}
      fullWidth
      placeholder="Write a message... "
      variant="filled"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Send">
              <IconButton>
                {" "}
                <Send size={24} color="#fff" />{" "}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

const Chatbox = () => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);

  return (
    <Stack direction="row" alignItems={"center"} spacing={3} sx={{ backgroundColor: "#ccc", borderRadius: "21px" }}>
     
     <TextField
        inputRef={inputRef}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            if (!value) return;

            console.log("value", value);
            setValue("");
          }
        }}
        fullWidth
        placeholder="Write a message... "
        variant="filled"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Send">
                <IconButton>
                  <Send size={24} color="#fff" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        />
        
        <ChatInput setValue={setValue} value={value} inputRef={inputRef} />
    </Stack>
  );
};

export default Chatbox;
