import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from "@mui/material";
import { ChevronLeft, ChevronRight, PanelsTopLeft } from "lucide-react";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import DataIcon from "@mui/icons-material/InsertDriveFile";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HypothesisIcon from "@mui/icons-material/Assessment";
import ConversationIcon from "@mui/icons-material/Chat";
import WebIcon from "@mui/icons-material/Web";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { setOpen } from "../redux/actions/navBarActions";
import Logo from "../assets/logo/logo.png";

const CenteredDrawer = styled(Drawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    width: open ? 240 : 75,
    height: "80%",
    maxHeight: "80vh",
    position: "fixed",
    left: 10,
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: 18,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "#000000",
    color: "white",
    display: "flex",
    flexDirection: "column",
  },
}));

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const open = useSelector((state) => state.navBar.is_open);
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSelected = (path) => location.pathname === path || location.pathname.split("/")[1] === path.split("/")[1];

  const pages = [
    { text: "Dashboard", icon: <SpaceDashboardIcon />, path: "/dashboard" },
    { text: "Data import", icon: <DataIcon />, path: "/data-import" },
    { text: "Deals", icon: <AccountBalanceWalletIcon />, path: "/deals" },
    { text: "Due Diligence", icon: <AccountBalanceIcon />, path: "/duediligence" },
    // { text: "Custom KPIs", icon: <HypothesisIcon />, path: "/keyword-pairs" },
    { text: "Templates", icon: <WebIcon />, path: "/duediligencetemplate" },
    { text: "Conversation", icon: <ConversationIcon />, path: "/chat" },
  ];

  const handleDrawer = () => {
    dispatch(setOpen(!open));
  };

  const NavItem = ({ text, icon, path }) => (
    <Tooltip title={open ? "" : text} placement="right">
      <ListItem
        button
        component={Link}
        to={path}
        sx={{
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          my: 0.5,
          backgroundColor: isSelected(path) ? "rgba(255, 255, 255, 0.2)" : "transparent",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", color: "inherit" }}>{icon}</ListItemIcon>
        {open && <ListItemText primary={text} />}
      </ListItem>
    </Tooltip>
  );

  return (
    <>
      <IconButton
        onClick={handleDrawer}
        sx={{
          position: "fixed",
          top: "15%",
          left: open ? 230 : 65,
          zIndex: 1201,
          backgroundColor: "#000000",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#333333",
          },
        }}
      >
        {open ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
      <CenteredDrawer variant="permanent" open={open}>
        <div className="flex flex-col h-full justify-between py-4">
          <div className="flex flex-col items-center">
            <div onClick={() => navigate("/")} className="mb-4">
              <img src={Logo} alt="Logo" className="h-12 w-12" />
            </div>
            <List className="flex-grow">
              {pages.slice(0, Math.ceil(pages.length)).map((page) => (
                <NavItem key={page.text} {...page} />
              ))}
            </List>
          </div>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignContent: "center",
            }}
          >
            <ListItem
              button
              component={Link}
              to="/settings"
              sx={{
                justifyContent: open ? "between" : "center",
                px: 2.5,
                my: 0.5,
                backgroundColor: isSelected("/settings") ? "rgba(255, 255, 255, 0.2)" : "transparent",
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "0", color: "inherit" }}>
                <SettingsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Settings" />}
            </ListItem>
          </List>
        </div>
      </CenteredDrawer>
    </>
  );
};

export default NavigationBar;
