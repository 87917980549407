import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography, Chip, IconButton, Divider, Stepper, Step, StepLabel, FormControl, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import { Delete, Close as CloseIcon } from "@mui/icons-material";

const options = ["Query the dataroom", "Query past internal papers", "Query similar transactions", "Answer the query from the web", "Ask ChatGPT", "Tabularize results"];

const initialWorkstreamData = {
  phase: "",
  name: "",
  questions: [],
};

const WorkstreamModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [workstreamData, setWorkstreamData] = useState(initialWorkstreamData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setWorkstreamData(initialWorkstreamData);
      setErrors({});
    }
  }, [open]);

  const handleInputChange = useCallback((field, value) => {
    setWorkstreamData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  }, []);

  const handleQuestionAdd = useCallback((e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      setWorkstreamData((prev) => ({
        ...prev,
        questions: [...prev.questions, { text: e.target.value.trim(), automations: [] }],
      }));
      setErrors((prev) => ({ ...prev, questions: "" }));
      e.target.value = "";
    }
  }, []);

  const handleQuestionDelete = useCallback((deletedQuestionIndex) => {
    setWorkstreamData((prev) => ({
      ...prev,
      questions: prev.questions.filter((_, index) => index !== deletedQuestionIndex),
    }));
  }, []);

  const handleAutomationChange = useCallback((questionIndex, option) => {
    setWorkstreamData((prev) => {
      const newQuestions = [...prev.questions];
      const question = newQuestions[questionIndex];
      const newAutomations = question.automations.includes(option) ? question.automations.filter((a) => a !== option) : [...question.automations, option];
      newQuestions[questionIndex] = { ...question, automations: newAutomations };
      return { ...prev, questions: newQuestions };
    });
  }, []);

  const validateStep = useCallback(
    (step) => {
      const newErrors = {};
      if (step === 0) {
        if (!workstreamData.phase) newErrors.phase = "Please select a phase";
        if (!workstreamData.name) newErrors.name = "Please enter a workstream name";
        if (workstreamData.questions.length === 0) newErrors.questions = "Please add at least one question";
      } else if (step > 0 && step <= workstreamData.questions.length) {
        const questionIndex = step - 1;
        if (workstreamData.questions[questionIndex].automations.length === 0) {
          newErrors.automations = "Please select at least one automation method";
        }
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    },
    [workstreamData],
  );

  const handleNext = useCallback(() => {
    if (validateStep(activeStep)) {
      setActiveStep((prev) => prev + 1);
    }
  }, [activeStep, validateStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const handleFinish = useCallback(() => {
    if (validateStep(activeStep)) {
      console.log("Workstream data:", workstreamData);
      onClose();
    }
  }, [activeStep, validateStep, workstreamData, onClose]);

  const isNextDisabled = useMemo(() => {
    if (activeStep === 0) {
      return !workstreamData.phase || !workstreamData.name || workstreamData.questions.length === 0;
    } else if (activeStep > 0 && activeStep <= workstreamData.questions.length) {
      return workstreamData.questions[activeStep - 1].automations.length === 0;
    }
    return false;
  }, [activeStep, workstreamData]);

  const steps = useMemo(() => {
    return ["Basic Information", ...workstreamData.questions.map((_, index) => `Question ${index + 1} Answer`)];
  }, [workstreamData.questions]);

  const renderStepContent = (step) => {
    if (step === 0) {
      return (
        <>
          <FormControl fullWidth margin="normal" error={!!errors.phase}>
            <Typography variant="subtitle1">Phase:</Typography>
            <TextField select value={workstreamData.phase} onChange={(e) => handleInputChange("phase", e.target.value)} fullWidth sx={{ bgcolor: "#f7f7f7" }} error={!!errors.phase}>
              <MenuItem value="" disabled>
                Select Phase
              </MenuItem>
              <MenuItem value="Phase 01">Phase 01</MenuItem>
              <MenuItem value="Phase 02">Phase 02</MenuItem>
            </TextField>
            {errors.phase && <FormHelperText>{errors.phase}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin="normal" error={!!errors.name}>
            <Typography variant="subtitle1">Workstream name:</Typography>
            <TextField value={workstreamData.name} onChange={(e) => handleInputChange("name", e.target.value)} placeholder="Name of the workstream" fullWidth sx={{ bgcolor: "#f7f7f7" }} error={!!errors.name} />
            {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin="normal" error={!!errors.questions}>
            <Typography variant="subtitle1">Questions:</Typography>
            <TextField placeholder="Type a question and press Enter" fullWidth onKeyDown={handleQuestionAdd} sx={{ bgcolor: "#f7f7f7" }} />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
              {workstreamData.questions.map((question, index) => (
                <Chip key={index} label={question.text} onDelete={() => handleQuestionDelete(index)} deleteIcon={<Delete />} />
              ))}
            </Box>
            {errors.questions && <FormHelperText>{errors.questions}</FormHelperText>}
          </FormControl>
        </>
      );
    } else if (step > 0 && step <= workstreamData.questions.length) {
      const questionIndex = step - 1;
      const question = workstreamData.questions[questionIndex];

      return (
        <>
          <Typography variant="subtitle1">{`How should the following question be answered?`}</Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
            {question.text}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {options.map((option, index) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={question.automations.includes(option)} onChange={() => handleAutomationChange(questionIndex, option)} />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{`${index + 1}. ${option}`}</Typography>
                  </Box>
                }
              />
            ))}
          </Box>
          {errors.automations && <FormHelperText error>{errors.automations}</FormHelperText>}
        </>
      );
    }

    return null;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          New Due Diligence Workstream
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3, py: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: "black",
            borderRadius: "6px",
            fontSize: 16,
            padding: "10px 20px",
          }}
        >
          Cancel
        </Button>
        <Box>
          {activeStep > 0 && (
            <Button
              onClick={handleBack}
              sx={{
                color: "black",
                borderRadius: "6px",
                fontSize: 16,
                padding: "10px 20px",
                mr: 1,
              }}
            >
              Back
            </Button>
          )}
          <Button
            onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
            disabled={isNextDisabled || activeStep === steps.length - 1}
            sx={{
              bgcolor: "black",
              color: "white",
              borderRadius: "6px",
              "&:hover": {
                bgcolor: "black",
                opacity: 0.9,
              },
              "&:disabled": {
                bgcolor: "grey.500",
                color: "white",
              },
              fontSize: 16,
              padding: "10px 20px",
            }}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default WorkstreamModal;
