import { Box } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Button, Card, Chip, Collapse, Divider, Grid, List, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Check, ChevronLeft, Plus } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";

const NewDueDiligence = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const [openList, setOpenList] = useState(false);

  const handleClick = () => {
    setOpenList(!openList);
  };

  const renderCard = (title, buttonText) => (
    <Card sx={{ width: "100%", bgcolor: "#f5f5f5", borderRadius: "10px", padding: "20px" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" sx={{ fontWeight: "bold", color: "#000" }}>
          {title}
        </Typography>
        <List component="nav">
          <ListItemButton onClick={handleClick}>
            {openList ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary="See more" />
          </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Nested Item 1" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Nested Item 2" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <Button variant="contained" width="100%" sx={{ bgcolor: "#000", px: "82px", fontWeight: "bold" }}>
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );

  const RenderStatusColumn = (title, items) => {
    console.log(title);
    const [user, setUser] = React.useState(false);

    React.useEffect(() => {
      if (title === "Red Flags") {
        setUser(true);
      }
    }, [title]);
    return (
      <Stack direction="column" spacing={6}>
        <Typography variant="h5" sx={{ mt: 2, mb: 2, ml: 2, fontWeight: "bold", color: "#000" }}>
          {title}
        </Typography>
        <Card sx={{ width: "100%", height: "100%", bgcolor: "#f5f5f5", borderRadius: "10px", padding: "20px" }} alignItems={"center"}>
          <Stack spacing={user ? 12 : 9} alignItems="center" justifyContent="space-around">
            {items}
          </Stack>
        </Card>
      </Stack>
    );
  };

  return (
    <Grid container sx={{ height: "100%", width: "95%", margin: 2, marginLeft: 6 }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ marginLeft: open ? "240px" : "75px" }}>
        <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} spacing={2}>
            <Button
              startIcon={<ChevronLeft color="black" />}
              sx={{
                color: "black",
              }}
            >
              Back
            </Button>
            <Typography
              variant="h4"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "black",
              }}
            >
              Due Diligence 01
            </Typography>
          </Stack>
          <CustomButton backgroundColor="#000" color="#FFF" padding="10px 24px" sx={{ marginLeft: "380px", px: "82px" }}>
            Create Internal Document
          </CustomButton>
        </Stack>
        <Divider sx={{ marginTop: 1, marginBottom: 2, borderBottomWidth: 3, bgcolor: "#000" }} />
        <Stack
          spacing={1}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <Stack direction={"row"} spacing={4} justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="h5"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Process
            </Typography>{" "}
            <Box display="flex" alignItems="center">
              <Box sx={{ width: "100%", mr: 1, position: "relative" }}>
                <LinearProgress
                  variant="determinate"
                  value={70}
                  sx={{
                    width: "400px",
                    height: 18,
                    borderRadius: 6,
                    border: "1px solid #000",
                    backgroundColor: "lightgrey",
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 6,
                      backgroundColor: "#1AB5B8",
                    },
                  }}
                />
              </Box>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              70%
            </Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#000",
              px: { xs: "20px", sm: "40px", md: "82px" },
              fontWeight: "bold",
              width: { xs: "100%", sm: "300px", md: "390px" },
              height: { xs: "20px", sm: "35px", md: "45px" },
            }}
          >
            Create new Task
          </Button>
        </Stack>

        <Card sx={{ width: "50%", height: "250px", bgcolor: "#f5f5f5", borderRadius: "10px", padding: "20px", marginBottom: 4 }}>
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"} height={"100%"}>
            <Stack spacing={2} width={"60%"}>
              <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000" }}>
                Your due diligence optimization score
              </Typography>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor: "#000",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h1" sx={{ fontWeight: "semibold", color: "#fff" }}>
                  90%
                </Typography>
              </Card>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={2} width={"100%"}>
              <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000" }}>
                Improve your score by following recommendations in the section bellow :{" "}
              </Typography>
              <Card sx={{ width: "100%", bgcolor: "#fff", borderRadius: "10px", padding: 1 }}>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  Are there any repeat users of the business ? Is there a way to make sure they are loyal?{" "}
                </Typography>
              </Card>
              <Card sx={{ width: "100%", bgcolor: "#fff", borderRadius: "10px", p: 1 }}>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  Is the revenue of the business consolidated within a few customers ? Or is it spread out over a larger customer base?
                </Typography>
              </Card>
            </Stack>
          </Stack>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={7}>
            <Stack spacing={2} mt={10}>
              {renderCard("Valuation methodology", "Open conversation")}
              {renderCard("Revenue Growth Assumptions", "Open conversation")}
              {renderCard("Competitors Overview", "Open conversation")}
              {renderCard("Cost Benchmarking", "Open conversation")}
              {renderCard("Industry Trends", "Open conversation")}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Stack direction="row" spacing={2}>
              {RenderStatusColumn("Red Flags", [
                <Chip
                  key={1}
                  label="0 Red Flags"
                  sx={{
                    bgcolor: "green",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />,
                <Chip
                  key={2}
                  label="2 Red Flags"
                  sx={{
                    bgcolor: "orange",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />,
                <Chip
                  key={3}
                  label="2 Red Flags"
                  sx={{
                    bgcolor: "orange",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />,
                <Chip
                  key={4}
                  label="7 Red Flags"
                  sx={{
                    bgcolor: "red",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />,
                <Chip
                  key={5}
                  label="10 Red Flags"
                  sx={{
                    bgcolor: "red",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                />,
              ])}
              {RenderStatusColumn("Confidence", [
                <Card key={1} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff" }}>95%</Typography>
                </Card>,
                <Card key={2} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff" }}>95%</Typography>
                </Card>,
                <Card key={3} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff" }}>95%</Typography>
                </Card>,
                <Card key={4} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff" }}>95%</Typography>
                </Card>,
                <Card key={5} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ color: "#fff" }}>75%</Typography>
                </Card>,
              ])}
              {RenderStatusColumn("Status", [
                <Card key={1} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Check size={34} color="#fff" />
                </Card>,
                <Card key={2} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%" }} />,
                <Card key={3} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%" }} />,
                <Card key={4} sx={{ width: "50px", height: "50px", bgcolor: "red", borderRadius: "50%" }} />,
                <Card key={5} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Check size={34} color="#fff" />
                </Card>,
              ])}
              {RenderStatusColumn("User Input", [
                <Card key={1} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Check size={34} color="#fff" />
                </Card>,
                <Card key={2} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%" }} />,
                <Card key={3} sx={{ width: "50px", height: "50px", bgcolor: "orange", borderRadius: "50%" }} />,
                <Card key={4} sx={{ width: "50px", height: "50px", bgcolor: "red", borderRadius: "50%" }} />,
                <Card key={5} sx={{ width: "50px", height: "50px", bgcolor: "green", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Check size={34} color="#fff" />
                </Card>,
              ])}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewDueDiligence;
