import { useSelector } from "react-redux";
import { Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DealsSummary from "./components/DealsSummary";
import Analysis from "./components/Analysis";
import SectorPerformance from "./components/SectorPerformance";
import { colors } from "../../utils/colors";
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import Documentstart from "../../components/Documentstart";

const DashboardScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Documentstart title={"Dashboard"} open={open} headerProps={{ showFilter: false, showView: false, showSearchBar: false }}>
      <Grid
        item
        xs={12}
        sx={{
          p: 2,
          mb: 2,
          bgcolor: colors.WHITE,

          borderRadius: 2,
          width: "100%",
        }}
      >
        <Analysis />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          p: 2,
          mb: 2,
          bgcolor: colors.WHITE,

          borderRadius: 2,
          width: "100%",
        }}
      >
        <DealsSummary />
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 7, paddingX: 5, paddingBottom: 5, bgcolor: colors.WHITE, borderRadius: 2, height: "400px", width: "100%" }}>
        <SectorPerformance />
      </Grid>
    </Documentstart>
  );
};

export default DashboardScreen;
