import React, { useState } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton, Tooltip, Grid, Box, Typography, Divider, InputAdornment, TextField, Checkbox, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { Search } from "@material-ui/icons";
import { X, Trash } from "lucide-react";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { createQuestion, deleteQuestions, updateQuestion } from "../../redux/actions/templatequestionAction";
import Documentstart from "../../components/Documentstart";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import EditQuestionDialog from "./templatecomponents/EditQuestionDialog";
// import { useLocation } from 'react-router-dom';

const QuestionnaireTemplate = () => {
  const { questionnaireId } = useParams();
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  console.log(questionnaireId);
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const { questionnaires: initialQuestionnaires } = location.state || {};
  const [questionnaires, setQuestionnaires] = useState(initialQuestionnaires || []);
  const state = useSelector((state) => state);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null); // Store the question to edit
  const [questionToDelete, setQuestionToDelete] = useState(null); // Store the question to delete
  console.log(questionnaires);
  console.log(state);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);

  const { selectedIndustry } = location.state || {};

  const handleBackClick = () => {
    // Pass the selectedIndustry when navigating back to the Due Diligence Template page
    navigate("/duediligencetemplate", { state: { selectedIndustry } });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0); // Reset to first page when search query changes
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setPage(0); // Reset to first page when search is cleared
  };

  const filteredQuestionnaires = searchQuery ? questionnaires.filter((q) => (q.title ? q.title.toLowerCase().includes(searchQuery) : false)) : questionnaires;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = questionnaires.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleAddQuestionnaire = async (questionnaireId, questionData) => {
    try {
      // Assuming questionData contains the necessary fields for creating a question
      const newQuestion = await dispatch(createQuestion({ template_questionnaire_id: questionnaireId, ...questionData }));
      setQuestionnaires((prev) => [...prev, newQuestion.payload]); // Assuming the new question is in the payload

      enqueueSnackbar("Question added successfully!", { variant: "success" });
    } catch (error) {
      console.error("Failed to add question:", error);
      enqueueSnackbar("Error adding question.", { variant: "error" });
    }
  };
  const handleDeleteQuestionnaires = async (questionIds) => {
    try {
      // Dispatch the action to delete multiple questions
      await dispatch(deleteQuestions(questionIds)); // Modify the action to handle multiple questions

      // Update the local state to remove the deleted questions
      setQuestionnaires((prev) => prev.filter((q) => !questionIds.includes(q.id)));

      enqueueSnackbar("Questions deleted successfully!", { variant: "success" });
    } catch (error) {
      console.error("Failed to delete questions:", error);
      enqueueSnackbar("Error deleting questions.", { variant: "error" });
    }
  };
  const handleEditQuestionnaire = async (questionId, updatedData) => {
    try {
      // updatedData contains the new values for the question
      await dispatch(updateQuestion(questionId, { ...updatedData }));
      setQuestionnaires((prev) => prev.map((q) => (q.id === questionId ? { ...updatedData } : q)));
      enqueueSnackbar("Question updated successfully!", { variant: "success" });
    } catch (error) {
      console.error("Failed to update question:", error);
      enqueueSnackbar("Error updating question.", { variant: "error" });
    }
  };

  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveQuestion = async (updatedQuestion) => {
    try {
      await dispatch(updateQuestion(updatedQuestion.id, updatedQuestion));
      setQuestionnaires((prev) => prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q)));
      enqueueSnackbar("Question updated successfully!", { variant: "success" });
      setIsEditModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Error updating question.", { variant: "error" });
    }
  };

  const handleDeleteQuestion = (question) => {
    setQuestionToDelete(question);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteQuestions([questionToDelete.id]));
      setQuestionnaires((prev) => prev.filter((q) => q.id !== questionToDelete.id));
      enqueueSnackbar("Question deleted successfully!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error deleting question.", { variant: "error" });
    }
    setIsDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <Documentstart
      title={`Questionnaire template -`}
      open={open}
      headerProps={{
        showSearchBar: false,
        showFilter: false,
        showView: false,
        backbutton: true,
        handleBackClick: handleBackClick,
        custombutton: true,
        custombuttontext: "Add Template Question",
        disabled: true,
        custombuttononclick: () => {},
        sx: { margin: "0" },
        // startIcon: <Plus size="22px" />,
        // hoverBackgroundColor: "",
        // variant: "contained",
      }}
    >
      <Grid item xs={12} md={12} lg={10} display={"flex"} justifyContent={"center"} alignContent={"center"} margin={"24px auto"}>
        <TableContainer>
          <Table sx={{ minWidth: 650, border: "1px solid #f0f0f0" }} aria-label="questionnaires table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#fafafa", borderBottom: "2px solid #f0f0f0" }}>
                <TableCell padding="checkbox">
                  <Checkbox indeterminate={selected.length > 0 && selected.length < questionnaires?.length} checked={questionnaires?.length > 0 && selected.length === questionnaires?.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all questionnaires" }} />
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", minWidth: "150px" }}>Title</TableCell>
                <TableCell sx={{ fontWeight: "bold", minWidth: "300px", width: "100%" }}>Content</TableCell>
                <TableCell sx={{ fontWeight: "bold", minWidth: "150px" }}>Automations</TableCell>
                <TableCell sx={{ fontWeight: "bold", minWidth: "150px" }}>Actions</TableCell>
              </TableRow>
            </TableHead>

            {/* Conditionally render the TableBody or the "No questions" message */}
            <TableBody>
              {filteredQuestionnaires?.length > 0 ? (
                filteredQuestionnaires.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((q) => {
                  const isItemSelected = selected.indexOf(q.id) !== -1;
                  const labelId = `enhanced-table-checkbox-${q.id}`;

                  return (
                    <TableRow hover onClick={(event) => handleClick(event, q.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={q.id} selected={isItemSelected} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                      </TableCell>

                      {/* Title Column */}
                      <TableCell component="th" scope="row" id={labelId} sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "300px" }}>
                        {q.title}
                      </TableCell>

                      {/* Content Column */}
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "300px",
                        }}
                      >
                        {q.content}
                      </TableCell>

                      {/* Automations Column */}
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                        }}
                      >
                        {q.actions.join(", ")}
                      </TableCell>

                      {/* Actions */}
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton disabled={true} onClick={() => handleEditQuestion(q)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip disabled={true} title="Delete">
                          <IconButton onClick={() => handleDeleteQuestion(q)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    <div>
                      <EditIcon style={{ fontSize: 40, color: "lightgray" }} />
                      <div>No questions available to answer the query.</div>
                      <Button variant="contained" color="primary" onClick={handleAddQuestionnaire}>
                        Add New Template Questions
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Pagination */}
          {filteredQuestionnaires?.length > 0 && <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={filteredQuestionnaires.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />}
        </TableContainer>
      </Grid>
      {/* Edit Modal */}
      {isEditModalOpen && <EditQuestionDialog open={isEditModalOpen} handleClose={handleCloseEditModal} questionData={questionToEdit} handleSave={handleSaveQuestion} />}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal open={isDeleteModalVisible} handleConfirm={handleConfirmDelete} handleClose={handleCancelDelete} isLoadingDelete={false} type="Question" />
    </Documentstart>
  );
};

export default QuestionnaireTemplate;
