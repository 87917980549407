import React from "react";
import { Box, Button, Typography, Container, Grid } from "@mui/material";
import myImage from "../../../assets/landingpage/s31.png"; // adjust the path as necessary
import { colors } from "../../../utils/colors";
import { styled } from "@mui/material/styles";
import { OpenCalendar, isMobile, windowHeight } from "../../../utils/utils";
const StyledButton = styled(Button)({
  backgroundColor: "white", // Replace with the exact color code from the image
  color: "#000", // Text color
  borderRadius: "5px", // Adjust to match the desired border radius from the image
  padding: "10px 30px", // Adjust padding to match the image
  boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2)", // Add shadow to match the image
  "&:hover": {
    // backgroundColor: "#43a047", // Darker shade for the hover state
    // boxShadow can be adjusted for hover state if needed
  },
  textTransform: "none", // Prevent uppercase text
  fontWeight: "bold", // Make font bold
});
const SectionThree = React.forwardRef((props, ref) => {
  return (
    <Container ref={ref} maxWidth="lg">
      <Box my={4} py={4} textAlign="center">
        <Typography style={{ fontWeight: "bold" }} variant="h3" component="h1" gutterBottom>
          Crafted by M&A Professionals
        </Typography>
        <Typography variant="h4" color="textSecondary">
          A Platform Built for Your Needs
        </Typography>
        <Typography mb={"12px"} style={{ color: colors.PGRAY }} variant="subtitle1" mt={2}>
          Built by M&A experts for professionals like you, our platform simplifies complex transactions. Instantly access critical data to differentiate your bid, develop a robust business plan, and make strategic decisions. With AI handling the heavy lifting, you can concentrate on what matters
          most—crafting winning strategies that lead to successful acquisitions
        </Typography>
        <StyledButton onClick={OpenCalendar} variant="contained" color="primary" sx={{ marginTop: isMobile() ? windowHeight / 200 : 0, backgroundColor: "#000", color: "white", textTransform: "none" }}>
          Book a demo
        </StyledButton>
      </Box>
      <Grid item xs={12} md={6}>
        <img src={myImage} alt="Landing Page" style={{ width: "100%", height: "auto" }} />
      </Grid>
    </Container>
  );
});

export default SectionThree;
