// src/components/SearchBar.js

import React from "react";
import { Box, TextField, Button, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Reusable InputField component
const InputField = ({ label, defaultValue }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      defaultValue={defaultValue}
      sx={{
        flex: 1,
        "& .MuiOutlinedInput-root": {
          borderRadius: "0px",
          border: "none",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        "& .MuiInputLabel-root": {
          fontSize: "0.875rem",
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px 14px",
        },
      }}
    />
  );
};

// Reusable DividerComponent component
const DividerComponent = () => {
  return <Divider orientation="vertical" variant="middle" flexItem />;
};

// SearchBar component
const SearchBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
        backgroundColor: "#ffffff",
        borderRadius: "30px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "800px",
        margin: "auto",
        gap: "8px",
      }}
    >
      <InputField label="Example companies" defaultValue="Tesla" />
      <DividerComponent />
      <InputField label="Business description" defaultValue="Electric Vehicles" />
      <DividerComponent />
      <InputField label="Geography" defaultValue="All" />
      <DividerComponent />
      <InputField label="Financials" defaultValue="All" />
      <Button
        variant="contained"
        color="primary"
        sx={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "48px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
        }}
      >
        <SearchIcon />
      </Button>
    </Box>
  );
};

export default SearchBar;
