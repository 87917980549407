import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Table, Button as AntButton, Empty } from "antd";

import { CopyOutlined, FileSearchOutlined } from "@ant-design/icons";
import { parse } from "node-html-parser";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { closeSnackbar, useSnackbar } from "notistack";
import { Button, Tooltip, CircularProgress, FormControl, InputLabel, MenuItem, Box, Select } from "@mui/material";
import { findDocument, getDocumentById } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setActivePdf, setPdfLoadingTask } from "../../../redux/actions/documentsAction";
import { usePdfFocus } from "../../../components/pdf-viewer/pdfContext";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { Dataset } from "@mui/icons-material";
import Spinner from "../../../components/Spinner";
const { Title } = Typography;

const DocTable = () => {
  const detailColors = ["#1AB5B8", "#B81A80"];
  const { enqueueSnackbar } = useSnackbar();
  const loadingTask = useSelector((state) => state.documents.loadingTask);
  const documents = useSelector((state) => state.documents.documents);
  const dispatch = useDispatch();
  const { setPdfFocusState } = usePdfFocus();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]); // State to store table data
  // Loading state for spinner
  console.log("documents documents", documents);
  const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document
  console.log(selectedDocument?.id, "selectedDocument");
  console.log("table data", tableData);
  useEffect(() => {
    const fetchTablesForDocument = async (document) => {
      try {
        setLoading(true); // Start the spinner
        const allTables = [];

        if (document?.table_url) {
          try {
            // Fetch the data from table_url
            const response = await fetch(document.table_url);
            const data = await response.json();
            console.log("Fetched data:", data);

            // Process each page by its page number
            Object.entries(data).forEach(([pageNumber, pageData]) => {
              // Convert each graph, table, and financial statement into the specified object format
              const pageObjects = [
                ...pageData.graphs.map((graph) => ({
                  page_number: pageNumber,
                  table_type: "graph",
                  table: graph,
                })),
                ...pageData.tables.map((table) => ({
                  page_number: pageNumber,
                  table_type: "table",
                  table: {
                    Header: table.Header,
                    Body: table.Body,
                  },
                })),
                ...pageData["financial statements"].map((statement) => ({
                  page_number: pageNumber,
                  table_type: "financial statement",
                  table: {
                    Header: statement.Header,
                    Body: statement.Body,
                  },
                })),
              ];

              // Add document's id, name, and transactionId to each table entry
              const pageObjectsWithDocInfo = pageObjects.map((tableEntry) => ({
                ...tableEntry,
                documentId: document.id,
                documentName: document.name,
              }));

              // Concatenate the page objects into allTables array
              allTables.push(...pageObjectsWithDocInfo);
            });
          } catch (error) {
            console.error(`Error fetching table for document ${document.id}:`, error);
          }
        } else {
          console.warn(`Document with ID ${document.id} has no table_url.`);
        }
        console.log("alltables", allTables);
        setTableData(allTables);
      } catch (error) {
        console.error("Error fetching table data:", error);
      } finally {
        setLoading(false); // Stop the spinner
      }
    };

    if (documents && documents.length > 0) {
      if (!selectedDocument) {
        const firstDocument = documents.flatMap((transaction) => transaction.documents)[0];
        if (firstDocument) {
          setSelectedDocument(firstDocument); // Set the first document as selected by default
          fetchTablesForDocument(firstDocument); // Fetch tables for the first document
        }
      } else {
        // Fetch tables for the currently selected document
        fetchTablesForDocument(selectedDocument);
      }
    }
  }, [documents, selectedDocument]);
  // Re-fetch when `documents` or `selectedDocument` changes
  // Re-fetch when `documents` changes
  // Function to copy the table's HTML content to the clipboard

  const jsonToHtmlTable = (tableData) => {
    let { Header, Body } = tableData;
    let htmlString = "<table><thead><tr>";

    // Ensure Body is an array, and handle nested structures
    const bodyArray = Array.isArray(Body)
      ? Body.map((row) => {
          if (typeof row === "object" && !Array.isArray(row)) {
            // If row is an object, flatten each key-value pair
            return Object.entries(row).flatMap(([key, value]) => {
              if (typeof value === "object" && value !== null) {
                // Flatten nested objects into array format
                return Object.entries(value).map(([subKey, subValue]) => [`${key} - ${subKey}`, subValue]);
              } else {
                return [[key, value]];
              }
            });
          }
          return row;
        })
      : [];

    // Determine the maximum row length in Body, defaulting to 1 if Body is empty
    const maxBodyLength = bodyArray.length > 0 ? Math.max(...bodyArray.map((row) => (Array.isArray(row) ? row.length : row.length || 0))) : 1;

    // Handle case where Header is a string by transforming it into an array with padding
    if (typeof Header === "string") {
      Header = [Header, ...new Array(maxBodyLength - 1).fill("")];
    } else if (!Array.isArray(Header)) {
      // Default Header to an empty array if it's neither a string nor an array
      Header = [];
    }

    // Determine the maximum column count between header and body rows
    const maxColumns = Math.max(Header.length, maxBodyLength);

    // Build the table header with padding for empty cells if needed
    Header.forEach((title) => {
      htmlString += `<th>${title || ""}</th>`;
    });

    for (let i = Header.length; i < maxColumns; i++) {
      htmlString += "<th></th>";
    }

    htmlString += "</tr></thead><tbody>";

    // Build the table body, ensuring each row matches the max column count
    bodyArray.forEach((row) => {
      htmlString += "<tr>";
      if (Array.isArray(row)) {
        row.forEach((cell) => {
          htmlString += `<td>${cell || ""}</td>`;
        });
      } else {
        // For object rows, map key-value pairs to table cells
        row.forEach(([key, value]) => {
          htmlString += `<td>${key}: ${value || ""}</td>`;
        });
      }

      // Add empty cells if row has fewer columns than maxColumns
      for (let i = row.length; i < maxColumns; i++) {
        htmlString += "<td></td>";
      }

      htmlString += "</tr>";
    });

    htmlString += "</tbody></table>";
    return htmlString;
  };

  const copyTable = (tableData) => {
    const htmlContent = jsonToHtmlTable(tableData.table);
    navigator.clipboard
      .writeText(htmlContent)
      .then(() => {
        enqueueSnackbar(`Table copy success`, { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to copy table`, { variant: "warning" });
      });
  };

  // Function to parse HTML table content into Ant Design compatible columns and data

  const parseTableContent = (tableData) => {
    const columns = [];
    const dataSource = [];

    let { Header, Body } = tableData;

    // Handle different types of Header: string, array, or none
    if (typeof Header === "string") {
      Header = [Header]; // Treat as a single-element array
    } else if (!Array.isArray(Header)) {
      Header = [];
    }
    // Determine Body structure
    if (typeof Body === "string") {
      // Treat Body as a single row containing the whole string
      Body = [[Body]]; // Wrap in a 2D array to handle as a single cell
    } else if (Array.isArray(Body) && typeof Body[0] === "object" && !Array.isArray(Body[0])) {
      // If Body is an array of objects
      // const keys = Object.keys(Body[0]);
      const keys = [...new Set(Body.flatMap(Object.keys))];
      // Define columns based on keys found in the first object
      keys.forEach((key, index) => {
        columns.push({
          title: key,
          dataIndex: `column_${index + 1}`,
          key: `column_${index + 1}`,
        });
      });

      // Populate dataSource for each row in Body (assuming each row is an object)
      Body.forEach((row, rowIndex) => {
        const rowData = {};
        keys.forEach((key, index) => {
          rowData[`column_${index + 1}`] = typeof row[key] === "object" ? JSON.stringify(row[key]) : row[key] || "";
        });
        dataSource.push({ key: `row_${rowIndex}`, ...rowData });
      });
    } else if (Array.isArray(Body)) {
      // If Body is an array of arrays
      const maxColumns = Math.max(Header.length, ...Body.map((row) => row.length));

      // Define columns based on Header or default titles
      for (let i = 0; i < maxColumns; i++) {
        columns.push({
          title: Header[i] || ``,
          dataIndex: `column_${i + 1}`,
          key: `column_${i + 1}`,
        });
      }

      // Populate dataSource by ensuring each row matches maxColumns
      Body.forEach((row, rowIndex) => {
        const rowData = {};
        console.log("Error at ", row, rowIndex);
        let row_2;
        if (typeof row === "string") {
          row_2 = [row];
        } else {
          row_2 = row;
        }

        row_2.forEach((cell, cellIndex) => {
          rowData[`column_${cellIndex + 1}`] = cell || "";
        });
        for (let i = row.length; i < maxColumns; i++) {
          rowData[`column_${i + 1}`] = "";
        }
        dataSource.push({ key: `row_${rowIndex}`, ...rowData });
      });
    } else {
      console.error("Unknown Body format");
    }
    if (Body.length === 1 && Body[0].length === 1 && typeof Body[0][0] === "string") {
      // Define a single column and add the entire Body content as a single row
      columns.push({
        title: Header[0] || "Content",
        dataIndex: "column_1",
        key: "column_1",
      });
      dataSource.push({
        key: "row_0",
        column_1: Body[0][0],
      });
    }
    return { columns, dataSource };
  };

  const handleTableClick = async (pageNumber, documentId) => {
    console.log(documentId, "table");
    if (!documentId) return;
    const foundDoc = documents.flatMap((transaction) => transaction.documents).find((doc) => doc.id === documentId);

    if (!foundDoc) {
      console.error("Document not found");
      return;
    }

    try {
      if (loadingTask?.destroy) {
        await loadingTask.destroy();
      }

      const newLoadingTask = pdfjsLib.getDocument(foundDoc.url);
      await dispatch(setPdfLoadingTask(newLoadingTask));

      const document = getDocumentById(documents, documentId);
      await dispatch(setActivePdf(document));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      await newLoadingTask.promise;

      const citationData = {
        documentId,
        pageNumber: pageNumber,
        citation: undefined,
      };

      // await dispatch(setExploreCitation(citationData));
      setPdfFocusState(citationData);
    } catch (error) {
      console.error("Failed to load PDF:", error);
      // TODO: Handle error (e.g., show error notification)
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          backgroundColor: "#F5F5F7",
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl variant="outlined" size="small" sx={{ width: 190 }}>
            <InputLabel id="document-select-label">Select Table Document</InputLabel>
            <Select
              labelId="document-select-label"
              value={selectedDocument?.id || ""}
              label="Select Document"
              onChange={(event) => {
                const selectedDocId = event.target.value;
                const selectedDoc = documents.flatMap((transaction) => transaction.documents).find((doc) => doc.id === selectedDocId);
                setSelectedDocument(selectedDoc);
              }}
            >
              {documents
                .flatMap((transaction) => transaction.documents)
                .map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    {doc.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {tableData && tableData.length > 0 ? (
        tableData.map((table, index) => {
          const { columns, dataSource } = parseTableContent(table.table);

          return (
            <div key={index} style={{ marginBottom: "30px" }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Row justify="start" align="middle">
                    <Title level={4} style={{ color: index % 2 === 0 ? detailColors[0] : detailColors[1], marginRight: "10px" }}>
                      {`${capitalizeFirstLetter(table.table_type)}, Page ${table.page_number}`}
                    </Title>
                    <Tooltip title="Find table in documents">
                      <AntButton icon={<FileSearchOutlined />} type="link" onClick={() => handleTableClick(table.page_number, selectedDocument?.id)} />
                    </Tooltip>
                  </Row>
                </Col>
                <Col>
                  <Button
                    sx={{
                      color: "#797E90",
                      backgroundColor: "transparent",
                      textTransform: "none",
                    }}
                    startIcon={<ContentPasteIcon />}
                    onClick={() => copyTable(table)}
                  >
                    Copy Table
                  </Button>
                </Col>
              </Row>
              <Table columns={columns} dataSource={dataSource} pagination={false} bordered />
            </div>
          );
        })
      ) : (
        <Empty description="No data to display" />
      )}
    </div>
  );
};

export default DocTable;
