import {
  FETCH_WORKSTREAMS_REQUEST,
  FETCH_WORKSTREAMS_SUCCESS,
  FETCH_WORKSTREAMS_FAILURE,
  CREATE_WORKSTREAM_REQUEST,
  CREATE_WORKSTREAM_SUCCESS,
  CREATE_WORKSTREAM_FAILURE,
  UPDATE_WORKSTREAM_REQUEST,
  UPDATE_WORKSTREAM_SUCCESS,
  UPDATE_WORKSTREAM_FAILURE,
  DELETE_WORKSTREAM_REQUEST,
  DELETE_WORKSTREAM_SUCCESS,
  DELETE_WORKSTREAM_FAILURE,
  FETCH_WORKSTREAM_BY_ID_REQUEST,
  FETCH_WORKSTREAM_BY_ID_SUCCESS,
  FETCH_WORKSTREAM_BY_ID_FAILURE,
} from "../actions/types";

const initialState = {
  workstreams: [],
  isLoading: false,
  error: null,
};

const workstreamReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle workstream list
    case FETCH_WORKSTREAMS_REQUEST:
    case CREATE_WORKSTREAM_REQUEST:
    case UPDATE_WORKSTREAM_REQUEST:
    case DELETE_WORKSTREAM_REQUEST:
    case FETCH_WORKSTREAM_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_WORKSTREAMS_SUCCESS:
      console.log("Workstreams fetched successfully:", action.payload);
      return {
        ...state,
        workstreams: action.payload,
        isLoading: false,
      };

    case FETCH_WORKSTREAMS_FAILURE:
    case CREATE_WORKSTREAM_FAILURE:
    case UPDATE_WORKSTREAM_FAILURE:
    case DELETE_WORKSTREAM_FAILURE:
    case FETCH_WORKSTREAM_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // Handle fetching a single workstream by ID
    case FETCH_WORKSTREAM_BY_ID_SUCCESS:
      return {
        ...state,
        workstreamById: action.payload,
        isLoading: false,
      };

    case CREATE_WORKSTREAM_SUCCESS:
      return {
        ...state,
        workstreams: [...state.workstreams, action.payload],
        isLoading: false,
      };

    case UPDATE_WORKSTREAM_SUCCESS:
      return {
        ...state,
        workstreams: state.workstreams.map((workstream) => (workstream.id === action.payload.id ? action.payload : workstream)),
        isLoading: false,
      };

    case DELETE_WORKSTREAM_SUCCESS:
      return {
        ...state,
        workstreams: state.workstreams.filter((workstream) => workstream.id !== action.payload),
        isLoading: false,
      };

    default:
      return state;
  }
};

export default workstreamReducer;
