import Diligence from "../DealsScreen/Diligence";
import DueDiligence from "../DealsScreen/DueDiligence";
import React, { useEffect, useRef, useState, useCallback, forwardRef } from "react";
const JustDD = () => {
  const [view, setView] = useState("DUE");
  const [savedTransactions, setSavedTransactions] = useState();
  const [savedWorkstream, setSavedWorkstream] = useState([]);
  const handleSetView = (value) => {
    if (value === "DETAILS" || value === "DUE") {
      setView(value);
    }
  };
  const handleTransactions = (transactions) => {
    setSavedTransactions(transactions);
  };
  const handleworkstream = (workstream) => {
    setSavedWorkstream(workstream);
  };
  return (
    <>
      {view === "DUE" && <DueDiligence setView={setView} transaction={savedTransactions} saveWorkstream={handleworkstream} handleTransactions={handleTransactions} />}
      {view === "DETAILS" && <Diligence setView={handleSetView} transaction={savedTransactions} savedWorkstream={savedWorkstream} setworkstream={handleworkstream} />}
    </>
  );
};

export default JustDD;
