import React, { useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, TextField, Typography, Box, Tooltip } from "@mui/material";

const automationOptions = ["Query the dataroom", "Query past internal papers", "Query similar transactions", "Answer the query from the web", "Ask ChatGPT", "Tabularize results"];

const EditQuestionDialog = ({ open, handleClose, questionData, handleSave }) => {
  const [title, setTitle] = useState(questionData?.title || "");
  const [content, setContent] = useState(questionData?.content || "");
  const [selectedAutomations, setSelectedAutomations] = useState(questionData?.actions || []);

  const handleCheckboxChange = (option) => {
    if (selectedAutomations.includes(option)) {
      setSelectedAutomations(selectedAutomations.filter((opt) => opt !== option));
    } else {
      setSelectedAutomations([...selectedAutomations, option]);
    }
  };

  const handleSubmit = () => {
    const updatedQuestion = {
      ...questionData,
      title,
      content,
      actions: selectedAutomations,
    };
    handleSave(updatedQuestion);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Edit Question
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Modify the details of the question
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6">Title:</Typography>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <TextField label="Question Title" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
        </FormControl>

        <Typography variant="h6" sx={{ mt: 1 }}>
          Content:
        </Typography>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <TextField label="Content" value={content} onChange={(e) => setContent(e.target.value)} fullWidth />
        </FormControl>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Automations:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          {automationOptions.map((option, index) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={selectedAutomations.includes(option)} onChange={() => handleCheckboxChange(option)} />}
              label={
                <Tooltip title="Adding new tasks is disabled for the pilot">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{`${index + 1}. ${option}`}</Typography>
                  </Box>
                </Tooltip>
              }
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "black",
            borderRadius: "6px",
            fontSize: 16,
            padding: "10px 20px",
            width: "180px",
            height: "60px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={true}
          variant="contained"
          sx={{
            bgcolor: "black",
            color: "white",
            borderRadius: "6px",
            "&:hover": {
              bgcolor: "black",
              color: "white",
            },
            width: "180px",
            height: "60px",
            fontSize: 16,
            padding: "10px 20px",
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuestionDialog;
