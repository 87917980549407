import { CLEAR_EXPLORED_DATA, EXPLORE, SET_FILTRED_EXPLORED_DATA } from "../actions/types";

const initialState = {
  exploredData: null,
};

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPLORE:
      return {
        ...state,
        exploredData: action.payload,
        // filtredExploredData: action.payload,
      };
    case SET_FILTRED_EXPLORED_DATA:
      return {
        ...state,
        filtredExploredData: action.payload,
      };
    case CLEAR_EXPLORED_DATA:
      return {
        ...state,
        exploredData: null,
        filtredExploredData: null,
      };
    default:
      return state;
  }
};

export default exploreReducer;
