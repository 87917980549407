import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backendClient } from "../../../api/backend";
import { useSnackbar } from "notistack";
import { countries, industries } from "../../../api/utils/dataModel";
import { colors } from "../../../utils/colors";
import { formatDate } from "../../../utils/utils";
import { setTransactions } from "../../../redux/actions/transactionAction";

const EditDeal = ({ open, handleClose, dataTransaction }) => {
  const [formData, setFormData] = useState({
    name: "",
    industry: "",
    country: "",
    description: "",
    date: "",
  });
  console.log(dataTransaction.id);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (dataTransaction) {
      setFormData({
        name: dataTransaction.name || "",
        industry: dataTransaction.industry || "",
        country: dataTransaction.country || "",
        description: dataTransaction.description || "",
        date: formatDate(dataTransaction.date) || "",
      });
    }
  }, [dataTransaction]);

  const handleFormChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.industry) newErrors.industry = "Industry is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.description) newErrors.description = "Description is required";
    if (!formData.date) newErrors.date = "Date is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpload = async () => {
    console.log("test ==> ");
    if (validateForm()) {
      setLoading(true);
      try {
        console.log(formData);

        const transactionId = dataTransaction.id;

        const propertyName = "name";
        const newValue = formData.name;
        const append = false;
        const remove = false;

        const transactionUpdated = await backendClient.updateTransactionProperty(transactionId, propertyName, newValue, append, remove);

        console.log("Transaction updated:", transactionUpdated);

        enqueueSnackbar("Transaction uploaded successfully", { variant: "success" });
        await dispatch(setTransactions([transactionUpdated]));
        handleClose();
        navigate(`/deals`);
      } catch (error) {
        enqueueSnackbar("Error uploading transaction", { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Transaction Details
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Add information for the transaction
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField label="Name" value={formData.name} onChange={handleFormChange("name")} error={!!errors.name} helperText={errors.name} fullWidth margin="normal" />
        <FormControl fullWidth margin="normal" error={!!errors.industry}>
          <InputLabel>Industry</InputLabel>
          <Select value={formData.industry} onChange={handleFormChange("industry")} label="Industry">
            {industries.map((industry, i) => (
              <MenuItem key={i} value={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
          {errors.industry && (
            <Typography variant="caption" color="error">
              {errors.industry}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.country}>
          <InputLabel>Country</InputLabel>
          <Select value={formData.country} onChange={handleFormChange("country")} label="Country">
            {countries.map((country, i) => (
              <MenuItem key={i} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
          {errors.country && (
            <Typography variant="caption" color="error">
              {errors.country}
            </Typography>
          )}
        </FormControl>
        <TextField label="Description" value={formData.description} onChange={handleFormChange("description")} error={!!errors.description} helperText={errors.description} fullWidth margin="normal" multiline rows={4} />
        <TextField label="Date" value={formData.date} onChange={handleFormChange("date")} error={!!errors.date} helperText={errors.date} fullWidth margin="normal" />
      </DialogContent>
      <DialogActions
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "flex-end", // Align to the right
          alignItems: "center",
          flexDirection: "row", // Ensure buttons are in a row
        }}
      >
        <Stack spacing={2} direction="row" mb={1} alignItems="center">
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: 2,
              color: "black",
              borderColor: "black",
              "&:hover": {
                bgcolor: "white",
                color: "black",
                borderColor: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleUpload}
            disabled={loading}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: colors.BLACK,
              color: colors.WHITE,
              boxShadow: "none",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "black",
              },
              "&:disabled": {
                color: "white",
              },
            }}
          >
            {loading ? (dataTransaction ? "Updating..." : "Uploading...") : dataTransaction ? "Update" : "Upload"}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default EditDeal;
