import React from "react";
import { Grid, Typography, Divider, Button, Box } from "@mui/material";
import CompanyCard from "./CompanyCard";

const Dashboard = ({ cards }) => {
  return (
    <>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6" component="h2" gutterBottom>
            AI recommendations based on all your input (751 results)
          </Typography>
          <Button
            style={{
              margin: "10px 0",
              textTransform: "none",
              color: "blue",
              border: "none",
              background: "none",
              fontSize: "16px",
            }}
          >
            See All
          </Button>
        </Box>
        <Divider style={{ marginBottom: "20px", backgroundColor: "black" }} />
      </div>
      <Grid container spacing={3} display={"flex"} justifyContent={"center"} alignContent={"center"}>
        {cards.map((company, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <CompanyCard company={company} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Dashboard;
