// we have a system where :
// . We import files. Each files are linkedin to a "transaction".
// . A transaction contains : id, title, industry, country, description and date.
// . When we upload files, user is asked to fill form with title, industry, description and date in order to create a transaction.
// . Transaction will be stored in PGSQL - AWS
// . Transaction can also be loaded from PGSQL - AWS.
// create transactionAction and transactionReducer

import { backendClient } from "../../api/backend";
import {
  CLEAR_TRANSACTIONS,
  CREATE_TRANSACTION,
  DELETE_TRANSACTIONS,
  FETCH_TRANSACTION,
  FETCH_TRANSACTIONS,
  REMOVE_TRANSACTION_DOCUMENT_ID,
  SET_TRANSACTION,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ID,
  UPDATE_TRANSACTION_DOCUMENTS_IDS,
  UPDATE_TRANSACTION_DOCUMENT_UPLOADED,
  UPDATE_TRANSACTION_PROPERTY,
} from "./types";

export const createTransaction = (transaction) => {
  return async (dispatch, getState) => {
    const createdTransaction = await backendClient.createTransaction(transaction);
    dispatch({
      type: CREATE_TRANSACTION,
      payload: createdTransaction,
    });
    return createdTransaction;
  };
};

export const setTransactions = (transactions) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTIONS,
      payload: transactions,
    });
  };
};
export const setTransaction = (transaction) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTION,
      payload: transaction,
    });
  };
};
export const setTransactionId = (transactionId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTION_ID,
      payload: transactionId,
    });
  };
};

export const clearTransactions = (transaction) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_TRANSACTIONS,
    });
  };
};

export const removeTransactionDocumentId = (documentId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_TRANSACTION_DOCUMENT_ID,
      payload: documentId,
    });
  };
};

export const deleteTransactions = (transactionIds) => {
  return async (dispatch, getState) => {
    await backendClient.deleteTransactions(transactionIds);
    dispatch({
      type: DELETE_TRANSACTIONS,
      payload: transactionIds,
    });
  };
};

export const updateTransactionDocumentsIds = (transactionId, documentId) => {
  return async (dispatch, getState) => {
    const transactions = getState().transactions.transactions;
    let transaction = transactions.find((transaction) => transaction.id === transactionId);
    console.log("updateTransactionDocumentsIds transaction", transaction);
    transaction.documents_ids.push(documentId);

    dispatch({
      type: UPDATE_TRANSACTION_DOCUMENTS_IDS,
      payload: transaction,
    });
    return transaction.id;
  };
};

export const updateTransactionProperty = (transactionId, propertyName, newValue, append, remove) => {
  return async (dispatch) => {
    let updatedTransaction;
    if (propertyName === "documents_ids") {
      updatedTransaction = await backendClient.updateTransactionProperty(transactionId, propertyName, newValue, append, remove);
    } else {
      updatedTransaction = await backendClient.updateTransactionProperty(transactionId, propertyName, newValue, append, remove);
    }
    dispatch({
      type: UPDATE_TRANSACTION_PROPERTY,
      payload: updatedTransaction,
    });
  };
};

export const updateTransactionDocumentUploaded = (transaction, value) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TRANSACTION_DOCUMENT_UPLOADED,
      payload: value,
    });
  };
};

export const fetchTransactions = (page, pageSize) => {
  return async (dispatch, getState) => {
    const transactions = await backendClient.fetchTransactions(page, pageSize);
    dispatch({
      type: FETCH_TRANSACTIONS,
      payload: transactions,
    });
  };
};

export const fetchTransaction = (transactionId, add_to_transactions) => {
  return async (dispatch, getState) => {
    const transaction = await backendClient.fetchTransaction(transactionId);
    dispatch({
      type: FETCH_TRANSACTION,
      payload: { transaction, add_to_transactions },
    });
    return transaction;
  };
};
