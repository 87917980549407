import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import { MessageCircle } from "lucide-react";
import { Box } from "@mui/material";
import { clearDocuments, fetchDocuments } from "../../../redux/actions/documentsAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Spinner from "../../../components/Spinner";

// Define the columns for the table
const SummaryTable = ({ questions, transaction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Question",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Summary",
      dataIndex: "content",
      key: "content",
      render: (status, record) => {
        if (record.status === "Error") {
          return (
            <Tooltip title="Error processing the request">
              <CloseCircleFilled style={{ color: "red", cursor: "pointer" }} />
            </Tooltip>
          );
        } else if (record.status === "Pending" && !record.summary) {
          return (
            <Tooltip title="Loading">
              <div
                style={{
                  borderRadius: "50%",
                  width: "14px",
                  height: "14px",
                  backgroundColor: "blue",
                  animation: "flashOrange 1.3s infinite", // Apply animation only for Pending status
                  cursor: "pointer",
                }}
              ></div>
            </Tooltip>
          );
        } else {
          return (
            <span>
              {record.summary ? (
                record.summary.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              ) : (
                <CheckCircleOutlined style={{ color: "blue" }} />
              )}
            </span>
          );
        }
      },
    },
    {
      title: "",
      key: "action",
      width: 10, // Set the width to fit the content
      padding: 0,
      render: (text, record) => (
        <Tooltip title="Explore Conversation">
          <Box
            sx={{
              backgroundColor: "#000",
              padding: "4px", // Adjust padding for better sizing
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "25px",
              height: "25px",
              borderRadius: "25%", // Make it a circle
              cursor: "pointer",
            }}
            onClick={() => handleNavigateToConversation(record.conversation_id)}
          >
            <MessageCircle color="white" size={16} /> {/* Adjust size for better fit */}
          </Box>
        </Tooltip>
      ),
    },
  ];

  // Sample data from your response (questions array)
  // const data = [
  //   {
  //     title: "Understanding PPA Terms",
  //     status: "Success",
  //     summary: null,
  //   },
  //   {
  //     title: "Future Revenue Forecast",
  //     status: "Pending",
  //     summary: null,
  //   },
  //   {
  //     title: "Off-Taker Signings Discussion",
  //     status: "Error",
  //     summary: null,
  //   },
  //   {
  //     title: "Commercial Risk Assessment",
  //     status: "Success",
  //     summary:
  //       "Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. Key risks identified in contract. ",
  //   },
  // ];
  const data = questions?.questions;
  console.log("data", data);
  // Function to handle explore action

  // Main component

  const handleNavigateToConversation = async (conversation_id) => {
    // setLoadingStates((prevState) => ({ ...prevState, [conversation_id]: true }));
    setLoading(true);
    await dispatch(clearDocuments());
    await dispatch(fetchDocuments(transaction.id));
    navigate(`/chat/${conversation_id}`);
    setLoading(false);
    // setLoadingStates((prevState) => ({ ...prevState, [conversation_id]: false }));
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  return loading ? (
    <Spinner />
  ) : (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="title" // Using the title as a unique key
      pagination={false} // Disable pagination if you want to show all at once
    />
  );
};

export default SummaryTable;
