import { Dialog, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalPdfView from "../../../components/ModalPdfView";

const PdfViewerDialog = ({ open, handleClose, document }) => {
    const { name: nameDocument, url: pdfUrl } = document;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "lg",
          
          height: "90vh",
          zIndex: 5000,
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          {nameDocument}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <ModalPdfView open={open} handleClose={handleClose} url={pdfUrl} />
      </DialogContent>
    </Dialog>
  );
};

export default PdfViewerDialog;
