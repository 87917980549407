import React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { isMobile } from "../utils/utils";
import { Button } from "@mui/material";
import { ChevronLeft } from "lucide-react";
// const BackButton = () => {
//   const navigate = useNavigate();

//   return (
//     <IconButton
//       onClick={() => navigate(-1)}
//       className="text-gray-600 hover:text-blue-500 focus:text-gray-500"
//       size="large"
//       edge="start"
//       aria-label="back"
//       sx={{
//         "&:hover": {
//           backgroundColor: "transparent", // Ensuring hover background is transparent
//         },
//       }}
//     >
//       <ArrowBackIosIcon />
//       {!isMobile() && <span className="text-sm font-medium">Back</span>}
//     </IconButton>
//   );
// };

// export default BackButton;
const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<ChevronLeft color="black" />}
      onClick={() => navigate(-1)}
      sx={{
        color: "black",
        "&:hover": {
          color: "black",
        },
      }}
    >
      Back
    </Button>
    // {!isMobile() && <span className="text-sm font-medium">Back</span>}
  );
};

export default BackButton;
