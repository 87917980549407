import React, { useState } from "react";
import { Modal, Box, Typography, Button, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import FilesUpload from "./FilesUpload";

function AddDocumentModal({ uploadedFiles, setUploadedFiles, isLoadingAddDocument, open, handleConfirm, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <DialogTitle>
          <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          {"Add documents"}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "gray" }}>
            Use this form to add a add a document to the transaction
          </Typography>
        </DialogTitle>
        <Divider />

        {/* File Upload Section */}
        <Box
          sx={{
            mt: 2,
            p: 2,
          }}
        >
          <FilesUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} is_upload={false} />
        </Box>

        {/* Buttons Section */}
        <DialogActions>
          <Button
            disabled={isLoadingAddDocument}
            onClick={handleClose}
            sx={{
              color: "black",
              borderRadius: "6px",
              fontSize: 16,
              padding: "10px 20px",
              width: "180px",
              height: "60px",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoadingAddDocument}
            onClick={() => {
              handleConfirm();
            }}
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              borderRadius: "6px",
              "&:hover": {
                bgcolor: "black",
                color: "white",
              },
              width: "180px",
              height: "60px",
              fontSize: 16,
              padding: "10px 20px",
            }}
          >
            {isLoadingAddDocument ? "Adding document..." : "Confirm"}
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}

export default AddDocumentModal;